import React from 'react'
import ReactJscorses from "./ReactJscorses";
import AllCourses from "./AllCourses";
import Partners from './Partners'
import ReactJsBanner from './ReactJsBanner';
import ReactjsTabs from './ReactjsTabs';
import FlipCards from './FlipCards';

const PlacementsReactjs = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div> 
      <ReactJsBanner/>
      <ReactjsTabs></ReactjsTabs>
    {/* <ReactJscorses></ReactJscorses>  */}
    <FlipCards />
    {/* <AllCourses></AllCourses> */}
     {/* <Partners></Partners> */}
    
     </div>
  )
}

export default PlacementsReactjs