import React from 'react'
import TechnoTalksFlutterCourses from "./TechnoTalksFlutterCourses";
import TechnoTalksCources from "./TechnoTalksCources";
import Partners from './Partners';
import FlutterBanner from './FlutterBanner';
import FlutterTabs from './FlutterTabs';
import PlacementssectionCards from './PlacementssectionCards';

const TechnotalksFlutter = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div> 
            <FlutterBanner/>
            <FlutterTabs></FlutterTabs>
    {/* <TechnoTalksFlutterCourses></TechnoTalksFlutterCourses>  */}
    <TechnoTalksCources />
{/* <PlacementssectionCards /> */}
     </div>
  )
}

export default TechnotalksFlutter