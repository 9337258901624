import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TechnoTalksCources from "./TechnoTalksCources";
import sensor from "../img/section-images/sensor.jpg";
import IndustrialBanner from './IndustrialBanner';
import IndustrailTabs from './IndustrailTabs';
const IndustrialAutomation = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [industrialauto, setIndustrialauto] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://iiaserver.iiautomate.com/IIA-Website-1.0.0/ttia/get"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setIndustrialauto(result);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  function MyChildComponent() {
    return (
      <div>
       
        <div className="" style={{ paddingTop: "10px" }}>
          <div class="">
            <section className="about-area" style={{ textAlign: "justify" }}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="about-content page-style ml-15"></div>
                  <div className="col-lg-6">




                    {/* <div className="about-img text-center">
                      <img src={sensor} alt="Image" />
                    </div> */}

               <div className="about-content page-style ml-15">
                 <div className="text-center" style={{marginTop: "0px"}}>
                   <span
                       className="top-title"
                                  style={{
                                   fontSize: "35px",
                                paddingBottom: "15px",
                                color: "#f08821",
                                  }}
                                   >
                                Key Features
                             </span>
                         </div>
                     </div>

                    <ul style={{ textAlign: "justify" }}>
                        <li>Industrial automation refers to the use of control systems, such as computers, robots,
                          and information technologies, to handle different processes and machinery.</li>
                        <li>Used in high-volume production, where the same process is repeated continuously.</li>
                        <li>Suitable for batch production where systems can be reprogrammed different tasks.</li>
                        <li>Systems can be changed automatically for different products or processes.</li>
                      <li>Sensors gather data from the environment, such as temperature, pressure, or motion.</li>
                      <li>Actuators convert this data into mechanical actions, like moving a robot arm or opening a valve.</li>
                    </ul>
                    {/* <div className="about-img text-center">
                      <img src={robotics} alt="Image" />
                    </div> */}
                  </div>
                  <div
                    className="col-lg-6"
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      lineHeight: "2",
                    }}
                  >
                    <div className="text-center">
                      <span
                        className="top-title"
                        style={{
                          fontSize: "35px",
                          paddingBottom: "20px",
                        }}
                      >
                        Webinar Details
                      </span>
                    </div>

                    {industrialauto && (
                      <table>
                        <tbody>
                          <tr>
                            <td>Webinar Name</td>
                            <td>{industrialauto.webinarName}</td>
                          </tr>
                          <tr>
                            <td>Course Duration</td>
                            <td>{industrialauto.courseDuration}</td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td>{industrialauto.date}</td>
                          </tr>
                          <tr>
                            <td>Mode</td>
                            <td>{industrialauto.mode}</td>
                          </tr>
                          <tr>
                            <td>Time</td>
                            <td>{industrialauto.timing}</td>
                          </tr>
                          <tr>
                            <td>Speaker Name</td>
                            <td>{industrialauto.speakerName}</td>
                          </tr>
                          <tr>
                            <td>Price</td>
                            <td>{industrialauto.price}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}

                    <Link to="/enroll">
                      <button className="custom-btn btn-3">
                        <span>Register Now</span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </section>

          
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
       <IndustrialBanner />
       <IndustrailTabs /> 
     

      
      <TechnoTalksCources />
    </div>
  );
};

export default IndustrialAutomation;
