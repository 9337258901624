import React from 'react';
import Button from 'react-bootstrap/Button';

const ButtonPage = ({ handleShow }) => {
  return (
    <Button className='button-data' onClick={handleShow} style={{  backgroundColor: "#524DA1",     padding: "10px 20px", }}>
      Download Brochure
    </Button>
    
  );
};

export default ButtonPage;
