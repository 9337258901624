import React, { useState, useEffect } from "react";
import robotics from "../img/section-images/robotics.jpg";

import { Link } from "react-router-dom";
const PythonCorses = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });


  // api integration 
  const [onlinerobotics, setOnlinerobotics] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/python/latest');
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setOnlinerobotics(result);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  //  if (isLoading) {
  //    return <div>Loading...</div>;
  //  }

  //  if (error) {
  //    return <div>Error: {error.message}</div>;
  //  }

  function MyChildComponent() {
    return (
      <div>
        <div class="" style={{ paddingTop: "10px" }}>
          <div class="">
            <section
              className="about-area about-style-three about-us-mobile "
              style={{ textAlign: "justify" }}
            >
              <div className="container">
                <div className="row align-items-center ">
                  <div className="about-content page-style ml-15"></div>
                  <div className="col-lg-6">


                  <div className="about-content page-style ml-15">
<div className="text-center" style={{marginTop: "-40px"}}>
<span
className="top-title"
style={{
  fontSize: "35px",
  paddingBottom: "15px",
}}
>
Key Features
</span>
</div>
</div>

                    <ul style={{ textAlign: "justify" }}>
                      <li>Discover how to create reusable code with functions and organize your project.</li>
                      <li>Understand essential data structures like lists, dictionaries, and sets.</li>
                      <li>Master reading from and writing to files, and manage input and output operations.</li>
                      <li>Explore the principles of object-oriented programming, including classes, objects.</li>
                      <li>Get acquainted with popular Python libraries and frameworks like NumPy, pandas, Flask, and Django.</li>
                      <li>Community and Ecosystem A vast community supports Java with a wealth of libraries, frameworks, and tools.</li>
                    </ul>
                    {/* <div className="about-img text-center">
                      <img src={robotics} alt="Image" />
                    </div> */}
                  </div>
                  <div
                    className="col-lg-6"
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      lineHeight: "2.6 ",
                    }}
                    >
                    <div className="text-center">
                      <span
                        className="top-title"
                        style={{
                          fontSize: "35px",
                          paddingBottom: "20px",
                        }}
                      >
                        Course Information
                      </span>
                    </div>

                    {onlinerobotics && (
                      <table>
                        <tbody>
                          <tr>
                            <td>Course Duration</td>
                            <td>{onlinerobotics.courseDuration}</td>
                          </tr>
                          {/* <tr>
               <td>Date</td><td>{data.date}</td>
               </tr> */}
                          <tr>
                            <td>Mode</td>
                            <td>{onlinerobotics.mode}</td>
                          </tr>
                          <tr>
                            <td>Time</td>
                            <td>{onlinerobotics.timing}</td>
                          </tr>

                          <tr>
                            <td>Price</td>
                            <td>{onlinerobotics.price}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}

<Link to="/contact-us?category=mechanical-engineering">
                  <button className="custom-btn btn-3">
                    <span>Register Now</span>
                  </button>
                </Link>
                  </div>
                </div>
              </div>
            </section>
            {/* <section
              className="about-area about-style-three about-us-mobile"
              style={{
                textAlign: "justify",
                paddingTop: "30px",
                paddingBottom: "10px",
              }}
            >
             <div className="container">
              <div className="row align-items-center" style={{ paddingBottom: "10px" }}>
                <div
                  className="about-content page-style ml-15"
                  style={{ textAlign: "justify", fontSize: "18px" }}
                >
                  <div className="text-center">
                    <span
                      style={{
                        fontSize: "30px",
                        color: "#5B4B99",
                      }}
                    >
                      Key Features:
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="about-content page-style ml-15 bdstyle conheigh"
                    style={{ textAlign: "justify" }}
                  >
                    <h4>Custom Website Design:</h4>
                    <ul>
                      <li
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        Our web development solutions are tailored to meet your specific business needs. From
                        user-friendly interfaces to visually appealing designs.
                      </li>
                      <li
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        We create custom websites that not only look great but also deliver a seamless user experience.
                      </li>
                    </ul>
                    <h4>E-Commerce Development:</h4>
                    <ul>
                      <li
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        Unlock the potential of online sales with our comprehensive e-commerce solutions.
                      </li>
                      <li
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        We specialize in building solutions that enable seamless transactions and offer customized features to grow your online business.
                      </li>
                    </ul>
                    <h4>Web Application Development:</h4>
                    <ul>
                      <li
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        Our web application development services provide dynamic, interactive platforms that enhance user engagement.
                      </li>
                      <li
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        Our team ensures that each web application is secure.
                      </li>
                    </ul>
                    <h4>Custom Mobile App Development:</h4>
                    <ul>
                      <li
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        We specialize in building tailor-made mobile applications designed to meet the unique needs of your business.
                      </li>
                      <li
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        Our team of experts delivers feature-rich, user-friendly applications that are visually appealing and highly functional.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content page-style ml-15 bdstyle conheigh" style={{ paddingBottom: "185px" }}>
                    <div className="text-center"></div>
                    <h4>Enterprise Mobility Solutions:</h4>
                    <ul>
                      <li
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        Empower your business with our enterprise mobility solutions designed to streamline workflows, improve communication, and enhance productivity.
                      </li>
                      <li
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        We develop secure, scalable mobile applications that integrate seamlessly with your existing systems.
                      </li>
                    </ul>
                    <h4>App Maintenance & Support:</h4>
                    <ul>
                      <li
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        We offer comprehensive maintenance and support services to keep your mobile applications running smoothly.
                      </li>
                      <li
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        Our dedicated team ensures your app stays updated with the latest features.
                      </li>
                    </ul>
                    <h4>Customized ERP Solutions:</h4>
                    <ul>
                      <li
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        Our ERP platform solutions are tailored to fit the specific needs of your business, enabling seamless integration across various departments.
                      </li>
                      <li
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        We develop ERP systems that unify your business processes.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            </section> */}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <article>
        <div class="snippet">
        <section className="about-area about-style-three about-us-mobile">
            <div className="container">
              <div className="row align-items-center rev">
                <div className="about-content page-style ml-15">
                  <div className="text-center">
                    <span
                      className="top-title"
                      style={{ fontSize: "35px", paddingBottom: "20px", color: "#5B4B99" }}
                    >
                      PYTHON <span style={{ color: " #F58634" }}></span>
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content page-style ml-15">
                    <p
                      style={{
                        textAlign: "justify",
                        textIndent: "50px",
                        fontSize: "18px",
                      }}
                    >
                      Python is a high-level, interpreted programming language known for its simplicity and readability. 
                      Its clear syntax emphasizes code readability, making it an excellent choice for beginners and experienced 
                      developers alike. Python supports multiple programming paradigms, including procedural, object-oriented, 
                      and functional programming, providing versatility for various applications. It boasts a vast standard library 
                      and a rich ecosystem of third-party packages, which streamline development across different domains. Python 
                      is widely used in web development, data analysis, artificial intelligence, and automation. Its strong community 
                      support and extensive documentation make troubleshooting and learning accessible. With its ease of integration 
                      and broad applicability, Python remains a popular and powerful tool for modern programming challenges.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-img text-center">
                    <div className="videoWrapper">
                      <iframe
                        width="100%"
                        height="330"
                        src="https://www.youtube.com/embed/y0YoTKv6was?si=pDFaCVQ3__MR78oe"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MyChildComponent />
          </section>
        </div>
      </article>
    </div>
  );
};

export default PythonCorses;
