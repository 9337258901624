import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import OnlineRoboticsCourseForm from "./OnlineRoboticsCourseForm"; // Import your form component
import Contacts from "./Contacts"; // Uncomment if you want to include this component

const Enroll = () => {
  const navigate = useNavigate(); // Initialize useNavigate for redirection

  useEffect(() => {
    
    // const token = localStorage.getItem("userToken");
    // console.log("Token:", token); 
    // if (!token) {
     
    //   navigate("/user-login");
    // }

    // Scroll to the top of the page when the component is mounted
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate]);

  return (
    <div>
        <OnlineRoboticsCourseForm />
      <br />
      {/* Uncomment if you want to display contacts */}
      {/* <Contacts /> */}
    </div>
  );
}

export default Enroll;
