import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import emailjs from "emailjs-com"; // Import EmailJS
const Contacts = () => {
    const fieldSelectRef = useRef(null);
    const subFieldSelectRef = useRef(null);
    const [formData, setFormData] = useState({
      firstName: "",
      lastName: "",
      emailId: "",
      contactNumber: "",
      message: "",
      field: "",
      subField: "",
    });
    const [submitStatus, setSubmitStatus] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
  
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
  
      const handleFieldChange = () => {
        const fieldSelect = fieldSelectRef.current;
        const subFieldSelect = subFieldSelectRef.current;
        const mechOptions = subFieldSelect.querySelectorAll(".mech-option");
        const techOptions = subFieldSelect.querySelectorAll(".tech-option");
  
        if (fieldSelect.value === "mechanical-engineering") {
          mechOptions.forEach(option => (option.style.display = "block"));
          techOptions.forEach(option => (option.style.display = "none"));
        } else if (fieldSelect.value === "technotalks") {
          techOptions.forEach(option => (option.style.display = "block"));
          mechOptions.forEach(option => (option.style.display = "none"));
        } else {
          mechOptions.forEach(option => (option.style.display = "none"));
          techOptions.forEach(option => (option.style.display = "none"));
        }
  
        subFieldSelect.value = "";
      };
  
      const fieldSelect = fieldSelectRef.current;
      if (fieldSelect) {
        fieldSelect.addEventListener("change", handleFieldChange);
      }
  
      return () => {
        if (fieldSelect) {
          fieldSelect.removeEventListener("change", handleFieldChange);
        }
      };
    }, []);
  
    useEffect(() => {
      if (category === 'technotalks') {
        fieldSelectRef.current.value = 'technotalks';
        fieldSelectRef.current.dispatchEvent(new Event('change'));
      } else if (category === 'mechanical-engineering') {
        fieldSelectRef.current.value = 'mechanical-engineering';
        fieldSelectRef.current.dispatchEvent(new Event('change'));
      }
    }, [category]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      emailjs.send(
        'service_0me6onf', // Your service ID
        'template_bg1phnd', // Your template ID
        formData, // Your form data
        'u_-37tqnoS6xe-7Wz' // Your public key
      )
      .then((result) => {
        setSubmitStatus("success");
        window.alert("Form submitted successfully. Our Team Will Reach You Soon....!");
      }, (error) => {
        setSubmitStatus("fail");
        window.alert("Failed to submit form. Please try again later.");
        console.error("Error submitting form:", error);
      });
    };
  
  return (
    <div style={{background:"#fff",marginTop:"-24px"}}>
    <div className="container" >
      

      <div className="innerwrap">
        <section className="section2 clearfix">
          <div className="col2 column1 first">
            <iframe
              src="https://www.google.com/maps/d/u/0/embed?mid=107SImYAMn_JeYpaREqei6ySulzxPz1c&ehbc=2E312F"
              width="100%"
              height="580"
              style={{
                border: "none",
                display: "inline-block",
                overflow: "hidden",
                clipPath: "polygon(0 12%, 100% 12%, 100% 100%, 0% 100%)",
              }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="col2 column2 last">
            <div className="sec2contactform">
              <h3 className="sec2frmtitle" style={{ paddingTop: "50px", color: "#5B4B99" }}>
                Want to Know More? <span style={{ color: " #F58634" }}>Drop Us a Mail</span> 
              </h3>
              <form onSubmit={handleSubmit}>
                <div className="clearfix">
                  <input
                    className="col2 first"
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                  <input
                    className="col2 last"
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="clearfix">
                  <input
                    className="col2 first"
                    type="email"
                    placeholder="Email"
                    name="emailId"
                    value={formData.emailId}
                    onChange={handleChange}
                    required
                  />
                  <input
                    className="col2 last"
                    type="text"
                    placeholder="Contact Number"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="clearfix">
                  <select
                    className="col2 first"
                    ref={fieldSelectRef}
                    name="field"
                    onChange={handleChange}
                    required
                    style={{ padding: '18px', backgroundColor: '#ededed', border: 'none' }}
                  >
                    <option value="">Select Field</option>
                    <option value="mechanical-engineering">Mechanical Engineering</option>
                    <option value="technotalks">Technotalks</option>
                  </select>
                  <select
                    className="col2 last"
                    ref={subFieldSelectRef}
                    name="subField"
                    onChange={handleChange}
                    required
                    style={{ padding: '18px', backgroundColor: '#ededed', border: 'none' }}
                  >
                    <option value="">Select Sub-Field</option>
                    {/* Mechanical Engineering options */}
                    <option value="mech1" className="mech-option">TOOL & FIXTURE DESIGN</option>
                    <option value="mech2" className="mech-option">OFFLINE ROBOTICS</option>
                    <option value="mech3" className="mech-option">ONLINE ROBOTICS</option>
                    {/* Technotalks options */}
                    <option value="tech1" className="tech-option">INDUSTRIAL AUTOMATION</option>
                    <option value="tech2" className="tech-option">FIXTURE DESIGN</option>
                    <option value="tech3" className="tech-option">DIGITAL FACILITY SOLUTIONS</option>
                  </select>
                </div>
                <textarea
                  className="col2"
                  name="message"
                  placeholder="Message"
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
                <div className="clearfix">
                  <button type="submit" className="custom-btn btn-3 btn send" style={{background:"#efefef"}}>Send</button>
                </div>
              </form>
              {submitStatus === "success" && <p className="success-message">Your message was sent successfully!</p>}
              {submitStatus === "fail" && <p className="error-message">There was an error sending your message. Please try again later.</p>}
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  )
}

export default Contacts