import React from 'react'
import CollegeSeminors from './CollegeSeminors'
import UniversitiesAlignWithUs from './UniversitiesAlignWithUs'

const CollegeSeminor = () => {
  return (
    <div>

        <CollegeSeminors/>
        <UniversitiesAlignWithUs/>
    </div>
  )
}

export default CollegeSeminor