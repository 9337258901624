import React, { useState } from "react";
import PlacedCandidates from "./PlacedCandidates";
import Partners from "./Partners";
import { Link } from "react-router-dom";
import PlacementServices from "./PlacementServices";
import BusinessPartners from "./BusinessPartners";
import PlacementKeyFeature from "./PlacementKeyFeature";
import PlacementsBanner from "./PlacementsBanner";
import PlacementsCards from "./PlacementsCards";
import PlacemetsAllSlider from "./PlacemetsAllSlider";
import PlacementsCoursesCards from "./PlacementsCoursesCards";
import FlipCards from "./FlipCards";
import Slider from "./Slider";
import Placementssliders from "./Placementssliders";
import AllCourses from "./AllCourses";

const Placements = () => {
  {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
 
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  function MyChildComponent() {
    return (
      <div>
        {" "}
        <div className="">
          <div className="">
            <section
              className="about-area about-style-three about-us-mobile "
              style={{ textAlign: "justify" }}
             >
              <div className="container">
                <div className="row align-items-center ">
                  <div className="about-content page-style ml-15">
                    <div className="text-center">
                      <span
                        className="top-title"
                        style={{
                          textAlign: "justify",
                          fontSize: "30px",
                          lineHeight: "1.8",
                        }}
                        >
                        Key Features of our Placement Support:
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="about-content page-style ml-15 bdstyle conheigh"
                      style={{ textAlign: "justify" }}
                      >
                      <h5>Industry Connections: </h5>
                      <ul>
                        <li>
                          Benefit from our extensive network of industry
                          partners and collaborators. We have strong ties with
                          leading companies that actively seek our graduates.
                        </li>
                      </ul>
                      <h5>Career Counseling:</h5>
                      <ul>
                        <li>
                          Our dedicated career counseling services are designed
                          to help you identify your strengths, interests, and
                          career goals. Receive personalized guidance to make
                          informed decisions about your professional journey.
                        </li>
                      </ul>
                      <h5>Resume Building Workshops:</h5>
                      <ul>
                        <li>
                          Craft a compelling resume that highlights your skills,
                          achievements, and potential. Our workshops guide you
                          through the process of creating a resume that stands
                          out in a competitive job market.
                        </li>
                      </ul>
                      <h5>Mock Interviews:</h5>
                      <ul>
                        <li>
                          Practice makes perfect. Prepare for interviews with
                          our mock interview sessions. Receive constructive
                          feedback from industry professionals and gain
                          confidence in presenting yourself effectively.
                        </li>
                      </ul>
                      <h5>Internship Opportunities:</h5>
                      <ul>
                        <li>
                          Many of our programs include internships with our
                          industry partners. Gain hands-on experience, build
                          your professional network, and increase your chances
                          of securing a full-time position upon graduation.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="about-content page-style ml-15 bdstyle conheigh"
                      style={{ paddingBottom: "90px" }}
                    >
                      <h5>Job Placement Events:</h5>
                      <ul>
                        <li>
                          Attend exclusive job placement events hosted on our
                          campus. Connect with recruiters, submit your resume,
                          and interview for exciting career opportunities.
                        </li>
                      </ul>
                      <h5>Alumni Network:</h5>
                      <ul>
                        <li>
                          Join a vibrant alumni community that spans various
                          industries. Benefit from the experiences and insights
                          of those who have walked the same path and
                          successfully launched their careers.
                        </li>
                      </ul>
                      <h5>Continuous Support: </h5>
                      <ul>
                        <li>
                          Our placement support doesn't end with graduation. We
                          provide ongoing assistance, keeping you informed about
                          industry trends, job opportunities, and professional
                          development resources.
                        </li>
                      </ul>
                      <h5>Success Stories:</h5>
                      <ul>
                        <li>
                          Explore the success stories of our alumni who have
                          secured positions in top companies. Your success is
                          our success, and we take pride in celebrating your
                          achievements.
                        </li>
                      </ul>
                      <Link to={'/placement-services'}> <button className="custom-btn btn-3"><span>placements</span></button></Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
     {/* <PlacemetsAllSlider /> */}
     <Placementssliders />
     {/* <Slider /> */}
     {/* <PlacementsCoursesCards /> */}
     <FlipCards />
     {/* <AllCourses/> */}
      <article>
     
        <div className="snippet">
          {/* <section className="about-area about-style-three about-us-mobile ">
            <div className="container">
              <div className="row align-items-center rev">
                <div className="about-content page-style ml-15">
                  <div className="text-center">
                  <h1 style={{ color: "#524DA1", fontSize: "40px", lineHeight: "1.3" }}>
                  PLACEMENTS
                </h1>
                
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content page-style ml-15">
                    <p
                      style={{
                        textAlign: "justify",
                        fontSize: "18px",
                        textIndent: "50px",
                      }}
                    >
                      Career Support and Placement Services
                      We go beyond teaching by ensuring our students transition smoothly into successful careers. Our job-oriented training programs and extensive industry network help graduates secure jobs promptly after completing their training.
                    </p>
                    <p
                      style={{
                        textAlign: "justify",
                        fontSize: "18px",
                        textIndent: "50px",
                      }}
                    >
                    With a placement success rate of 84% and over 200 students placed, we excel in providing career opportunities. We organize both off-campus and on-campus placement drives, partnering with leading companies to connect students with top employers.
                    </p>
                    
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-img text-center">
                    <div className="videoWrapper">
                      <iframe
                        width="100%"
                        height="300"
                        src="https://www.youtube.com/embed/y0YoTKv6was?si=pDFaCVQ3__MR78oe"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          
          </section> */}
        </div>
      </article>
      <PlacementKeyFeature/>
      {/* <PlacementServices/> */}
      {/* <PlacedCandidates /> */}
   <PlacementsCards />

      {/* <Partners /> */}
      {/* <BusinessPartners/> */}
    </div>
  );
};

export default Placements;
