import React from 'react'
import technotalk from '../img/section-images/technotalks.jpg'
const TechnoTalksBriefExp = () => {
  return (
    <div> <section className="about-area about-style-three about-us-mobile" style={{background:"rgb(54, 48, 74)"}}>
    <div className="container" >
      <div className="row align-items-center rev" > 
      <div className="about-content page-style ml-15">
            <div className="text-center">
            <h1 style={{ color: "rgb(255, 255, 255)", fontSize: "40px", lineHeight: "1.3",paddingTop:"15px" }}>
                 TECHNO-<span style={{ color: "#F58634" }}> TALKS</span>
                </h1>
              {/* <span className="top-title" style={{color:'#544395',fontSize: "30px",lineHeight:'1.6'}}>TECHNO-<span style={{ color: " #F58634" }}>TALKS</span></span> */}
            </div>
            </div>         
        <div className="col-lg-6">
          <div className="about-content page-style ml-15">
           
            <p style={{ textAlign: 'justify',fontSize: '18px',textIndent: '50px',lineHeight:'1.5', color: '#c8c7c7'}}>
            Techno-Talks Series is a hub of innovation and insights where technology and industry trends converge. Designed to inspire aspiring engineers, it features discussions led by thought leaders and experts across various tech domains.
            </p>
            <p style={{ textAlign: 'justify',fontSize: '18px',textIndent: '50px',lineHeight:'1.5', color: '#c8c7c7'}}>
            Technology is advancing rapidly, transforming our lives in unprecedented ways. From the Internet of Things to countless emerging trends, exciting opportunities await. Techno-Talks offers live, interactive discussions on the latest technological developments, providing valuable insights from industry professionals and subject matter experts. 
            </p>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-img text-center rev">

          <div className="vedio-heading">
          <div className="videoWrapper">
                      <iframe
                        width="100%"
                        height="300"
                        src="https://www.youtube.com/embed/y0YoTKv6was?si=pDFaCVQ3__MR78oe"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
          </div>
          </div>
            {/* <img src={technotalk} alt="Image" /> */}
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
  )
}
export default TechnoTalksBriefExp