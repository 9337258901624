import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const PlacementKeyFeature = () => {
    const cardsRef = useRef([]);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("visible");
              observer.unobserve(entry.target); // Stop observing once it's visible
            }
          });
        },
        {
          threshold: 0.1, // Trigger when 10% of the element is visible
        }
      );
  
      cardsRef.current.forEach((card) => {
        if (card) {
          observer.observe(card);
        }
      });
  
      return () => {
        cardsRef.current.forEach((card) => {
          if (card) {
            observer.unobserve(card);
          }
        });
      };
    }, []);
  
    const cards = [
        {
          icon: "fa-solid fa-graduation-cap",
          title: "Industry Connections",
          text: "Benefit from our extensive network of industry partners and collaborators. We have strong ties with leading companies that actively seek our graduates.",
        },
        {
          icon: "fa-solid fa-book-open",
          title: "Career Counseling",
          text: "Our dedicated career counseling services are designed to help you identify your strengths, interests, and career goals. ",
        },
        {
          icon: "fa-solid fa-users",
          title: "Resume Mastery Workshops",
          text: " Build a resume that highlights your strengths and achievements. Our workshops offer expert guidance to help you stand out in a competitive job market.",
        },
        {
          icon: "fa-solid fa-child-reaching",
          title: "Mock Interviews",
          text: "Practice makes perfect. Prepare for interviews with our mock interview sessions. Receive constructive feedback from industry professionals and gain confidence in presenting yourself effectively.",
        },
        {
          icon: "fa-solid fa-bookmark",
          title: "Internship Opportunities",
          text: "Many of our programs include internships with our industry partners. Gain hands-on experience, build your professional network, increase your chances of securing a full-time position upon graduation.",
        },
        {
          icon: "fa-solid fa-user-tie",
          title: "Job Placement Events",
          text: "Attend exclusive job placement events hosted on our campus. Connect with recruiters, submit your resume, and interview for exciting career opportunities.",
        },
        {
          icon: "fa-solid fa-child-reaching",
          title: "Alumni Network",
          text: "Join a vibrant alumni community that spans various industries. Benefit from the experiences and insights of those who have walked the same path and successfully launched their careers.",
        },
        {
          icon: "fa-solid fa-bookmark",
          title: "Continuous Support",
          text: "Our placement support doesn't end with graduation. We provide ongoing assistance, keeping you informed about industry trends, job opportunities, and professional development resources.",
        },
        {
          icon: "fa-solid fa-user-tie",
          title: "Success Stories",
          text: "Explore the success stories of our alumni who have secured positions in top companies. Your success is our success, and we take pride in celebrating your achievements.",
        },
      ];
      
  
    return (
        <div className="container-fluid" >
        <div className="container">
          {/* Header */}
          <h1 style={{ color: "rgb(70 53 133)", fontSize: "40px", lineHeight: "1.3",padding:'20px' }}>
            KEY <span style={{ color: "#F58634" }}>FEATURES</span>
          </h1>
      
          {/* First row */}
          <div className="row containerbd">
            {cards.slice(0, 3).map((card, index) => (
              <div
                key={index}
                className="col-md-4 anicard"
                ref={(el) => (cardsRef.current[index] = el)}
              >
                <div className="face face1">
                  <div className="contented">
                    <FontAwesomeIcon
                      icon={card.icon}
                      style={{ fontSize: "40px", color: "#B197FC" }}
                    />
                    <h3>{card.title}</h3>
                  </div>
                </div>
                <div className="face face2">
                  <div className="contented">
                    <p>{card.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
      
          {/* Second row */}
          <div className="row containerbd" style={{ paddingTop: "20px" }}>
            {cards.slice(3, 6).map((card, index) => (
              <div
                key={index + 3}
                className="col-md-4 anicard"
                ref={(el) => (cardsRef.current[index + 3] = el)}
              >
                <div className="face face1">
                  <div className="contented">
                    <FontAwesomeIcon
                      icon={card.icon}
                      style={{ fontSize: "40px", color: "#B197FC" }}
                    />
                    <h3>{card.title}</h3>
                  </div>
                </div>
                <div className="face face2">
                  <div className="contented">
                    <p style={{ color: '#c8c7c7'}}>{card.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
      
          {/* Third row */}
          <div className="row containerbd" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
            {cards.slice(6, 9).map((card, index) => (
              <div
                key={index + 6}
                className="col-md-4 anicard"
                ref={(el) => (cardsRef.current[index + 6] = el)}
              >
                <div className="face face1">
                  <div className="contented">
                    <FontAwesomeIcon
                      icon={card.icon}
                      style={{ fontSize: "40px", color: "#B197FC" }}
                    />
                    <h3>{card.title}</h3>
                  </div>
                </div>
                <div className="face face2">
                  <div className="contented">
                    <p style={{ color: '#c8c7c7'}}>{card.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
export default PlacementKeyFeature