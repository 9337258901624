import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import toolfix from "../img/banners/V6.jpg";
import onlinerobotics from "../img/banners/online-robotics.jpg";
import offlinerobotics from "../img/banners/offline-robotics.jpg";
import react from "../img/banners/REACT-&-MOBILE-APP.jpg";
import java from "../img/banners/JAVA.jpg";
import flutter from "../img/banners/Filter-mobile-app.jpg";
import techtalk from "../img/banners/TECHNO-TALKS.jpg";
import { Link } from "react-router-dom";
import DownloadBrocherpopup from "./DownloadBrocherpopup"; // Modal
import ButtonPage from './ButtonPage'; // Button component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';



import reactbanner from '../img/banners/V2.jpg';
import javabanner from '../img/banners/V1.jpg';
import offlinecourse from '../img/banners/V3.jpg';
import reactbanners from '../img/banners/online-robotics.jpg';
import flutterban from '../img/banners/Filter-mobile-app.jpg';
const AllcoursesSlider = () => {
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    return (
        <div>
            <Carousel
                className="res-nav-mob"
                interval={3000}
                prevIcon={
                    <span >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </span>
                }
                nextIcon={
                    <span>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                }
            >


                {/* First Slide - TOOL & FIXTURE DESIGN */}


                {/* Second Slide - ONLINE ROBOTICS */}

                <Carousel.Item>
                    <img className="d-block w-100" src={toolfix} alt="Tool & Fixture Design" style={{ height: "650px" }} />
                    <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
                        <div className="row">
                            {/* Column 1 */}
                            <div className="col-lg-4 dtd">
                                <div style={{ width: "100%" }}>
                                    <h2 className="banner-content" style={{ fontFamily: "Poppins, SansSerif", fontSize: "32px", fontWeight: "600", width: "139%" }}>
                                        Unlock your potential in manufacturing "From Concept to Creation"

                                    </h2>
                                    <h2 className="banner-content" style={{ fontSize: "40px", color: "#fff", fontFamily: "Roboto", fontWeight: "800", widt: '120%' }}>
                                        TOOL & FIXTURE <span style={{ color: "#F58842" }}>DESIGN</span>
                                    </h2>
                                    <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "400", fontWeight: "bold" }}>
                                        1. Industrial Oriented Training (IOT)
                                    </p>
                                    <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "400", fontWeight: "bold" }}>
                                        2. Job Oriented Skill Enhancement (JOSE)
                                    </p>
                                    <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "400", fontWeight: "bold" }}>
                                        3. 100% Placement Support
                                    </p>
                                    <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "400", marginLeft: "49px", fontWeight: "bold" }}>
                                        NX & CATIA Software free training
                                    </p>
                                </div>
                            </div>

                            {/* Column 2 data not founding in */}
                            <div className="col-lg-8 dtdt">
                                <h2 className="banner-contente" style={{ fontFamily: "Poppins, SansSerif", fontSize: "27px", fontWeight: "600" }}>
                                    “Only Institute Automating Careers Across India"
                                </h2>
                            </div>

                        </div>
                        <div className="d-flex flex-column flex-md-row align-items-center kgl" style={{ display: "flex", gap: "60px" }}>
                            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                                {/* Button to trigger the modal */}
                                <ButtonPage handleShow={handleShow} />
                                {/* Modal Component */}
                                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} />
                            </div>

                            <div>
                                <Link
                                    to="/tool-&-fixture-design"
                                    className="btn"
                                    style={{ backgroundColor: "#524DA1", color: "#fff", padding: "10px 20px" }}
                                >
                                    View More
                                </Link>
                            </div>
                        </div>



                    </Carousel.Caption>

                </Carousel.Item>






                <Carousel.Item>
                    <img className="d-block w-100" src={onlinerobotics} alt="Online Robotics" style={{ height: "650px" }} />
                    <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
                        <div className="row">
                            {/* Column 1 */}
                            <div className="col-lg-4 dtd" style={{}}>
                                <div className="" style={{ width: "110%" }}>

                                    <h2 className="banner-content" style={{ fontSize: "32px", color: "#000", fontFamily: "Raleway!important", fontWeight: "600", marginTop: "-50px" }}     >
                                        India's First Indigenous Industrial robotics Training center
                                    </h2>
                                    <h2 className="banner-content" style={{ fontSize: "35px", color: "#524DA1", fontFamily: "Raleway!important", fontWeight: "bold", }}     >

                                        ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>

                                    </h2>
                                    {/* <h2   style={{  fontSize: "40px", color: "#524DA1",  fontFamily: "roboto",  fontWeight: "800",    }}     >
          
                        ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>
                        </h2> */}
                                    <p className="banner-contentul" style={{ fontSize: "17px", color: "#000", textAlign: "justify", textIndent: "50px", fontWeight: "400", fontWeight: "bold" }}  >
                                        1 Industrial Oriented Training(IOT)

                                    </p>
                                    <p className="banner-contentul" style={{ fontSize: "17px", color: "#000", textAlign: "justify", textIndent: "50px", fontWeight: "400", fontWeight: "bold" }} >
                                        2. Job oriented skill enhancement (JOSE)
                                    </p>
                                    <p className="banner-contentul" style={{ fontSize: "17px", color: "#000", textAlign: "justify", textIndent: "50px", fontWeight: "400", fontWeight: "bold" }}  >
                                        3. 100% Placement Support
                                    </p>
                                    <p className="banner-contentul" style={{ fontSize: "25px", color: "#000", textAlign: "justify", fontWeight: "400", fontWeight: "bold" }}   >  Delmia Simulation Software Free Training

                                    </p>
                                    {/* <div className="view-btn">
                   
                    <Link to="/online-robotics" className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "8px 20px", float: "right", }}>
                      View More
                    </Link>
                  </div> */}
                                </div>
                            </div>

                            <div className="col-lg-8 dtdt ">
                                <h2 className="banner-contento" style={{ fontSize: "32px", color: "rgb(82 77 161)", fontFamily: "Raleway!important", fontWeight: "600", marginTop: "-54px", marginLeft: "50px" }}    >
                                    “A course that can Automate your Career "
                                </h2>

                            </div>
                        </div>
                        {/* <div style={{}}>
              <div> 
                <ButtonPage handleShow={handleShow} />

               
                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} /></div>


            </div> */}
                        <div className="d-flex flex-column flex-md-row align-items-center kgl" style={{ display: "flex", gap: "60px" }}>
                            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                                {/* Button to trigger the modal */}
                                <ButtonPage handleShow={handleShow} />
                                {/* Modal Component */}
                                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} />
                            </div>

                            <div className="">
                                <Link
                                    to="/online-robotics"

                                    className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "10px 20px", float: "right", }}
                                >
                                    View More
                                </Link>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>

                {/* Third Slide - OFFLINE ROBOTICS */}
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={offlinerobotics}
                        alt="Offline Robotics"
                        style={{ height: "650px" }}
                    />
                    <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
                        <div className="row">
                            {/* Column 1 */}
                            <div className="col-lg-4 dtd" style={{}}>
                                <div className="" style={{ width: "110%" }}>

                                    <h2 className="banner-content" style={{ fontSize: "32px", color: "#000", fontFamily: "Raleway!important", fontWeight: "600", }}     >
                                        Turn your passion into expertise in robotics From Theory to Real-World Automation
                                    </h2>
                                    <h2 className="banner-content" style={{ fontSize: "30px", color: "#524DA1", fontFamily: "Raleway!important", fontWeight: "bold", }}     >

                                        OFFLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>

                                    </h2>
                                    {/* <h2   style={{  fontSize: "40px", color: "#524DA1",  fontFamily: "roboto",  fontWeight: "800",    }}     >
          
            ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>
            </h2> */}
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#000",
                                            fontWeight: "bold",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                        }}
                                    >
                                        1. Industry-Specific Robotics Training (ISR)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#000",
                                            fontWeight: "bold",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                        }}
                                    >
                                        2. Career-Focused Skills Advancement (CFSA)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#000",
                                            fontWeight: "bold",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                        }}
                                    >
                                        3. 100% Placement Support
                                    </p>
                                    {/* <div className="view-btn">
                 
                    <Link to="/offline-robotics" className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "8px 20px", float: "right", }}>
                      View More
                    </Link>
                  </div> */}
                                </div>
                            </div>

                            <div className="col-lg-8 dtdt ">
                                <h2 className="banner-contentof" style={{ fontSize: "32px", marginTop: "-50px", color: "rgb(82 77 161)", fontFamily: "Raleway!important", fontWeight: "600", width: '60%', textAlign: 'center' }}    >
                                    “Revolutionize Your Career with Offline Robotics Across India”
                                </h2>

                            </div>
                        </div>
                        {/* <div style={{}}>
              <div> 
                <ButtonPage handleShow={handleShow} />

              
                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} /></div>


            </div> */}

                        <div className="d-flex flex-column flex-md-row align-items-center kgl" style={{ display: "flex", gap: "60px" }}>
                            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                                {/* Button to trigger the modal */}
                                <ButtonPage handleShow={handleShow} />
                                {/* Modal Component */}
                                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} />
                            </div>

                            <div className="">
                                <Link
                                    to="/offline-robotics"

                                    className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "10px 20px", float: "right", }}
                                >
                                    View More
                                </Link>
                            </div>
                        </div>

                    </Carousel.Caption>

                </Carousel.Item>

                {/* Fourth Slide - JAVA */}
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={java}
                        alt="Java Programming"
                        style={{ height: "650px" }}
                    />


                    <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
                        <div className="row">
                            {/* Column 1 */}
                            <div className="col-lg-4 dtd" style={{}}>
                                <div className="" style={{ width: "110%" }}>

                                    <h2 className="banner-content" style={{ fontSize: "32px", color: "#fff", fontFamily: "Raleway!important", fontWeight: "600", width: '139%' }}     >
                                        Transform ideas into robust applications From Coding Basics to Enterprise Solutions
                                    </h2>
                                    <h2 className="banner-content"
                                        style={{
                                            fontSize: "40px",
                                            color: "#fff",
                                            fontFamily: "Raleway!important",
                                            fontWeight: "800",
                                        }}
                                    >
                                        JAVA <span style={{ color: "#F58842" }}>DEVELOPMENT</span>
                                    </h2>
                                    {/* <h2   style={{  fontSize: "40px", color: "#524DA1",  fontFamily: "roboto",  fontWeight: "800",    }}     >
          
            ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>
            </h2> */}
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        1. Java-Driven Application Development (JAD)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        2. Professional Skill Enrichment (PSE)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        3. 100% Placement Support
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "19px",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            textAlign: "justify",
                                            marginLeft: "49px"
                                        }}
                                    >
                                        Free training in Spring Boot & Hibernate frameworks
                                    </p>

                                    {/* <div className="view-btn">
                  
                    <Link to="/Java-corses" className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "8px 20px", float: "right", }}>
                      View More
                    </Link>
                  </div> */}
                                </div>
                            </div>

                            <div className="col-lg-8 dtdt ">
                                <h2 className="banner-contente" style={{ fontSize: "32px", color: "#fff", fontFamily: "Raleway!important", fontWeight: "600", }}    >
                                    “Empowering Future Developers Across India”
                                </h2>

                            </div>
                        </div>
                        {/* <div style={{}}>
              <div>
                <ButtonPage handleShow={handleShow} />

           
                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} /></div>


            </div> */}
                        <div className="d-flex flex-column flex-md-row align-items-center kgl" style={{ display: "flex", gap: "60px" }}>
                            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                                {/* Button to trigger the modal */}
                                <ButtonPage handleShow={handleShow} />
                                {/* Modal Component */}
                                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} />
                            </div>

                            <div className="">
                                <Link
                                    to="/Java-corses"

                                    className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "10px 20px", float: "right", }}
                                >
                                    View More
                                </Link>
                            </div>
                        </div>
                    </Carousel.Caption>






                </Carousel.Item>

                {/* Fifth Slide - REACTJS */}
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={react}
                        alt="ReactJS Web Application"
                        style={{ height: "650px" }}
                    />
                    <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
                        <div className="row">
                            {/* Column 1 */}
                            <div className="col-lg-4 dtd" style={{}}>
                                <div className="" style={{ width: "110%" }}>

                                    <h2 className="banner-content" style={{ fontSize: "32px", color: "#fff", fontFamily: "Raleway!important", fontWeight: "600", width: "120%" }}     >
                                        Craft dynamic web applications with ease From Components to Full-Scale Solutions
                                    </h2>
                                    <h2 className="banner-content"
                                        style={{
                                            fontSize: "30px",
                                            color: "#fff",
                                            fontFamily: "Raleway!important",
                                            fontWeight: "bold",

                                        }}
                                    >
                                        REACTJS WEB <span style={{ color: "#F58842" }}>APPLICATION</span>
                                    </h2>
                                    {/* <h2   style={{  fontSize: "40px", color: "#524DA1",  fontFamily: "roboto",  fontWeight: "800",    }}     >
          
            ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>
            </h2> */}
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        1. Advanced ReactJS Techniques (ART)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        2. Web-Ready Skill Building (WSB)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        3. 100% Placement Support
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "20px",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            textAlign: "justify",
                                            marginLeft: "49px"
                                        }}
                                    >
                                        Free Next.js & Redux toolkit included
                                    </p>

                                    {/* <div className="view-btn">
                 
                    <Link to="/ReactJs-corses" className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: " 8px 20px", float: "right", }}>
                      View More
                    </Link>
                  </div> */}
                                </div>
                            </div>

                            <div className="col-lg-8 dtdt ">
                                <h2 className="banner-contentof" style={{ fontSize: "32px", color: "#fff", fontFamily: "Raleway!important", fontWeight: "600", }}    >
                                    “Redefining Frontend Development Across India”
                                </h2>

                            </div>
                        </div>
                        {/* <div style={{}}>
              <div>
                <ButtonPage handleShow={handleShow} />

  
                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} /></div>


            </div> */}
                        <div className="d-flex flex-column flex-md-row align-items-center kgl" style={{ display: "flex", gap: "60px" }}>
                            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                                {/* Button to trigger the modal */}
                                <ButtonPage handleShow={handleShow} />
                                {/* Modal Component */}
                                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} />
                            </div>

                            <div className="">
                                <Link
                                    to="/ReactJs-corses"

                                    className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "10px 20px", float: "right", }}
                                >
                                    View More
                                </Link>
                            </div>
                        </div>
                    </Carousel.Caption>

                </Carousel.Item>

                {/* Sixth Slide - FLUTTER */}
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={flutter}
                        alt="Flutter Mobile App"
                        style={{ height: "650px" }}
                    />

                    <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
                        <div className="row">
                            {/* Column 1 */}
                            <div className="col-lg-4 dtd" style={{}}>
                                <div className="" style={{ width: "110%" }}>

                                    <h2 className="banner-content"
                                        style={{
                                            fontSize: "32px",
                                            color: "#fff",
                                            fontFamily: "Raleway!important",
                                            fontWeight: "600",
                                            width: "145%"
                                        }}
                                    >
                                        Master cross-platform app development From Design to Deployment
                                    </h2>
                                    <h2 className="banner-content"
                                        style={{
                                            fontSize: "40px",
                                            color: "#fff",
                                            fontFamily: "Raleway!important",
                                            fontWeight: "800",
                                        }}
                                    >
                                        FLUTTER MOBILE <span style={{ color: "#F58842" }}> APP</span>
                                    </h2>
                                    {/* <h2   style={{  fontSize: "40px", color: "#524DA1",  fontFamily: "roboto",  fontWeight: "800",    }}     >
          
            ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>
            </h2> */}
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        1. Industry-Oriented Flutter Training (IFT)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        2. Mobile Development Skill Enhancement (MDSE)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        3. 100% Placement Support
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "22px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            // marginLeft: "49px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        Free Dart programming language training provided
                                    </p>


                                </div>
                            </div>

                            <div className="col-lg-8 dtdt ">
                                <h2 className="banner-content" style={{ fontSize: "30px", color: "#fff", fontFamily: "Raleway!important", fontWeight: "600", }}    >
                                    “Shape the Future of Mobile Apps Across India”
                                </h2>

                            </div>
                        </div>

                        {/* <div style={{}}>
              <div> 
                <ButtonPage handleShow={handleShow} />

                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} /></div>


            </div> */}

                        <div className="d-flex flex-column flex-md-row align-items-center kgl" style={{ display: "flex", gap: "60px" }}>
                            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                                {/* Button to trigger the modal */}
                                <ButtonPage handleShow={handleShow} />
                                {/* Modal Component */}
                                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} />
                            </div>

                            <div className="">
                                <Link
                                    to="/flutter-corses"

                                    className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "10px 20px", float: "right", }}
                                >
                                    View More
                                </Link>
                            </div>
                        </div>
                    </Carousel.Caption>


                </Carousel.Item>

                <Carousel.Item>
                    <img className="d-block w-100" src={javabanner} alt="Tool & Fixture Design" style={{ height: "650px" }} />
                    <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
                        <div className="row">
                            {/* Column 1 */}
                            <div className="col-lg-4 dtd" style={{}}>
                                <div className="" style={{ width: "110%" }}>

                                    <h2 className="banner-content" style={{ fontSize: "32px", color: "#fff", fontFamily: "Raleway!important", fontWeight: "600", }}     >
                                        Transform ideas into robust applications From Coding Basics to Enterprise Solutions
                                    </h2>
                                    <h2 className="banner-content"
                                        style={{
                                            fontSize: "40px",
                                            color: "#fff",
                                            fontFamily: "Raleway!important",
                                            fontWeight: "800",
                                        }}
                                    >
                                        CLOUD <span style={{ color: "#F58842" }}>COMPUTING</span>
                                    </h2>
                                    {/* <h2   style={{  fontSize: "40px", color: "#524DA1",  fontFamily: "roboto",  fontWeight: "800",    }}     >
          
                     ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>
                    </h2> */}
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        1. Cloud Computing-Driven Application Development (JAD)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        2. Professional Skill Enrichment (PSE)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        3. 100% Placement Support
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "19px",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            textAlign: "justify",
                                            marginLeft: "49px"
                                        }}
                                    >
                                        Free training in Cloud Computing
                                    </p>


                                </div>
                            </div>

                            <div className="col-lg-8 dtdt ">
                                <h2 className="banner-content" style={{ fontSize: "32px", color: "#fff", fontFamily: "Raleway!important", fontWeight: "600", }}    >
                                    “Empowering Innovation with Cloud Computing”
                                </h2>

                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row align-items-center kgl" style={{ display: "flex", gap: "60px" }}>
                            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                                {/* Button to trigger the modal */}
                                <ButtonPage handleShow={handleShow} />
                                {/* Modal Component */}
                                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} />
                            </div>

                            <div className="">
                                <Link
                                    to="/cloud-computing"

                                    className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "10px 20px", float: "right", }}
                                >
                                    View More
                                </Link>
                            </div>
                        </div>
                    </Carousel.Caption>


                </Carousel.Item>




                <Carousel.Item>
                    <img className="d-block w-100" src={reactbanner} alt="Tool & Fixture Design" style={{ height: "650px" }} />
                    <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
                        <div className="row">
                            {/* Column 1 */}
                            <div className="col-lg-4 dtd" style={{}}>
                                <div className="" style={{ width: "110%" }}>

                                    <h2 className="banner-content" style={{ fontSize: "32px", color: "#fff", fontFamily: "Raleway!important", fontWeight: "600", }}     >
                                        Craft dynamic web applications with ease From Components to Full-Scale Solutions
                                    </h2>
                                    <h2 className="banner-content"
                                        style={{
                                            fontSize: "30px",
                                            color: "#fff",
                                            fontFamily: "Raleway!important",
                                            fontWeight: "bold",

                                        }}
                                    >
                                        DATA <span style={{ color: "#F58842" }}> ANALYTICS</span>
                                    </h2>

                                    {/* <h2   style={{  fontSize: "40px", color: "#524DA1",  fontFamily: "roboto",  fontWeight: "800",    }}     >
          
                                       ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>
                                       </h2> */}
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        1. Advanced Data Analytics Techniques (ART)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        2. Web-Ready Skill Building (WSB)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        3. 100% Placement Support
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "20px",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            textAlign: "justify",
                                            marginLeft: "49px"
                                        }}
                                    >
                                        Free Data Analytics toolkit included
                                    </p>

                                    
                                </div>
                            </div>

                            <div className="col-lg-8 dtdt ">
                                <h2 className="banner-content" style={{ fontSize: "32px", color: "#fff", fontFamily: "Raleway!important", fontWeight: "600", }}    >
                                    "Unlock Insights, Drive Decisions with Data Analytics"
                                </h2>

                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row align-items-center kgl" style={{ display: "flex", gap: "60px" }}>
                            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                                {/* Button to trigger the modal */}
                                <ButtonPage handleShow={handleShow} />
                                {/* Modal Component */}
                                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} />
                            </div>

                            <div className="">
                                <Link
                                    to="/data-analystic"

                                    className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "10px 20px", float: "right", }}
                                >
                                    View More
                                </Link>
                            </div>
                        </div>
                    </Carousel.Caption>


                </Carousel.Item>

                <Carousel.Item>
                    <img className="d-block w-100" src={offlinecourse} alt="Tool & Fixture Design" style={{ height: "650px" }} />

                    <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
                        <div className="row">
                            {/* Column 1 */}
                            <div className="col-lg-4 dtd" style={{}}>
                                <div className="" style={{ width: "110%" }}>

                                    <h2 className="banner-content" style={{ fontSize: "32px", fontFamily: "Raleway!important", fontWeight: "600", }}     >
                                        Unlocking Human Potential with Artificial Intelligence Solutions for Progress.
                                    </h2>
                                    <h2 className="banner-content" style={{ fontSize: "30px", color: "#524DA1", fontFamily: "Raleway!important", fontWeight: "bold", }}     >

                                        ARTIFICIAL <span style={{ color: "#F58842" }}> INTELLIGENCE</span>

                                    </h2>
                                    {/* <h2   style={{  fontSize: "40px", color: "#524DA1",  fontFamily: "roboto",  fontWeight: "800",    }}     >

                 ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>
                 </h2> */}
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",

                                            fontWeight: "bold",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                        }}
                                    >
                                        1. Industry-Specific AI & ML Training (ISAM)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",

                                            fontWeight: "bold",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                        }}
                                    >
                                        2. Career-Focused Skills Advancement (CFSA)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",

                                            fontWeight: "bold",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                        }}
                                    >
                                        3. 100% Placement Support
                                    </p>

                                </div>
                            </div>

                            <div className="col-lg-8 dtdt ">
                                <h2 className="banner-contentof" style={{ fontSize: "32px", marginTop: "-50px", color: "#fff", fontFamily: "Raleway!important", fontWeight: "600", width: '72%', textAlign: 'center' }}    >
                                    “Transform the Future with Artificial Intelligence and Machine Learning”
                                </h2>

                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row align-items-center kgl" style={{ display: "flex", gap: "60px" }}>
                            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                                {/* Button to trigger the modal */}
                                <ButtonPage handleShow={handleShow} />
                                {/* Modal Component */}
                                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} />
                            </div>

                            <div className="">
                                <Link
                                    to="/ai-ml-solutions"

                                    className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "10px 20px", float: "right", }}
                                >
                                    View More
                                </Link>
                            </div>
                        </div>
                    </Carousel.Caption>


                </Carousel.Item>




                <Carousel.Item>
                    <img className="d-block w-100" src={reactbanners} alt="Tool & Fixture Design" style={{ height: "650px" }} />
                    <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
                        <div className="row">
                            {/* Column 1 */}
                            <div className="col-lg-4 dtd" style={{}}>
                                <div className="" style={{ width: "110%" }}>

                                    <h2 className="banner-content" style={{ fontSize: "32px", color: "#000", fontFamily: "Raleway!important", fontWeight: "600", }}     >
                                        First Indigenous Industrial Robotics Training Center for Innovation Growth.
                                    </h2>
                                    <h2 className="banner-content"
                                        style={{
                                            fontSize: "30px",
                                            color: "#000",
                                            fontFamily: "Raleway!important",
                                            fontWeight: "bold",

                                        }}
                                    >
                                        AUTOMATION <span style={{ color: "#F58842" }}> SOLUTIONS</span>
                                    </h2>

                                    {/* <h2   style={{  fontSize: "40px", color: "#524DA1",  fontFamily: "roboto",  fontWeight: "800",    }}     >
          
                       ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>
                     
                     </h2> */}

                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#000",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        1. Industrial Oriented Training(IOT)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#000",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        2. Job oriented skill enhancement (JOES)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#000",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        3. 100% Placement Support
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "25px",
                                            color: "#000",
                                            fontWeight: "bold",
                                            textAlign: "justify",
                                            marginLeft: "49px"
                                        }}
                                    >
                                        Delmia Simulation Software Free Training
                                    </p>

                                    
                                </div>
                            </div>

                            <div className="col-lg-8 dtdt ">
                                <h2 className="banner-content" style={{ fontSize: "32px", color: "#000", fontFamily: "Raleway!important", fontWeight: "600", }}    >
                                    “Streamline Processes with Automation”
                                </h2>

                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row align-items-center kgl" style={{ display: "flex", gap: "60px" }}>
                            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                                {/* Button to trigger the modal */}
                                <ButtonPage handleShow={handleShow} />
                                {/* Modal Component */}
                                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} />
                            </div>

                            <div className="">
                                <Link
                                    to="/automation-solutions"

                                    className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "10px 20px", float: "right", }}
                                >
                                    View More
                                </Link>
                            </div>
                        </div>
                    </Carousel.Caption>


                </Carousel.Item>




                <Carousel.Item>
                    <div className="banner-img">
                        <img className="d-block w-100" src={flutterban} alt="Tool & Fixture Design" style={{ height: "650px" }} />
                    </div>
                    <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
                        <div className="row">
                            {/* Column 1 */}
                            <div className="col-lg-4 dtd" style={{}}>
                                <div className="" style={{ width: "110%" }}>

                                    <h2 className="banner-content"
                                        style={{
                                            fontSize: "32px",
                                            color: "#fff",
                                            fontFamily: "Raleway!important",
                                            fontWeight: "600",
                                            width: "115%"
                                        }}
                                    >
                                        Master cross-platform app development From Design to Deployment
                                    </h2>
                                    <h2 className="banner-content"
                                        style={{
                                            fontSize: "40px",
                                            color: "#fff",
                                            fontFamily: "Raleway!important",
                                            fontWeight: "800",
                                        }}
                                    >
                                        ECOMMERCE AND  <span style={{ color: "#F58842" }}> DIGITAL MARKETING</span>
                                    </h2>
                                    {/* <h2   style={{  fontSize: "40px", color: "#524DA1",  fontFamily: "roboto",  fontWeight: "800",    }}     >
          
                  ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>
                  </h2> */}
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        1. E-commerce & Digital Marketing Training (EDMT)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        2. Mobile Development Skill Enhancement (MDSE)
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "17px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            textIndent: "50px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        3. 100% Placement Support
                                    </p>
                                    <p className="banner-contentul"
                                        style={{
                                            fontSize: "22px",
                                            color: "#fff",
                                            fontWeight: "400",
                                            textAlign: "justify",
                                            // marginLeft: "49px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        Free Dart programming language training provided
                                    </p>

                                    
                                </div>
                            </div>

                            <div className="col-lg-8 dtdt ">
                                <h2 className="banner-content" style={{ fontSize: "30px", color: "#fff", fontFamily: "Raleway!important", fontWeight: "600", }}    >
                                    “Empowering Growth through E-Commerce and Digital ”
                                </h2>

                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row align-items-center kgl" style={{ display: "flex", gap: "60px" }}>
                            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                                {/* Button to trigger the modal */}
                                <ButtonPage handleShow={handleShow} />
                                {/* Modal Component */}
                                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} />
                            </div>

                            <div className="">
                                <Link
                                    to="/ecommerce-digital-marketing"

                                    className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "10px 20px", float: "right", }}
                                >
                                    View More
                                </Link>
                            </div>
                        </div>
                    </Carousel.Caption>


                </Carousel.Item>
                {/* Seventh Slide - TECHNOTALKS */}

            </Carousel>
        </div>
    );
};

export default AllcoursesSlider;
