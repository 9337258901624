import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const Pricing = () => {
  {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
 // api integration
 const [fixtureFullCourseget, setFixtureFullCourseget] = useState(null);
 const [industFullCourseget, setIndustFullCourseget] = useState(null);
 const [digitalFullCourseget, setDigitalFullCourseget] = useState(null);
 const [toolDesignbarBatch, setToolDesignbarBatch] = useState(null);
 const [industrialBatch, setIndustrialBatch] = useState(null);
 const [digitalBatch, setDigitalBatch] = useState(null);
 const [isLoading, setIsLoading] = useState(false);
 const [error, setError] = useState(null);
 useEffect(() => {
   const fetchData = async () => {
     setIsLoading(true);
     try {
       const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/fdcourse/get');
       if (!response.ok) {
         throw new Error('Failed to fetch data');
       }
       const result = await response.json();
       setFixtureFullCourseget(result);
     } catch (error) {
       setError(error);
     }
     try {
      const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/fixturedesigncourse/latest"
      );
     
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const result = await response.json();
      setToolDesignbarBatch(result);
    } catch (error) {
      setError(error);
    }
     try {
      const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/iacourse/get');
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const result = await response.json();
      setIndustFullCourseget(result);
    } catch (error) {
      setError(error);
    }
    try {
      const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/industrialautomation/latest"
      );
     
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const result = await response.json();
      setIndustrialBatch(result);
    } catch (error) {
      setError(error);
    }
    try {
      const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/dfscourse/get');
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const result = await response.json();
      setDigitalFullCourseget(result);
    } catch (error) {
      setError(error);
    }
    try {
      const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/industrialautomation/latest"
      );
     
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const result = await response.json();
      setDigitalBatch(result);
    } catch (error) {
      setError(error);
    }




     setIsLoading(false);
   };
   




  

   fetchData();
 }, []);

//  if (isLoading) {
//    return <div>Loading...</div>;
//  }

//  if (error) {
//    return <div>Error: {error.message}</div>;
//  }

  return (
    <div>
      <div className="section-full bg-white ">
        <div className="container">
          <div className="section-head text-black text-center">
            <h2 className="text-uppercase" style={{ color: "#5B4B99" }}>
              TECHNOTALKS{" "}
            </h2>
            <div className="dlab-separator bg-primary"></div>
          </div>

        

          <div className="pricing-table">
            <div className="pricing-box">
              <h2>Fixture Design</h2>
              {fixtureFullCourseget && (
                <> 
                 <span className="price">{fixtureFullCourseget.price} /- {fixtureFullCourseget.courseDuration}</span>
             
                <ul>
                  <li>Duration :{fixtureFullCourseget.courseDuration} </li>
                  <li>Mode : {fixtureFullCourseget.mode}</li>
                  {toolDesignbarBatch && (
                    <li>Batch : {toolDesignbarBatch.batch} </li>
                    
                  )}
                  <li>Timings :{fixtureFullCourseget.timing} </li>
                </ul>
                
                </>
            
              )}
              <span className="pricing-table-divider"></span>
              <div className="pricingtable-footer">
                {" "}
                <Link to={"/contact-us"}>
                  {" "}
                  <button className="custom-btn btn-3">
                    <span>Enroll Now</span>
                  </button>
                </Link>
              </div>
            </div>
            <div className="pricing-box">
              <h2>Industrial Automation</h2>
              {industFullCourseget &&(  
              <> 
                 <span className="price">{industFullCourseget.price} /- {industFullCourseget.courseDuration}</span>
             
                <ul>
                  <li>Duration :{industFullCourseget.courseDuration} </li>
                  <li>Mode : {industFullCourseget.mode}</li>
                  {industrialBatch && (
                    <li>Batch : {industrialBatch.batch} </li>
                    
                  )}
                  <li>Timings :{industFullCourseget.timing} </li>
                </ul>
                
                </>
            
              )}
              <span className="pricing-table-divider"></span>
              <div className="pricingtable-footer">
                {" "}
                <Link to={"/contact-us"}>
                  {" "}
                  <button className="custom-btn btn-3">
                    <span>Enroll Now</span>
                  </button>
                </Link>
              </div>
            </div>
            <div className="pricing-box pricing-table-best">
              <h2>Digital Facility Solution</h2>
              {digitalFullCourseget &&(  
              <> 
                 <span className="price">{digitalFullCourseget.price} /- {digitalFullCourseget.courseDuration}</span>
             
                <ul>
                  <li>Duration :{digitalFullCourseget.courseDuration} </li>
                  <li>Mode : {digitalFullCourseget.mode}</li>
                  {digitalBatch && (
                    <li>Batch : {digitalBatch.batch} </li>
                    
                  )}
                  <li>Timings :{digitalFullCourseget.timing} </li>
                </ul>
                
                </>
            
              )}
              <span className="pricing-table-divider"></span>
              <div className="pricingtable-footer">
                {" "}
                <Link to={"/contact-us"}>
                  {" "}
                  <button className="custom-btn btn-3">
                    <span>Enroll Now</span>
                  </button>
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
