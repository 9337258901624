import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import logo from "../img/logo/logo.png";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WhatsAppButton from "./WhatsAppButton";
const footer = () => {
  return (
    <div>
      <footer className="footer-section">
        <div className="container">     
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo" style={{textAlign:"center"}}>
                    <Link to="/">
                      <img
                        src={logo}
                        className="img-fluid"
                        alt="logo"
                        style={{ height: "100px" }}
                      />
                    </Link>
                  </div>
                  <div className="footer-text" style={{ textAlign: 'justify'}}>
                    <p>
                    Welcome to INTERNATIONAL INSTITUTE OF AUTOMATE which ignites minds and empowers the future, it is a dynamic learning environment where innovation, expertise, and excellence converge to shape the leaders of tomorrow. 
                    </p>
                    <div className="dlab-topbar-left">
                <ul>
                  <li style={{color:"#fff"}}>
                    <i className="fa fa-envelope-o m-r5"></i>{" "}
                    <FontAwesomeIcon icon="fa-solid fa-envelope" />{" "}
                    Info@iiautomate.com
                  </li>
                  <li style={{color:"#fff"}}>
                    <i className="fa fa-phone m-r5"></i>
                    <FontAwesomeIcon icon="fa-solid fa-phone" /> +91 8096120173
                  </li>
                </ul>
              </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                   
                    <li>
                      <Link to="/courses">Courses</Link>
                    </li>
                     <li>
                      <Link to="/techno-talks">Techno-Talks</Link>
                    </li>
                    <li>
                      <Link to="/placements">Placements</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-conditions">Terms & Conditions</Link>
                    </li>

                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Get In Touch</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <i className="fas fa-phone">
                    <FontAwesomeIcon icon="fas fa-map-marker-alt" style={{float:"left"}} /><h5 style={{color:'#fff',paddingLeft:'20px'}}> Hyderabad</h5><p> #913, Manjeera Trinity Corporate, 
                                    9th floor, Office No.913, 
                                    KPHB,Hyderabad,Telangana, India - 500085</p>
                  </i>
                  <i className="fas fa-phone">
                    <FontAwesomeIcon icon="fas fa-map-marker-alt" style={{float:"left"}} /><h5 style={{color:'#fff',paddingLeft:'20px'}}> Chennai</h5><p>13, Customs Colony, OMR, Oggiyamduraipakkam, Tamilnadu-600097  </p>
                  </i>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Our Location</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <i className="fas fa-phone">
                    <FontAwesomeIcon icon="fas fa-map-marker-alt" style={{float:"left"}} /><h5 style={{color:'#fff',paddingLeft:'20px'}}> Bangalore</h5><p>1596, 20th Main Road, Agara Village, 1st Sector, HSR Layout, Bengaluru, Karnataka-560102.</p>
                  </i>
                  <i className="fas fa-phone">
                    <FontAwesomeIcon icon="fas fa-map-marker-alt" style={{float:"left"}} /><h5 style={{color:'#fff',paddingLeft:'20px'}}> Pune</h5><p>Suratwala Mark, 8th Floor, Office No. 810, Wakad road, Survey number- 27/1, 28/1, 28/5, 28/6, opposite KFC, Pune, Maharashtra - 411057</p>
                  </i>                    
                  </div>
                </div>
              
              </div>
            </div>
          </div>
         </div>
         <div className="container-fluid copyright-area" style={{backgroundColor:"#efefef"}}>
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 text-center text-lg-left">
                <div className="copyright-text">
                  <p style={{color:'rgb(82 78 161)', fontSize:'18px'}}>Copyright &copy; 2024, All Right Reserved{" "}
                    <Link to="/"  style={{color:'rgb(244 153 85)'}}>IIA</Link>
                  </p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </footer>

           {/* <button className="scroltop fa fa-angle-double-up">
        <FontAwesomeIcon icon="fas fa-angle-double-up" />
      </button> */}
      
      <WhatsAppButton/>
    </div>
  );
};

export default footer;
library.add(fab, fas, far);
