import React from 'react'
import FixtureBriefExp from './FixtureBriefExp';
import TechnoTalksCources from './TechnoTalksCources';
import ToolDesignBanner from './ToolDesignBanner';
import TechnoFixtureTabs from './TechnoFixtureTabs';
import TechnoFixturedesignBanner from './TechnoFixturedesignBanner';
const FixtureDesign = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };

  return (
    <div>
      <TechnoFixturedesignBanner />
       {/* <ToolDesignBanner/> */}
       <TechnoFixtureTabs/>
        {/* <FixtureBriefExp/> */}
        <TechnoTalksCources/>
    </div>
  )
}

export default FixtureDesign