import React from 'react'
import IteCorses from "./IteCorses";
import AllCourses from "./AllCourses";
import Partners from './Partners'
import BusinessPartners from './BusinessPartners';
const InformationTechnology = () => {
  return (
    <div> 
    <IteCorses></IteCorses> 
    <AllCourses></AllCourses>
    <BusinessPartners/>
     {/* <Partners></Partners> */}
     </div>
  )
}

export default InformationTechnology
