import React, { useState, useRef } from "react";
import { Form } from "react-bootstrap";
const EnrollPersonalInfo = ({ handleChange }) => {
  {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  const form = useRef();
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [uid, setuid] = useState("");
  const [alternate_phone, setalternate_phone] = useState("");
  const [gender, setgender] = useState("");
  const [dob, setdob] = useState("");
  const [course, setcourse] = useState("");
  const [address, setaddress] = useState("");
  const [amount, setamount] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      alert("please enter details below");
    } else if (email === "") {
      alert("please enter details below");
    } else if (phone === "") {
      alert("please enter details below");
    } else if (uid === "") {
      alert("please enter details below");
    } else if (alternate_phone === "") {
      alert("please enter details below");
    } else if (gender === "") {
      alert("please enter details below");
    } else if (dob === "") {
      alert("please enter details below");
    
    } else if (course === "") {
      alert("please enter details below");
    } else if (address === "") {
      alert("please enter details below");
    } else if (amount === "") {
      alert("please enter details below");
    } else {
      var options = {
        key: "rzp_test_rA2UalGsJwTy9v",
        key_secret: "RG7A51JvDSuFfhUzkGVbpAtn",

        amount: amount * 100,
        currency: "INR",

        captured: true,
        payment_capture: "1",
        name: "IIAutomation",

        handler: function (response) {
           alert(
            "Thanks for your intrest \n Your Payment has been  successfully completed"
          );
        },
        prefill: {
          name: name,
          email: email,
          contact: phone,
        },
        notes: {
          address: "IIAutomation",
        },
        theme: {
          color: "#3399cc",
        },
      };

      var pay = new window.Razorpay(options);
      pay.open();
    }
  };
  return (
    <div>
      <section>
        <div className="container-fluid">
          <div
            className="container  boderform"
            style={{
              backgroundColor: "#efefef",
              paddingBottom: "30px",
              borderRadius: "10px",
            }}
          >
            <h2
              style={{
                textAlign: "center",
                color: "#5B4B99",
                paddingBottom: "50px",
                paddingTop: "30px",
              }}
            >
              PERSONAL INFORMATION
            </h2>
            <form ref={form} onSubmit={(event) => {}} className="donate-form">
              <div className="row" style={{ paddingBottom: "25px" }}>
                <Form.Group
                  className="col-md-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Full Name*</Form.Label>
                  <Form.Control
                    type="email"
                    name="full_name"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group
                  className="col-md-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Gender*</Form.Label>
                  <Form.Select
                    aria-label="Default select example "
                    name="gender"
                    value={gender}
                    onChange={(e) => setgender(e.target.value)}
                    required
                  >
                    <option>Enter Your Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group
                  className="col-md-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>DOB*</Form.Label>
                  <Form.Control
                    type="date"
                    name="dob"
                    placeholder="date of birth"
                    value={dob}
                    onChange={(e) => setdob(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group
                  className="col-md-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>EMail-Id*</Form.Label>
                  <Form.Control
                    type="email"
                    name="email_id"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
              <div className="row" style={{ paddingBottom: "25px" }}>
                <Form.Group
                  className="col-md-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Contact No.*</Form.Label>
                  <Form.Control
                    type="number"
                    name="contact_no"
                    placeholder="Enter Your Contact No."
                    value={phone}
                    onChange={(e) => setphone(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group
                  className="col-md-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Emergency Contact No.*</Form.Label>
                  <Form.Control
                    type="number"
                    name="emergency_contact_no"
                    placeholder="Enter EC No."
                    value={alternate_phone}
                    onChange={(e) => setalternate_phone(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group
                  className="col-md-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Address*</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="Enter Your Address"
                    value={address}
                    onChange={(e) => setaddress(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group
                  className="col-md-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Unique ID No.*</Form.Label>
                  <Form.Control
                    type="text"
                    name="uid"
                    placeholder="Enter The UID No"
                    value={uid}
                    onChange={(e) => setuid(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
              <div className="row" style={{ paddingBottom: "25px" }}>
                
                <Form.Group
                  className="col-md-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Courses*</Form.Label>
                  <Form.Select
                    aria-label="Default select example "
                    name="gender"
                    value={course}
                    onChange={(e) => setcourse(e.target.value)}
                    required
                  >
                    <option>Choose your course</option>
                    <option value="student">Tools and Fixture design</option>
                    <option value="Trainer">Online Robotics</option>
                    <option value="Speaker">Offline Robotics</option>
                  </Form.Select>
                </Form.Group>
                
              </div>
              <button
                type="submit"
                className="custom-btn btn-3"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                <span>Submit</span>
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};
export default EnrollPersonalInfo;
