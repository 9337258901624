import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PlacementServices = () => {

  {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <div>
<section id="cards-container" >
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="text-center">
               
              <h2 className="text-uppercase" style={{color:'#5B4B99'}}>Placement <span style={{ color: " #F58634" }}>Services</span></h2>
					
               
               
              </div>       
              <div className="col-md-6 card-wrapper">
                <div className="card" style={{ height: "320px" }}>
                  <div>
                    <div className="card-top gradient-greens">
                      <div className="icon">
                      <FontAwesomeIcon icon="fa-solid fa-people-roof" flip />
                      </div>
                    </div>
                    <div className="card-content">
                      <h4 className="card-title" style={{ paddingTop: "40px" }}>
                      Placement Drive
                      </h4>
                    </div>
                  </div>
                  <div className="overlay zoom" style={{ height: "320px" }}>
                    <h4 style={{ color: "#695B9D" }}>Placement</h4>
                    <p
                      style={{
                        color: "#000",
                        fontSize: "14px",
                        textAlign: "justify",
                      }}
                    >
                    Need to be placed
                    </p>
                    <Link to={'/contact-us'}> <button className="custom-btn btn-3"><span>Apply Now</span></button></Link>
                  </div>
                </div>
              </div>                      
              <div className="col-md-6 card-wrapper">
                <div className="card" style={{ height: "320px" }}>
                  <div>
                    <div className="card-top gradient-greens">
                      <div className="icon">
                      {/* <FontAwesomeIcon icon="fa-solid fa-graduation-cap"  /> */}
                      <FontAwesomeIcon icon="fa-solid fa-people-carry-box" flip  />
                      </div>
                    </div>
                    <div className="card-content">
                      <h4 className="card-title" style={{ paddingTop: "40px" }}>
                       To Provide Placement 
                      </h4>
                    </div>
                  </div>
                  <div className="overlay zoom" style={{ height: "320px" }}>
                  <h4 style={{ color: "#695B9D" }}>Recruiters Place</h4>
                    {/* <h4 style={{ color: "#695B9D" }}>Registraton for Service</h4> */}
                    <p
                      style={{
                        color: "#000",
                        fontSize: "14px",
                        textAlign: "justify",
                      }}
                    >
                   Required Trained Candidates to be placed
                    </p>
                    <Link to={'/contact-us'}> <button className="custom-btn btn-3"><span>Apply Now</span></button></Link>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default PlacementServices