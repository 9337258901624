import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ChooseUs = () => {
  const cardsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target); // Stop observing once it's visible
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );

    cardsRef.current.forEach((card) => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      cardsRef.current.forEach((card) => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, []);

  const cards = [
    {
      icon: "fa-solid fa-graduation-cap",
      title: "Customize Learning",
      text: "We believe that every learner is unique, and so should their learning path...",
    },
    {
      icon: "fa-solid fa-book-open",
      title: "Course Recordings",
      text: "We understand that flexibility is key to effective learning...",
    },
    {
      icon: "fa-solid fa-users",
      title: "Mock Sessions",
      text: "We offer Mock Sessions as part of our hands-on learning approach...",
    },
    {
      icon: "fa-solid fa-child-reaching",
      title: "Personalised Coaching",
      text: "We believe in personalized coaching to help learners achieve their full potential...",
    },
    {
      icon: "fa-solid fa-bookmark",
      title: "Learning Experience",
      text: "We create a transformative learning experience designed to empower you...",
    },
    {
      icon: "fa-solid fa-user-tie",
      title: "Industry Experts",
      text: "We collaborate with industry experts who bring practical insights...",
    },
  ];

  return (
    <div className="container-fluid" style={{   }}>
      <div className="container">
        {/* <h1 style={{ paddingTop: "20px" }}>
          <span style={{ fontWeight: "700", color: "rgb(70 53 133)" }}>
            Why Choose <span style={{ color: "#F58634" }}>IIAUTOMATE?</span>
          </span>
        </h1> */}
        <h1 className="heading-names" style={{ color: "rgb(70 53 133)", fontSize: "40px", lineHeight: "1.3",padding: "20px" }}>
        WHY CHOOSE <span style={{ color: "#F58634" }}>IIAUTOMATE?</span>
                </h1>
        {/* First row */}
        <div className="row containerbd">
          {cards.slice(0, 3).map((card, index) => (
            <div
              key={index}
              className="col-md-4 anicard"
              ref={(el) => (cardsRef.current[index] = el)}
            >
              <div className="face face1">
                <div className="contented">
                  <FontAwesomeIcon
                    icon={card.icon}
                    style={{ fontSize: "40px", color: "#B197FC" }}
                  />
                  <h3>{card.title}</h3>
                </div>
              </div>
              <div className="face face2">
                <div className="contented">
                  <p style={{    color: '#c8c7c7'}}>{card.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Divider section */}
        {/* <div className="separator" style={{ margin: "50px 0", textAlign: "center" }}>
          <h2 style={{ color: "#F58634" }}>Discover More Features</h2>
          <p style={{ color: "#707070" }}>
            Our platform offers a wide variety of tools and resources designed to enhance your learning experience.
          </p>
        </div> */}

        {/* Second row */}
        <div className="row containerbd"style={{paddingTop:"20px",paddingBottom:"20px"}}>
          {cards.slice(3, 6).map((card, index) => (
            <div
              key={index + 3}
              className="col-md-4 anicard"
              ref={(el) => (cardsRef.current[index + 3] = el)}
            >
              <div className="face face1">
                <div className="contented">
                  <FontAwesomeIcon
                    icon={card.icon}
                    style={{ fontSize: "40px", color: "#B197FC" }}
                  />
                  <h3>{card.title}</h3>
                </div>
              </div>
              <div className="face face2">
                <div className="contented">
                  <p style={{    color: '#c8c7c7'}}>{card.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChooseUs;