import React from 'react'
import PythonCorses from "./PythonCorses";
import AllCourses from "./AllCourses";
import Partners from './Partners'
const Python = () => {
  return (
    <div> 
    <PythonCorses></PythonCorses> 
    <AllCourses></AllCourses>
     <Partners></Partners>
     </div>
  )
}

export default Python