import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import tfd from "../img/our-work/tools.png";
import or from "../img/our-work/online.jpg";
import offr from "../img/our-work/offline.jpg";
import { Link } from "react-router-dom";
const Courses = () => {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      800: {
        items: 2,
      },
      1000: {
        items: 2,
      },
      1300: {
        items: 3,
      },
    },
  };

  return (
    <div>
      <div
        className="section-full bg-gray content-inner-1 what-we-do overlay-black-dark bg-img-fix"
        id="services"
      >
        <div className="container">
          <div className="section-head text-center text-white">
            <h2 className="text-uppercase" style={{ color: " rgb(70 53 133)",}}>Our <span style={{ color: " #F58634" }}>Courses</span></h2>
            <div className="dlab-separator bg-primary"></div>
          </div>
          <div className="section-content">
            <div className="course mfp-gallery gallery owl-none owl-loaded owl-theme owl-carousel owl-dots-white-full">
              <OwlCarousel
                className="owl-theme owl-stage owlCarousel"
                loop
                margin={10}
                nav
                {...options}
              >
                <Link to="/tool-&-fixture-design" style={{textAlign:"center",alignItems:"center"}}>
                  <div
                    className="card_2"
                    style={{ backgroundImage: `url(${tfd})` }}
                  >
                    <div className="card2_body">
                      <h5 className="card2_title">TOOL AND FIXTURE DESIGN</h5>
                      <p className="desc">
                        Tool and fixture design is creating special helpers for
                        making things. Tools are like special equipment designed
                        to cut, shape, or manipulate materials, while fixtures
                        are devices that hold things in place while you work on
                        them.
                      </p>
                      <Link to="/tool-&-fixture-design" className="read_more">
                        {" "}
                        Read More
                      </Link>
                    </div>
                  </div>
                </Link>
                <Link to="/online-robotics" style={{textAlign:"center",alignItems:"center"}}>
                  <div className="card_2" style={{ backgroundImage: `url(${or})` }}>
                    <div className="card2_body">
                      <h1 className="card2_title">ONLINE ROBOTICS</h1>
                      <p className="desc">
                        Online robotics is a virtual playground where you can
                        build, test, and experiment with robots using just a
                        computer and internet connection. It's a digital
                        environment that mimics real-life scenarios, letting you
                        design and see how robots would behave without actually
                        building them physically.
                      </p>
                      <Link to="/online-robotics" className="read_more">
                        {" "}
                        Read More
                      </Link>
                    </div>
                  </div>
                </Link>
                <Link to="/offline-robotics" style={{textAlign:"center",alignItems:"center"}}>
                  <div
                    className="card_2"
                    style={{ backgroundImage: `url(${offr})` }}
                  >
                    <div className="card2_body">
                      <h1 className="card2_title"> OFFLINE ROBOTICS</h1>
                       <p className="desc" style={{ fontSize: "18px" }}>
                        Offline robotic involves creating and testing robot
                        behaviors and movements on a computer without needing an
                        internet connection. It's like having a pretend robot in
                        a digital world right on your own computer, where you
                        can design, program, and see how it works without the
                        need for real physical robots
                      </p>
                      <Link to="/offline-robotics" className="read_more">
                        {" "}
                        Read More
                      </Link>
                    </div>
                  </div>
                </Link>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
