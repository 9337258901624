import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Component/Home";
import NavBar from "./Component/NavBar";
import AboutUs from "./Component/AboutUs";
import Footer from "./Component/Footer";
import TechCourses from "./Component/TechCourses";
import ToolAndFixtureDesign from "./Component/ToolAndFixtureDesign";
import OnlineRobotics from "./Component/OnlineRobotics";
import OfflineRobotics from "./Component/OfflineRobotics";
import TechnoTalks from "./Component/TechnoTalks";
import FixtureDesign from "./Component/FixtureDesign";
import IndustrialAutomation from "./Component/IndustrialAutomation";
import DigitalSolution from "./Component/DigitalSolution";
import ContactUs from "./Component/ContactUs";
import Placements from "./Component/Placements";
import MechanicalEngineering from "./Component/MechanicalEngineering";
import Enroll from "./Component/Enroll";
import EnrollForm from "./Component/EnrollForm";
import EnrollServices from "./Component/EnrollServices";
import EnrollPersonalInfo from "./Component/EnrollPersonalInfo";
import CoursesPricing from "./Component/CoursesPricing";
import Pricing from "./Component/Pricing";
import TechPersonalInformationForm from "./Component/TechPersonalInformationForm";
import EnrollProvideServices from "./Component/EnrollProvideServices";
import TechnotalksTraineeEnroll from "./Component/TechnotalksTraineeEnroll";
import CoursePersonalinfo from "./Component/CoursePersonalinfo";
import TechPersonalInfo from "./Component/TechPersonalInfo";
import PlacementServices from "./Component/PlacementServices";
import PlacementForm from "./Component/PlacementForm";
import RecruiterForm from "./Component/RecruiterForm";
import BasicInformationForm from "./Component/BasicInformationForm";
import PreviewForm from "./Component/PreviewForm";
import PersonalInfoForm from "./Component/PersonalInfoForm";
import TechnotalksPersonalInformation from "./Component/TechnotalksPersonalInformation";
import ToolAndFixtureCourseForm from "./Component/ToolAndFixtureCourseForm";
import OfflineRoboticsCourseForm from "./Component/OfflineRoboticsCourseForm";
import OnlineRoboticsCourseForm from "./Component/OnlineRoboticsCourseForm";
import FixtureWebinorForm from "./Component/FixtureWebinorForm";
import IndustrialAutomationWebinarForm from "./Component/IndustrialAutomationWebinarForm";
import DigitalFacilitySolWebinarForm from "./Component/DigitalFacilitySolWebinarForm";
import DigitalFacilitySolutionFullCourseForm from "./Component/DigitalFacilitySolutionFullCourseForm";
import FixtureDesignFullCourseForm from "./Component/FixtureDesignFullCourseForm";
import ToolsFixturePreviewForm from "./Component/ToolsFixturePreviewForm";
import OnlineRoboticsPrivewForm from "./Component/OnlineRoboticsPrivewForm";
import OfflinePreviewForm from "./Component/OfflinePreviewForm";
import FixtureWebinorPreviewForm from "./Component/FixtureWebinorPreviewForm";
import IndustrialWebinorPreviewForm from "./Component/IndustrialWebinorPreviewForm";
import DigitalWebinorPreview from "./Component/DigitalWebinorPreview";
import IndustrialFullCoursePreviewForm from "./Component/IndustrialFullCoursePreviewForm";
import DigitalFacilityFullCoursePreviewForm from "./Component/DigitalFacilityFullCoursePreviewForm";
import CollegeSeminor from "./Component/CollegeSeminor";
import HyderabadBranches from "./Component/HyderabadBranches";
import InformationTechnology from "./Component/InformationTechnology";
// import WhatsAppButton from './WhatsAppButton';
import Mobileapplication from "./Component/Mobileapplication";
import WebApplication from "./Component/WebApplication";
import Erpsolutions from "./Component/Erpsolutions";
import Java from "./Component/Java";
import Python from "./Component/Python";
import ReactJs from "./Component/ReactJs";

import Flutter from "./Component/Flutter";


import JavaForm from "./Component/JavaForm";
import ReactJsForm from "./Component/ReactJsForm";
import FlutterCoursesForm from "./Component/FlutterCoursesForm";


import JavaPrivewForm from "./Component/JavaPrivewForm";
import ReactjsPreviewForm from "./Component/ReactjsPreviewForm";
import FlutterPreviewForm from "./Component/FlutterPreviewForm";

import TechnoTalksJava from "./Component/TechnoTalksJava";
import TechnoTalksReactjs from "./Component/TechnoTalksReactjs";
import TechnotalksFlutter from "./Component/TechnotalksFlutter";
import PrivacyPolicy from "./Component/PrivacyPolicy";
import TermsAndCondition from "./Component/TermsAndCondition";
import PlacementsToolandFixture from "./Component/PlacementsToolandFixture";
import PlacementsOnlineRobotics from "./Component/PlacementsOnlineRobotics";
import PlacementsOfflineRobotics from "./Component/PlacementsOfflineRobotics";
import PlacementsJava from "./Component/PlacementsJava";
import PlacementsReactjs from "./Component/PlacementsReactjs";
import PlacementsFlutter from "./Component/PlacementsFlutter";
import LoginPage from "./Component/LoginPage";
import ForgetPasswordpage from "./Component/ForgetPasswordpage";
import CloudComputing from "./Component/CloudComputing";
import DataAnalystic from "./Component/DataAnalystic";
import AiandMlsolutions from "./Component/AiandMlsolutions";
import AutomationSolutions from "./Component/AutomationSolutions";
import EcommerceandDigitalmarketing from "./Component/EcommerceandDigitalmarketing";

import PlacementsCloudComputing from "./Component/PlacementsCloudComputing";


import PlacementsDataAnalytics from "./Component/PlacementsDataAnalytics";

import PlacementsAlandMl from "./Component/PlacementsAlandMl";


import PlacementsAutomationSolutions from "./Component/PlacementsAutomationSolutions";


import PlacementsEcommers from "./Component/PlacementsEcommers";


import TechnotalksCloud from "./Component/TechnotalksCloud";
import TechnoDataanalystics from "./Component/TechnoDataanalystics";
import TechnoALandMl from "./Component/TechnoALandMl";
import TechnoEcommerce from "./Component/TechnoEcommerce";



const App = () => {
  return (
    <div>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/courses" element={<TechCourses />}></Route>
          <Route
            path="/mechanical-engineering"
            element={<MechanicalEngineering />}
          ></Route>
          <Route path="/techno-talks" element={<TechnoTalks />}></Route>
          <Route path="/placements" element={<Placements />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/enroll" element={<Enroll />}></Route>
          <Route
            path="/tool-&-fixture-design"
            element={<ToolAndFixtureDesign />}
          ></Route>
          <Route path="/online-robotics" element={<OnlineRobotics />}></Route>
          <Route path="/offline-robotics" element={<OfflineRobotics />}></Route>
          <Route path="/fixture-design" element={<FixtureDesign />}></Route>
          <Route
            path="/industrial-automation"
            element={<IndustrialAutomation />}
          ></Route>
          <Route path="/digital-solution" element={<DigitalSolution />}></Route>
          <Route path="/Trainer-enroll-form" element={<PersonalInfoForm />}></Route>
          <Route path="/enroll-services" element={<EnrollServices />}></Route>
          <Route
            path="/presonal-information"
            element={<EnrollPersonalInfo />}
          ></Route>
          <Route path="/course-price" element={<CoursesPricing />}></Route>
          <Route path="/tech-price" element={<Pricing />}></Route>
          <Route
            path="/tech-form"
            element={<TechPersonalInformationForm />}
          ></Route>
          <Route
            path="/provide-services"
            element={<EnrollProvideServices />}
          ></Route>

          <Route
            path="/trainer-technotalk-enroll-form"
            element={<TechnotalksPersonalInformation />}
          ></Route>

          {/* 
          <Route
            path="/student-personal-information"
            element={<CoursePersonalinfo />}
          ></Route> */}


          <Route
            path="/online-robotics-course-form"
            element={<OnlineRoboticsCourseForm />}
          ></Route>

          <Route
            path="/tool-&-fixture-full-course-form"
            element={<ToolAndFixtureCourseForm />}
          ></Route>
          <Route
            path="/offline-robotics-course-form"
            element={<OfflineRoboticsCourseForm />}
          ></Route>

          <Route
            path="/technotalk-student-information"
            element={<TechPersonalInfo />}
          >
          </Route>
          <Route
            path="/fixture-design-webinar-form"
            element={<FixtureWebinorForm />}
          ></Route>
          <Route
            path="/industrial-automation-webinar-form"
            element={<IndustrialAutomationWebinarForm />}
          ></Route>
          <Route
            path="/digital-facility-solution-webinar-form"
            element={<DigitalFacilitySolWebinarForm />}
          ></Route>
          <Route
            path="/placement-services"
            element={<PlacementServices />}
          ></Route>
          <Route
            path="/placement-form"
            element={<PlacementForm />}
          ></Route>
          <Route
            path="/recruiter-form"
            element={<RecruiterForm />}
          ></Route>
          <Route
            path="/Technotalk-industrial-automation-full-course-regristration-form"
            element={<BasicInformationForm />}
          ></Route>
          <Route
            path="/Technotalk-fixture-design-course-regristration-form"
            element={<FixtureDesignFullCourseForm />}
          ></Route>

          <Route
            path="/Technotalk-digital-facility-solution-full-course-regristration-form"
            element={<DigitalFacilitySolutionFullCourseForm />}
          ></Route>


          <Route
            path="/preview-form"
            element={<PreviewForm />}
          ></Route>
          <Route
            path="/fixture-preview-form"
            element={<ToolsFixturePreviewForm />}
          ></Route>
          <Route
            path="/online-preview-form"
            element={<OnlineRoboticsPrivewForm />}
          ></Route>
          <Route
            path="/offline-preview-form"
            element={<OfflinePreviewForm />}
          ></Route>
          <Route
            path="/fixture-webinor-preview-form"
            element={<FixtureWebinorPreviewForm />}
          ></Route>
          <Route
            path="/industrial-webinor-preview-form"
            element={<IndustrialWebinorPreviewForm />}
          ></Route>
          <Route
            path="/digital-webinor-preview-form"
            element={<DigitalWebinorPreview />}
          ></Route>
          <Route
            path="/industrial-fullcourse-preview-form"
            element={<IndustrialFullCoursePreviewForm />}
          ></Route>
          <Route
            path="/digital-fullcourse-preview-form"
            element={<DigitalFacilityFullCoursePreviewForm />}
          ></Route>
          <Route
            path="/college-seminor"
            element={<CollegeSeminor />}
          ></Route>
          <Route
            path="/hyderabad-branches"
            element={<HyderabadBranches />}
          ></Route>

          <Route
            path="/ite-engineering"
            element={<InformationTechnology />}
          ></Route>
          <Route path="/mobile-application" element={<Mobileapplication />}></Route>
          <Route path="/web-application" element={<WebApplication />}></Route>
          <Route path="/erp-solutions" element={<Erpsolutions />}></Route>
          <Route path="/Java-corses" element={<Java />}></Route>
          <Route path="/Python-corses" element={<Python />}></Route>
          <Route path="/ReactJs-corses" element={< ReactJs />}></Route>
          <Route path="/flutter-corses" element={< Flutter />}></Route>



          <Route path="/java-corses-form" element={< JavaForm />}></Route>

          <Route path="/reactjs-corses-form" element={<  ReactJsForm />}></Route>
          <Route path="/flutter-corses-form" element={<  FlutterCoursesForm />}></Route>


          <Route path="/java-privew-form" element={<  JavaPrivewForm />}></Route>
          <Route path="/reactjs-privew-form" element={<  ReactjsPreviewForm />}></Route>
          <Route path="/flutter-privew-form" element={<  FlutterPreviewForm />}></Route>


          <Route path="/techno-talks-java" element={<  TechnoTalksJava />}></Route>
          <Route path="/techno-talks-reactjs" element={<  TechnoTalksReactjs />}></Route>
          <Route path="/techno-talks-flutter" element={<  TechnotalksFlutter />}></Route>
          <Route path="/privacy-policy" element={<   PrivacyPolicy />}></Route>
          <Route path="/terms-conditions" element={<   TermsAndCondition />}></Route>
          <Route path="/placements-tool-fixture" element={<   PlacementsToolandFixture />}></Route>
          <Route path="/placements-online-robotics" element={<   PlacementsOnlineRobotics />}></Route>
          <Route path="/placements-offline-robotics" element={<   PlacementsOfflineRobotics />}></Route>

          <Route path="/placements-java" element={<   PlacementsJava />}></Route>
          <Route path="/placements-reactjs" element={<   PlacementsReactjs />}></Route>
          <Route path="/placements-flutter" element={<   PlacementsFlutter />}></Route>
          <Route path="/user-login" element={<   LoginPage />}></Route>
          <Route path="/forgot-password" element={<   ForgetPasswordpage />}></Route>

          <Route path="/cloud-computing" element={<   CloudComputing />}></Route>

          <Route path="/data-analystic" element={<   DataAnalystic />}></Route>
          <Route path="/ai-ml-solutions" element={<    AiandMlsolutions />}></Route>
          <Route path="/automation-solutions" element={<    AutomationSolutions />}></Route>
          <Route path="/ecommerce-digital-marketing" element={<    EcommerceandDigitalmarketing />}></Route>

          <Route path="/placements-cloud-computing" element={<    PlacementsCloudComputing />}></Route>

          <Route path="/placements-data-analystics" element={<PlacementsDataAnalytics />}></Route>
          <Route path="/placements-alandml" element={<PlacementsAlandMl />}></Route>
          <Route path="/placements-automationsolutions" element={<PlacementsAutomationSolutions />}></Route>

          <Route path="/placements-ecommers" element={<PlacementsEcommers />}></Route>

          <Route path="/techno-cloud" element={<TechnotalksCloud />}></Route>

          <Route path="/techno-dataanalystics" element={<TechnoDataanalystics />}></Route>
          <Route path="/techno-aiml" element={< TechnoALandMl />}></Route>
          <Route path="/techno-ecommrce" element={<  TechnoEcommerce />}></Route>


          {/* <Route path="/WhatsAppButton" element={<WhatsAppButton />}>
          </Route> */}

        </Routes>

        <Footer></Footer>
        {/* <WhatsAppButton/> */}
      </Router>
    </div>
  );
};

export default App;
