import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

const IndustrialAutomationWebinarForm = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  // api integration
  const [industrialauto, setIndustrialauto] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://iiaserver.iiautomate.com/IIA-Website-1.0.0/ttia/get"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setIndustrialauto(result);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  //  if (isLoading) {
  //    return <div>Loading...</div>;
  //  }

  //  if (error) {
  //    return <div>Error: {error.message}</div>;
  //  }
  const [formData, setFormData] = useState({
    fullName: "",
    gender: "",
    dateOfBirth: "",
    emailId: "",
    contactNumber: "",

    course: "",
    // Add more fields as needed
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (industrialauto.price === "") {
      alert("please enter amount below");
    } else {
      var options = {
        key: "rzp_test_rA2UalGsJwTy9v",
        key_secret: "RG7A51JvDSuFfhUzkGVbpAtn",
        amount: industrialauto.price * 100,
        currency: "INR",
        captured: true,
        payment_capture: true,
        name: "International Institute Of Automate",
        handler: function (response) {
          alert(
            "Thanks for your intrest \n Your Payment has been successfully completed"
          );
          window.location.href = `/industrial-webinor-preview-form`;
        },
        prefill: {
          name: formData.fullName,
          email: formData.emailId,
          contact: formData.contactNumber,
        },
        notes: {
          address: "International Institute Of Automate",
        },
        theme: {
          color: "#3399cc",
        },
      };

      var pay = new window.Razorpay(options);
      pay.open();
    }

    try {
      const response = await fetch(
        "https://iiaserver.iiautomate.com/IIA-Website-1.0.0/industrialautomationer/registration",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const responseData = await response.json();
      localStorage.setItem('responseData',responseData.id);
      // Handle response as needed
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <section>
        <div className="container-fluid">
          <div
            className="container  boderform"
            style={{
              backgroundColor: "#efefef",
              paddingBottom: "30px",
              borderRadius: "10px",
            }}
          >
            <h2
              style={{
                textAlign: "center",
                color: "#5B4B99",
                paddingBottom: "50px",
                paddingTop: "30px",
              }}
            >
              Industrial Automation Webinar Form
            </h2>
            <form onSubmit={handleSubmit} className="donate-form">
              <div className="row" style={{ paddingBottom: "25px" }}>
                <Form.Group
                  className="col-md-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Full Name*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group
                  className="col-md-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Gender*</Form.Label>
                  <Form.Select
                    aria-label="Default select example "
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    required
                  >
                    <option>Enter Your Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group
                  className="col-md-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>DOB*</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="date of birth"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group
                  className="col-md-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>EMail-Id*</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Your Email"
                    name="emailId"
                    value={formData.emailId}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </div>
              <div className="row" style={{ paddingBottom: "25px" }}>
                <Form.Group
                  className="col-md-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Contact No.*</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Your Contact No."
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group
                  className="col-md-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Courses*</Form.Label>
                  <Form.Select
                    aria-label="Default select example "
                    name="course"
                    value={formData.course}
                    onChange={handleChange}
                    required
                  >
                    <option>Choose your course</option>
                    <option disabled>Fixture design</option>
                    <option value="Industrial_Automation">
                      Industrial Automation
                    </option>
                    <option disabled>Digital Facility Solution</option>
                  </Form.Select>
                </Form.Group>
                {industrialauto && (
                  <>
                    <Form.Group
                      className="col-md-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Webinar Name*</Form.Label>
                      <Form.Control
                        // type="number"

                        placeholder={industrialauto.webinarName}
                        disabled
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      className="col-md-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Course Duration*</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder={industrialauto.courseDuration}
                        disabled
                        required
                      />
                    </Form.Group>

                    <Form.Group
                      className="col-md-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Mode*</Form.Label>
                      <Form.Control
                        // type="number"

                        placeholder={industrialauto.mode}
                        disabled
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      className="col-md-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Time*</Form.Label>
                      <Form.Control
                        // type="number"

                        placeholder={industrialauto.timing}
                        disabled
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      className="col-md-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Price*</Form.Label>
                      <Form.Control
                        // type="number"

                        placeholder={industrialauto.price}
                        disabled
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      className="col-md-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Date*</Form.Label>
                      <Form.Control
                        // type="number"

                        placeholder={industrialauto.date}
                        disabled
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      className="col-md-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Speaker Name*</Form.Label>
                      <Form.Control
                        // type="number"

                        placeholder={industrialauto.speakerName}
                        disabled
                        required
                      />
                    </Form.Group>
                  </>
                )}
              </div>

              <div style={{ textAlign: "center" }}>
                <button type="submit" className="custom-btn btn-3">
                  <span>Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default IndustrialAutomationWebinarForm;
