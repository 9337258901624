import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import logo1 from "../img/logo/logo1.jpg";
import html2canvas from "html2canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PreviewForm = () => {
  // api integration
  const [toolbarfixture, setToolbarfixture] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
   
      setIsLoading(true);
      try {
        let id=localStorage.getItem('responseData');
        if (!id) {
          throw new Error('No ID found in localStorage');
        }
        const response = await fetch(
          `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/fixturedesigncourse/registration/${id}`,
         
          // Replace ":id" with the actual ID parameter you want to fetch
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        // alert(result.fullName)
        setToolbarfixture(result);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleDownloadPDF = () => {
    const input = document.getElementById("table-container");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("IIA.pdf");
    });
  };
  return (
    <div>
      <div className="container-fluid " id="table-container" style={{paddingTop:'60px'}}>
        <div className="container">
          <div className="row" style={{ fontSize:"22px"}}>
            <div>
              <div style={{ textAlign: "center" }}>
                <img src={logo1} alt="" style={{ width: "400px" , height:"70px"}} />

                <p style={{ textAlign: "right" }}>
                  <i className="fa fa-phone m-r5"></i>
                  <FontAwesomeIcon icon="fa-solid fa-phone" /> +91 8096120173
                </p>
                <p style={{ textAlign: "right" }}>
                  {" "}
                  <i className="fa fa-envelope-o m-r5"></i>{" "}
                  <FontAwesomeIcon icon="fa-solid fa-envelope" />{" "}
                  Info@iiautomate.com
                </p>
              </div>
            
            </div>
            <hr></hr>
            <h2 style={{ textAlign: "center", padding: "10px",paddingTop:'40px',paddingBottom:"40PX" }}>
              INTERNATIONAL INSTITUTE OF AUTOMATE
            </h2>
            <div >
            {toolbarfixture && (   
              <table method="Get" style={{paddingTop:'15px',paddingBottom:"15PX"}}>
                  
       
                  <tr>
                    <td>Full Name</td>
                    <td>{toolbarfixture[0].fullName ??  ""}</td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td>{toolbarfixture[0].gender ??  ""}</td>
                  </tr>
                  <tr>
                    <td>Date Of Birth</td>
                    <td>{toolbarfixture[0].dateOfBirth  ??  ""}</td>
                  </tr>
                  
                  <tr>
                    <td>Email Id</td>
                    <td>{toolbarfixture[0].emailId ??  ""}</td>
                  </tr>
                  <tr>
                    <td>Contact Number</td>
                    <td>{toolbarfixture[0].contactNumber ??  ""}</td>
                  </tr>
                  <tr>
                    <td>Course</td>
                    <td>{toolbarfixture[0].course ??  ""}</td>
                  </tr>
    
    
                  <tr>
                    <td>Batch</td>
                    <td>{toolbarfixture[0].batch ??  ""}</td>
                  </tr>
                  <tr>
                    <td>Course Duration</td>
                    <td>{toolbarfixture[0].courseDuration ??  ""}</td>
                  </tr>
                  <tr>
                    <td>Mode</td>
                    <td>{toolbarfixture[0].mode ??  ""}</td>
                  </tr><tr>
                    <td>Timing</td>
                    <td>{toolbarfixture[0].timing ??  ""}</td>
                  </tr><tr>
                    <td>Payment Mode</td>
                    <td>Online</td>
                  </tr>
                  <tr>
                    <td>Price</td>
                    <td>{toolbarfixture[0].price ??  ""}</td>
                  </tr>
              
       
           </table>
             )}
              <br></br>
              <hr></hr>
              <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Get In Touch</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <i className="fas fa-phone">
                    <FontAwesomeIcon icon="fas fa-map-marker-alt" style={{float:"left"}} /><h5 style={{color:'#000',paddingLeft:'20px'}}> Hyderabad</h5><p>Plot No. 12-7-20/64/2/8, Opp. Rspot Center,
                     IDL Lake Road, Near Balanagar Metro, Kukatpally, Hyderabad, Telangana-500072.</p>
                  </i>
                  <i className="fas fa-phone">
                    <FontAwesomeIcon icon="fas fa-map-marker-alt" style={{float:"left"}} /><h5 style={{color:'#000',paddingLeft:'20px'}}> Chennai</h5><p>13, Customs Colony, OMR, Oggiyamduraipakkam, Tamilnadu-600097  </p>
                  </i>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <br></br>
                    <br></br>   <br></br>
                    {/* <h3></h3> */}
                  </div>
                  <div className="footer-text mb-25">
                    <i className="fas fa-phone">
                    <FontAwesomeIcon icon="fas fa-map-marker-alt" style={{float:"left"}} /><h5 style={{color:'#000',paddingLeft:'20px'}}> Bangalore</h5><p>1596, 20th Main Road, Agara Village, 1st Sector, HSR Layout, Bengaluru, Karnataka-560102.</p>
                  </i>
                  <i className="fas fa-phone">
                    <FontAwesomeIcon icon="fas fa-map-marker-alt" style={{float:"left"}} /><h5 style={{color:'#000',paddingLeft:'20px'}}> Pune</h5><p>Suratwala Mark, 8th Floor, Office No. 810, Wakad road, Survey number- 27/1, 28/1, 28/5, 28/6, opposite KFC, Pune, Maharashtra - 411057</p>
                  </i>                    
                  </div>
                </div>
              </div>
              </div>
                  <hr></hr>
            </div>
            <div style={{ textAlign: "center", padding: "10px" }}>
              <button onClick={handleDownloadPDF}>Download as PDF</button>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  );
};

export default PreviewForm;
