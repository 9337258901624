import React, { useState } from "react";
import DownloadBrocherpopup from "./DownloadBrocherpopup"; // Modal
import ButtonPage from './ButtonPage'; // Button component
import { Link } from "react-router-dom";

import Carousel from "react-bootstrap/Carousel";
// import onlinecourse from '../img/Section-banners/onlinecourses.jpg'
import onlinecourse from '../img/banners/online-robotics.jpg'
const OnlineRobiticsBanner = () => {

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  return (
    <section  >
          <Carousel className="res-nav-mob no-indicators" interval={300000} controls={false}>
          <img className="d-block w-100" src={onlinecourse} alt="Tool & Fixture Design"   style={{ height: "650px" }} />
          <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
          <div className="row">
              {/* Column 1 */}
              <div className="col-lg-4 dtd" style={{}}>
                <div className="" style={{ width: "110%" }}>

                  <h2 className="banner-content" style={{ fontSize: "32px", color: "#000", fontFamily: "Raleway!important", fontWeight: "600",  marginTop:"-50px"}}     >
                    India's First Indigenous Industrial robotics Training center
                  </h2>
                  <h2 className="banner-content" style={{ fontSize: "35px", color: "#524DA1", fontFamily: "Raleway!important", fontWeight: "bold", }}     >

ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>

</h2>
                  {/* <h2   style={{  fontSize: "40px", color: "#524DA1",  fontFamily: "roboto",  fontWeight: "800",    }}     >
          
            ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>
            </h2> */}
                  <p className="banner-contentul" style={{ fontSize: "17px", color: "#000", textAlign: "justify", textIndent: "50px", fontWeight: "400", fontWeight: "bold" }}  >
                    1 Industrial Oriented Training(IOT)

                  </p>
                  <p className="banner-contentul" style={{ fontSize: "17px", color: "#000", textAlign: "justify", textIndent: "50px", fontWeight: "400", fontWeight: "bold" }} >
                    2. Job oriented skill enhancement (JOSE)
                  </p>
                  <p className="banner-contentul" style={{ fontSize: "17px", color: "#000", textAlign: "justify", textIndent: "50px", fontWeight: "400", fontWeight: "bold" }}  >
                    3. 100% Placement Support
                  </p>
                  <p className="banner-contentul" style={{ fontSize: "25px", color: "#000", textAlign: "justify", fontWeight: "400", fontWeight: "bold" }}   >  Delmia Simulation Software Free Training

                  </p>
                  <div className="view-btn">
                {/* Link to view more */}
                {/* <Link to="/online-robotics" className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "10px 20px", float: "right",  }}>
                  View More
                </Link> */}
              </div>
                </div>
              </div>

              <div className="col-lg-8 dtdt ">
                <h2 className="banner-content" style={{ fontSize: "32px", color: "rgb(82 77 161)", fontFamily: "Raleway!important", fontWeight: "600", marginTop:"-54px", }}    >
                  “A course that can Automate your Career "
                </h2>
               
              </div>
            </div>
            <div style={{}}>
              <div > {/* ButtonPage to trigger the modal */}
               
                <ButtonPage handleShow={handleShow} />

                {/* Modal Component */}
                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} /></div>

              
            </div>
          </Carousel.Caption>
          </Carousel>
        </section>
  )
}

export default OnlineRobiticsBanner