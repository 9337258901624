import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import logo1 from "../img/logo/logo1.jpg";
import html2canvas from "html2canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ToolsFixturePreviewForm = () => {
     // api integration
  const [toolbarfixture, setToolbarfixture] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
   
      setIsLoading(true);
      try {
        let id=localStorage.getItem('responseData');
        console.log(toolbarfixture);
        if (!id) {
          throw new Error('No ID found in localStorage');
        }
        const response = await fetch(
          `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/preview/${id}`,
         
          // Replace ":id" with the actual ID parameter you  various procedures having the various solutions and all the corrupted things we are using mainly 
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        // alert(result.fullName)
        setToolbarfixture(result);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleDownloadPDF = () => {
    const input = document.getElementById("table-container");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("IIA.pdf");
    });
  };
  return (
    <div>
      <div className="container-fluid " id="table-container" style={{paddingTop:'60px'}}>
        <div className="container">
          <div className="row" style={{ fontSize:"22px"}}>
            <div>
              <div style={{ textAlign: "center" }}>
                <img src={logo1} alt="" style={{ width: "618px" , height:"70px"}} />

                {/* <h2 style={{ textAlign: "center", padding: "10px",paddingTop:'40px',paddingBottom:"40PX" }}>
              INTERNATIONAL INSTITUTE OF AUTOMATE
            </h2> */}
              </div>
            
            </div>
            {/* <hr></hr> */}
            
            <div >
            {toolbarfixture && (   
              <table method="Get" style={{paddingTop:'15px',paddingBottom:"15PX"}}>
                  
       
                  <tr>
                    <td>Full Name</td>
                    <td>{toolbarfixture[0].fullName ??  ""}</td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td>{toolbarfixture[0].gender ??  ""}</td>
                  </tr>
                  <tr>
                    <td>Date Of Birth</td>
                    <td>
    {toolbarfixture[0].dateOfBirth ? 
      new Date(toolbarfixture[0].dateOfBirth).toLocaleDateString('en-GB') 
      : ""
    }
  </td>
                  </tr>
                  
                  <tr>
                    <td>Email Id</td>
                    <td>{toolbarfixture[0].emailId ??  ""}</td>
                  </tr>
                  <tr>
                    <td>Contact Number</td>
                    <td>{toolbarfixture[0].contactNumber ??  ""}</td>
                  </tr>
                  <tr>
                    <td>Counsellor Name</td>
                    <td>{toolbarfixture[0].councillorName ??  ""}</td>
                  </tr>
                  <tr>
                    <td>Course</td>
                    <td>{toolbarfixture[0].course ??  ""}</td>
                  </tr>
    
    
                  <tr>
                    <td>Batch</td>
                    <td>{toolbarfixture[0].batch ??  ""}</td>
                  </tr>
                  <tr>
  <td>Course Duration</td>
  <td>{toolbarfixture[0].courseDuration?.replace(/_/g, ' ') ?? ""}</td>
</tr>

                  <tr>
                    <td>Class Mode</td>
                    <td>{toolbarfixture[0].mode ??  ""}</td>
                  </tr><tr>
                    <td>Timing</td>
                    <td>{toolbarfixture[0].timings ??  ""}</td>
                  </tr>
                  {/* <tr>
                    <td>Payment Mode</td>
                    <td>{toolbarfixture[0].mode ??  ""}</td>
                  </tr> */}
                  <tr>
                    <td>Course Price</td>
                    <td>{toolbarfixture[0].coursePrice ??  ""}</td>
                  </tr>
                  <tr>
                    <td>Branch</td>
                    <td>{toolbarfixture[0].branches ??  ""}</td>
                  </tr>
              
       
           </table>
             )}
              <br></br>
              <div class="row" style={{textAlign:"center"}}>
              <p>www.iiautomate.com</p>
              </div>
              <hr></hr>
              
            </div>
           
          </div>
        </div>
        
      </div>
      <div style={{ textAlign: "center", padding: "10px" }}>
              <button onClick={handleDownloadPDF}>Download as PDF</button>
            </div>
    </div>
  )
}

export default ToolsFixturePreviewForm