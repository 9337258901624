import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink } from "react-router-dom";
import logo1 from "../img/logo/IIA LOGO-01.png";
import "bootstrap/dist/css/bootstrap.min.css";

const NavBar = () => {
  return (
    <div>
      <header className="site-header header">




        <div className="sticky-header main-bar-wraper navbar-expand-lg">
          <div className="main-bar clearfix onepage">
            <div className="container clearfix">
              <div className="logo-header mostion">
                <Link to="/">
                  <img src={logo1} alt="" />
                </Link>
              </div>
              <button
                className="navbar-toggler collapsed navicon justify-content-end "
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
               >
                <span></span>
                <span></span>
                <span></span>
              </button>
              {/* <!-- main nav --> */}
              <div
                className="header-nav navbar-collapse collapse navbar myNavbar justify-content-end"
                id="navbarNavDropdown"
                >
                <ul className="nav navbar-nav">
                  <li className="nav-item">
                    <NavLink to="/" className="nav-link ">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown ">
                    <NavLink to="/about-us" className="nav-link ">
                      About
                    </NavLink>

                  </li>


                  <li className="nav-item dropdown">
                    <Link
                      to="/courses"
                      className="nav-link "
                      id="navbarDropdownMenuLink"
                      
                     >
                      Courses
                    </Link>


                    {/* <div className="dropdown-menu mega-menu" aria-labelledby="navbarDropdownMenuLink">
                      <div className="row">
                       
                        <div className="col-md-6">
                         
                          <ul className="list-unstyled">
                            <li>
                              <NavLink className="dropdown-item" to="/tool-&-fixture-design">
                                Tool And Fixture Design
                              </NavLink>
                            </li>
                            <li>
                              <NavLink className="dropdown-item" to="/online-robotics">
                                Online Robotics
                              </NavLink>
                            </li>
                            <li>
                              <NavLink className="dropdown-item" to="/offline-robotics">
                                Offline Robotics
                              </NavLink>
                            </li>
                            <li>
                              <NavLink className="dropdown-item" to="/Java-corses">
                                Java
                              </NavLink>
                            </li>
                            <li>
                              <NavLink className="dropdown-item" to="/ReactJs-corses">
                                React Js
                              </NavLink>
                            </li>
                            <li>
                              <NavLink className="dropdown-item" to="/flutter-corses">
                                Flutter
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                       
                        <div className="col-md-6">
                          
                          <ul className="list-unstyled">
                            <li>
                              <NavLink className="dropdown-item" to="/cloud-computing">
                                Cloud Computing
                              </NavLink>
                            </li>
                            <li>
                              <NavLink className="dropdown-item" to="/data-analystic">
                                Data Analytics
                              </NavLink>
                            </li>
                            <li>
                              <NavLink className="dropdown-item" to="/ai-ml-solutions">
                                AI & ML Solutions
                              </NavLink>
                            </li>
                            <li>
                              <NavLink className="dropdown-item" to="/automation-solutions">
                                Automation Solutions
                              </NavLink>
                            </li>
                            <li>
                              <NavLink className="dropdown-item" to="/ecommerce-digital-marketing">
                                E-commerce & Digital<br></br> Marketing Solutions
                              </NavLink>
                            </li>



                          </ul>
                        </div>

                     

                      </div>
                    </div> */}

                    
                  </li>



                  {/* <li className="nav-item"><NavLink to="/courses" className="nav-link ">Courses</NavLink></li> */}

                  <li className="nav-item">
                    <NavLink to="/techno-talks" className="nav-link ">
                      TECHNO-TALKS
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/placements" className="nav-link ">
                      Placement
                    </NavLink>
                  </li>
                  <li className="nav-item">
            
                      <NavLink to="/enroll" className="nav-link">
                        Enroll
                      </NavLink>
              
                  </li>
                  <li className="nav-item">
                    <NavLink to="/user-login" className="nav-link ">
                      Login
                    </NavLink>
                  </li>

              

                  <li>
                    <Link
                      to="https://www.facebook.com/login.php"
                      target="_blank"
                      className="site-button-link facebook hover"
                    >
                      <FontAwesomeIcon icon="fa-brands fa-facebook-f" color="#524ea1" className="facebook-svg" />
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="https://accounts.google.com/v3/signin/identifier?continue=https%3A%2F%2Faccounts.google.com%2F&followup=https%3A%2F%2Faccounts.google.com%2F&ifkv=ARZ0qKItBbL2XOMVYb971aEhIdLiNShpsISxjMWaPhcP3goM0Xtr-dedghwOJF0t8nmsEbU7vDqr4A&passive=1209600&flowName=GlifWebSignIn&flowEntry=ServiceLogin&dsh=S352710403%3A1711972370285188&theme=mn&ddm=0"
                      target="_blank"
                      className="site-button-link google-plus hover"
                    >
                      <FontAwesomeIcon icon="fa-brands fa-google-plus-g" color="#fff"/>
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to="https://twitter.com/?lang=en"
                      target="_blank"
                      className="site-button-link twitter hover"
                    >
                      <FontAwesomeIcon icon="fa-brands fa-twitter" color="#524ea1" className="facebook-svg" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/accounts/login/"
                      target="_blank"
                      className="site-button-link instagram hover"
                    >
                      <FontAwesomeIcon icon="fab fa-instagram" color="#524ea1" className="facebook-svg" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://in.linkedin.com/"
                      target="_blank"
                      className="site-button-link linkedin hover"
                    >
                      <FontAwesomeIcon icon="fa-brands fa-linkedin" color="#524ea1" className="facebook-svg" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.youtube.com/@IIAUTOMATE"
                      target="_blank"
                      className="site-button-link youtube hover"
                    >
                      <FontAwesomeIcon icon="fab fa-youtube" color="#524ea1" className="facebook-svg" />
                    </Link>
                  </li>

                </ul>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default NavBar;
