import React, { useState,useEffect } from "react";
import { Form } from "react-bootstrap";

const DigitalFacilitySolWebinarForm = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  // api integration
  const [dfs, setDfs] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/dfs/get');
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setDfs(result);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  //  if (isLoading) {
  //    return <div>Loading...</div>;
  //  }

  //  if (error) {
  //    return <div>Error: {error.message}</div>;
  //  }
      const [formData, setFormData] = useState({
        fullName: '',
        gender: '',
        dateOfBirth: '',
        emailId: '',
        contactNumber: '',
      
        course: '',
        // Add more fields as needed
      });
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();

        if (dfs.price === "") {
          alert("please enter amount below");
        } else {
          var options = {
            key: "rzp_test_rA2UalGsJwTy9v",
            key_secret: "RG7A51JvDSuFfhUzkGVbpAtn",
            amount: dfs.price * 100,
            currency: "INR",
            captured: true,
            payment_capture: true,
            name: "International Institute Of Automate",
            handler: function (response) {
              alert(
                "Thanks for your intrest \n Your Payment has been successfully completed"
              );
              window.location.href = `/digital-webinor-preview-form`;
            },
            prefill: {
              name: formData.fullName,
              email: formData.emailId,
              contact: formData.contactNumber,
            },
            notes: {
              address: "International Institute Of Automate",
            },
            theme: {
              color: "#3399cc",
            },
          };
          var pay = new window.Razorpay(options);
          pay.open();
        }
    
        try {
          const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/dfs/registration', 
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
          });
          // Handle response as needed
          const responseData = await response.json();
          localStorage.setItem('responseData',responseData.id);
        } catch (error) {
          console.error('Error:', error);
        }
      };
       
  return (
    <div>
 <section>
    <div className="container-fluid">
      <div
        className="container  boderform"
        style={{
          backgroundColor: "#efefef",
          paddingBottom: "30px",
          borderRadius: "10px",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            color: "#5B4B99",
            paddingBottom: "50px",
            paddingTop: "30px",
          }}
        >
          Digital Facility Solution Webinar Form
        </h2>
        <form onSubmit={handleSubmit} className="donate-form">
          <div className="row" style={{ paddingBottom: "25px" }}>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Full Name*</Form.Label>
              <Form.Control
                type="text"
                
                placeholder="Enter Name"
               
                name="fullName" value={formData.fullName} onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Gender*</Form.Label>
              <Form.Select
                aria-label="Default select example "
                name="gender" value={formData.gender} onChange={handleChange}
                required
              >
                <option>Enter Your Gender</option>
                <option   value= "Male" >Male</option>
                <option  value="Female" >Female</option>
                <option  value="Others" >Others</option>
              </Form.Select>
            </Form.Group>

            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>DOB*</Form.Label>
              <Form.Control
                type="date"
                
                placeholder="date of birth"
                name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>EMail-Id*</Form.Label>
              <Form.Control
                type="email"
               
                placeholder="Enter Your Email"
              
                name="emailId" value={formData.emailId} onChange={handleChange}
                required
              />
            </Form.Group>
          </div>
          <div className="row" style={{ paddingBottom: "25px" }}>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Contact No.*</Form.Label>
              <Form.Control
                type="number"
                
                placeholder="Enter Your Contact No."
                name="contactNumber" value={formData.contactNumber} onChange={handleChange}
                required
              />
            </Form.Group>
            
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Courses*</Form.Label>
              <Form.Select
                aria-label="Default select example "
               
                name="course" value={formData.course} onChange={handleChange}
             
                required
              >
                <option>Choose your course</option>
                <option disabled>Fixture design</option>
                <option disabled >Industrial Automation</option>
                <option  value="Digital_Facility_Solution" >Digital Facility Solution</option>
              </Form.Select>
            </Form.Group>
            {dfs && (
                  <>
                   <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Webinar Name*</Form.Label>
              <Form.Control
                // type="number"
                
                placeholder={dfs.webinarName}
               disabled
                required
              />
            </Form.Group>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Course Duration*</Form.Label>
              <Form.Control
                type="number"
                
                placeholder={dfs.courseDuration}
                disabled
                required
              />
            </Form.Group>
                      
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Mode*</Form.Label>
              <Form.Control
                // type="number"
                
                placeholder={dfs.mode}
                disabled
                required
              />
            </Form.Group>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Time*</Form.Label>
              <Form.Control
                // type="number"
                
                placeholder={dfs.timing}
                disabled
                required
              />
            </Form.Group>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Price*</Form.Label>
              <Form.Control
                // type="number"
                
                placeholder={dfs.price}
               disabled
                required
              />
            </Form.Group>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Date*</Form.Label>
              <Form.Control
                // type="number"
                
                placeholder={dfs.date}
               disabled
                required
              />
            </Form.Group>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Speaker Name*</Form.Label>
              <Form.Control
                // type="number"
                
                placeholder={dfs.speakerName}
               disabled
                required
              />
            </Form.Group>
                     
                    
                  </>
                )}
          </div>
        
          <div style={{textAlign:"center"}}>
          <button
            type="submit"
            className="custom-btn btn-3"
           
          >
            <span>Submit</span>
          </button>
          </div>
          
        </form>
      </div>
    </div>
  </section>

    </div>
  )
}

export default DigitalFacilitySolWebinarForm