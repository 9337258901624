import React, { useState } from "react";
import DownloadBrocherpopup from "./DownloadBrocherpopup"; // Modal
import ButtonPage from './ButtonPage'; // Button component
import flutterban from '../img/banners/Filter-mobile-app.jpg'
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
const FlutterBanner = () => {

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  return (
    <div>
      <section>
        <Carousel className="res-nav-mob no-indicators" interval={300000} controls={false} >
          <div className="banner-img">
            <img className="d-block w-100" src={flutterban} alt="Tool & Fixture Design" style={{ height: "650px" }} />
          </div>
          <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
            <div className="row">
              {/* Column 1 */}
              <div className="col-lg-4 dtd" style={{}}>
                <div className="" style={{ width: "110%" }}>

                  <h2 className="banner-content"
                    style={{
                      fontSize: "32px",
                      color: "#fff",
                      fontFamily: "Raleway!important",
                      fontWeight: "600",
                      width: "115%"
                    }}
                  >
                    Master cross-platform app development From Design to Deployment
                  </h2>
                  <h2 className="banner-content"
                    style={{
                      fontSize: "40px",
                      color: "#fff",
                      fontFamily: "Raleway!important",
                      fontWeight: "800",
                    }}
                  >
                    FLUTTER MOBILE <span style={{ color: "#F58842" }}> APP</span>
                  </h2>
                  {/* <h2   style={{  fontSize: "40px", color: "#524DA1",  fontFamily: "roboto",  fontWeight: "800",    }}     >
          
            ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>
            </h2> */}
                  <p className="banner-contentul"
                    style={{
                      fontSize: "17px",
                      color: "#fff",
                      fontWeight: "400",
                      textAlign: "justify",
                      textIndent: "50px",
                      fontWeight: "bold"
                    }}
                  >
                    1. Industry-Oriented Flutter Training (IFT)
                  </p>
                  <p className="banner-contentul"
                    style={{
                      fontSize: "17px",
                      color: "#fff",
                      fontWeight: "400",
                      textAlign: "justify",
                      textIndent: "50px",
                      fontWeight: "bold"
                    }}
                  >
                    2. Mobile Development Skill Enhancement (MDSE)
                  </p>
                  <p className="banner-contentul"
                    style={{
                      fontSize: "17px",
                      color: "#fff",
                      fontWeight: "400",
                      textAlign: "justify",
                      textIndent: "50px",
                      fontWeight: "bold"
                    }}
                  >
                    3. 100% Placement Support
                  </p>
                  <p className="banner-contentul"
                    style={{
                      fontSize: "22px",
                      color: "#fff",
                      fontWeight: "400",
                      textAlign: "justify",
                      // marginLeft: "49px",
                      fontWeight: "bold"
                    }}
                  >
                    Free Dart programming language training provided
                  </p>

                 
                </div>
              </div>

              <div className="col-lg-8 dtdt ">
                <h2 className="banner-content" style={{ fontSize: "30px", color: "#fff", fontFamily: "Raleway!important", fontWeight: "600", }}    >
                  “Shape the Future of Mobile Apps Across India”
                </h2>

              </div>
            </div>
            <div >
              <div> {/* ButtonPage to trigger the modal */}

                <ButtonPage handleShow={handleShow} />

                {/* Modal Component */}
                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} /></div>


            </div>
          </Carousel.Caption>
        </Carousel>
      </section>
    </div>
  )
}

export default FlutterBanner