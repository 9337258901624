import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const DownloadBrochurePopup = ({ showModal, handleClose }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    mailId: '',
    contactNumber: '',
    cources: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDownload = async () => {
    alert('Brochure send successfully to Your mail')
    console.log(formData);
    try {
      const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/contact/us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      console.log('Data submitted successfully');
      handleClose();
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  return (
    <>
      <style>
        {`
          .custom-modal-backdrop {
            background-color: rgba(0, 0, 0, 0.2); /* Light backdrop with transparency */
            pointer-events: none; /* Prevent interaction with the background */
          }
             /* Responsive styles for smaller mobile devices */
          @media (max-width: 480px) {
            .modal-body {
              padding: 1rem;
            }

            .form-control {
              font-size: 0.85rem;
              padding: 6px;
            }

            .modal-footer button {
              padding: 0.5rem;
              font-size: 0.9rem;
            }
              .modal-content{
                text-align: center;
                margin-right: 28px;
              }
          }
        `}
      </style>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        backdropClassName="custom-modal-backdrop" // Apply custom backdrop 
      >
        <Modal.Body>
          <div style={{ textAlign: 'right' }}>
            <Button variant="light" onClick={handleClose} style={{ marginTop: '20px' }}>
              <span aria-hidden="true">&times;</span>
            </Button>
          </div>
          <form>
            <div className="form-group">
              <label htmlFor="fullName">Name</label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                name="fullName"
                placeholder="Enter your name"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="mailId">Email address</label>
              <input
                type="email"
                className="form-control"
                id="mailId"
                name="mailId"
                placeholder="Enter your email"
                value={formData.mailId}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="contactNumber">Phone Number</label>
              <input
                type="tel"
                className="form-control"
                id="contactNumber"
                name="contactNumber"
                placeholder="Enter your phone number"
                value={formData.contactNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="courses">Select Course</label>
              <select
                className="form-control"
                id="courses"
                name="cources"
                value={formData.cources}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  -- Select a course --
                </option>
                <option value="ToolandFixtureDesign">Tool & Fixture Design</option>
                <option value="OnlineRobotics">Online Robotics</option>
                <option value="OfflineRobotics">Offline Robotics</option>
                <option value="ReactJs">ReactJs</option>
                <option value="Java">Java Development</option>
                <option value="Flutter">Flutter Mobile App Development</option>
              </select>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleDownload}>
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DownloadBrochurePopup;
