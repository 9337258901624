import React from 'react'
import JavaCorses from "./JavaCorses";
import FlipCards from "./FlipCards";
import Partners from './Partners'
import CloudComputingBanner from './CloudComputingBanner';
import CloudComputingTabs from './CloudComputingTabs';

const PlacementsCloudComputing = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div> 

      <CloudComputingBanner/>
      <CloudComputingTabs></CloudComputingTabs>
    {/* <JavaCorses></JavaCorses>  */}
    
    <FlipCards />
     {/* <Partners></Partners> */}
   
     </div>
  )
}

export default PlacementsCloudComputing