import React, { useState } from "react";
import DownloadBrocherpopup from "./DownloadBrocherpopup"; // Modal
import ButtonPage from './ButtonPage'; // Button component
import techtalk from "../img/banners/Tool-&-Fixture-v2.jpg";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import onlinerobotics from "../img/banners/online-robotics.jpg";
const IndustrialBanner = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  return (
    <div>
    <section>
    <Carousel className="res-nav-mob no-indicators" interval={300000} controls={false}>
    <img className="d-block w-100"   src={onlinerobotics} alt="Online Robotics"  style={{ height: "650px" }}  />

<Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
  <div className="row">
    {/* Column 1 */}
    <div className="col-lg-4 dtd" style={{}}>
      <div className="" style={{ width: "100%" }}>
      <h2 className="banner-content" style={{fontSize: "32px",   color: "#000",  fontFamily: "Raleway!important",   fontWeight: "600",  width:"136%" ,  }}     >
  India's First Indigenous Industrial robotics Training center
         </h2>
        <h2 className="banner-content"  style={{  fontSize: "40px", color: "#524DA1",  fontFamily: "Raleway!important",  fontWeight: "800", width:"106%"    }}     >

INDUSTRIAL AUTOMATION

</h2>

        <p  className="banner-contentul"   style={{   fontSize: "20px", color: "#000",    textAlign: "justify", textIndent: "50px", fontWeight: "400",fontWeight: "bold",  }}  >
  1 Industrial Oriented Training(IOT)

  </p>
  <p className="banner-contentul" style={{  fontSize: "20px", color: "#000",    textAlign: "justify", textIndent: "50px", fontWeight: "400", fontWeight: "bold", }} >
    2. Job oriented skill enhancement (JOES)
  </p>
  <p className="banner-contentul" style={{fontSize: "20px",  color: "#000",   textAlign: "justify",   textIndent: "50px", fontWeight: "400",fontWeight: "bold",  }}  >
    3. 100% Placement Support
  </p>
  <p  className="banner-contentul" style={{  fontSize: "25px",color: "#000",    textAlign: "justify", fontWeight: "400", marginLeft:"49px",fontWeight: "bold", width:"110%"}} >  Delmia Simulation Software Free Training

  </p>

        <div className="view-btn">
{/* 
          <Link to="/industrial-automation" className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "10px 20px", float: "right", }}>
            View More
          </Link> */}
        </div>
      </div>
    </div>

    {/* Column 2 */}
    <div className="col-lg-8 dtdt ">
    <h2 className="banner-content" style={{fontSize: "32px",   color: "#000",  fontFamily: "Raleway!important",   fontWeight: "600",     }}     >
  India's First Indigenous Industrial robotics Training center
         </h2>
    </div>
    <div >
      <div> {/* ButtonPage to trigger the modal */}
 
        <ButtonPage handleShow={handleShow} />

        {/* Modal Component */}
        <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} />


      </div>


    </div>
  </div>



  {/* Flexbox container for buttons */}


</Carousel.Caption>
          </Carousel>
    </section>
</div>
  )
}

export default IndustrialBanner