import React from 'react'
import TechnoTalksReactCourses from "./TechnoTalksReactCourses";
import TechnoTalksCources from "./TechnoTalksCources";
import Partners from './Partners';
import ReactJsBanner from './ReactJsBanner';
import ReactjsTabs from './ReactjsTabs';
const TechnoTalksReactjs = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div> 
           <ReactJsBanner/>
           <ReactjsTabs></ReactjsTabs>
    {/* <TechnoTalksReactCourses></TechnoTalksReactCourses>  */}
    <TechnoTalksCources />
     {/* <Partners></Partners> */}
     </div>
  )
}

export default TechnoTalksReactjs