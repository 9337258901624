import React from "react";
import Slider from "react-slick";
import candidate1 from "../img/Placed/ppl/Placement_Poster_V1.jpg";
import candidate2 from "../img/Placed/ppl/Placement_Poster_V2.jpg";
import candidate3 from "../img/Placed/ppl/Placement_Poster_V3.jpg";
import candidate4 from "../img/Placed/ppl/Placement_Poster_V4.jpg";
import candidate5 from "../img/Placed/ppl/Placement_Poster_V5.jpg";
import candidate6 from "../img/Placed/ppl/Placement_Poster_V6.jpg";
import candidate7 from "../img/Placed/ppl/Placement_Poster_V7.jpg";
const PlacementsCards = () => {
  var seminorSliders = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    infinite: true,
    dots: false,
    autoplaySpeed: 2000,
    // centerMode: true,
    // centerPadding: "20px", // Allows slight overflow for side slides
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <div className='seminor' style={{background:"rgb(206, 202, 202)"
    }}>
    <div className="partner-area ptb-100">
      <div className="container">
        <div className="partner-bg style-two">
          {/* <span
            style={{
              fontWeight: "700px",
              color: "rgb(70 53 133)",
              paddingTop: "60px!important",
            }}
          >
            TECHNO- <span style={{ color: " #F58634" }}>TALKS</span>
          </span> */}
           <h1 className="heading-names" style={{ color: "rgb(70 53 133)", fontSize: "40px", lineHeight: "1.3" }}>
     PLACED <span style={{ color: "#F58634" }}>CANDIDATES</span>
              </h1>

          <div className="logo-sliders" style={{ paddingLeft: "0px!important", paddingRight: "0px!important" }}>
            <Slider {...seminorSliders}>
              {[candidate1, candidate2, candidate3,candidate4,candidate5,candidate6,candidate7].map((logo, index) => (
                <div key={index}>
                  <img src={logo} alt="Image" style={{ height: "367px", width: "370px" }} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};
export default PlacementsCards;
