import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const OurCommitments = () => {
  return (
    <div>
      <section id="cards-container" style={{ paddingTop: "20px",    backgroundColor: "#fff" }}>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="text-center">
              <h1 className="heading-names" style={{ color: "rgb(99 84 156)", fontSize: "40px", lineHeight: "1.3" }}>
                  OUR <span style={{ color: "#F58634" }}>COMMITMENTS</span>
                </h1>

                {/* <span
                  style={{
                    color: "#fff",
                    fontSize: "30px",
                    lineHeight: "1.8",
                  }}
                >
                  OUR  <span style={{ color: " #F58634" }}>COMMITMENTS</span>
                </span> */}
              </div>

              <div className="col-md-4 card-wrapper">
                <div className="card" style={{ height: "320px" }}>
                  <div className="card-top gradient-green">
                    <div className="icon">
                      <FontAwesomeIcon
                        icon="fa-solid fa-crosshairs"
                        beatFade
                        style={{ color: "#49368E" }}
                      />
                    </div>
                  </div>
                  <div className="card-content">
                    <h4 className="card-title" style={{ paddingTop: "40px" }}>
                      Our Vision
                    </h4>
                  </div>
                  <div className="overlay slider" style={{ height: "320px" }}>
                    <h4 style={{ color: "#695B9D", paddingTop: "40px" }}>
                      Our Vision
                    </h4>
                    <p
                      style={{
                        color: "#000",
                        fontSize: "14px",
                        textAlign: "justify",
                      }}
                    >
                      Our vision is to be a beacon of bringing Employability
                      Enhancement Education at affordable prices to every
                      individual in the country and fostering a community of
                      learners who are not only academically proficient but also
                      equipped with the skills, values, and vision needed to
                      make a positive impact on the world.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 card-wrapper">
                <div className="card" style={{ height: "320px" }}>
                  <div className="card-top gradient-green">
                    <div className="icon">
                      <FontAwesomeIcon
                        icon="fa-solid fa-bullseye"
                        beatFade
                        style={{ color: "#49368E" }}
                      />
                    </div>
                  </div>
                  <div className="card-content">
                    <h4 className="card-title" style={{ paddingTop: "40px" }}>
                      Our Mission
                    </h4>
                  </div>
                  <div className="overlay fade" style={{ height: "320px" }}>
                    <h4 style={{ color: "#695B9D" }}>Our Mission</h4>
                    <p
                      style={{
                        color: "#000",
                        fontSize: "14px",
                        textAlign: "justify",
                      }}
                    >
                      Our mission is to empower the next generation of thinkers,
                      innovators and leaders with the automation knowledge and
                      skills in providing a dynamic and transformative learning
                      experience that goes beyond to shape the future of
                      industries.
                    </p>
                  </div>
                </div>
              </div>

              {/* <!-- Zoom --> */}
              <div className="col-md-4 card-wrapper">
                <div className="card" style={{ height: "320px" }}>
                  <div>
                    <div className="card-top gradient-green">
                      <div className="icon">
                        <FontAwesomeIcon
                          icon="fa-solid fa-heart"
                          beatFade
                          style={{ color: "#49368E" }}
                        />
                      </div>
                    </div>
                    <div className="card-content">
                      <h4 className="card-title" style={{ paddingTop: "40px" }}>
                        Our Values
                      </h4>
                    </div>
                  </div>
                  <div className="overlay zoom" style={{ height: "320px" }}>
                    <h4 style={{ color: "#695B9D" }}>Our Values</h4>
                    <p
                      style={{
                        color: "#000",
                        fontSize: "14px",
                        textAlign: "justify",
                      }}
                    >
                      Passion to learn and grow<br></br>
                      Responsible to the society<br></br>
                      Integrity and Leadership<br></br>
                      Goal orientation & Innovation<br></br>
                      Teamwork & Contributions
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurCommitments;
