import React, { useState } from 'react';
import { Link } from "react-router-dom";
import redo from '../img/education-icon/redo.png'

const CloudComputingTabs = () => {

  const [activeTab, setActiveTab] = useState('tab1');

  const tabs = [
    { 
      id: 'tab1', 
      label: 'Level 1 Certification',  
      content: (
        <>
          <div className="row">

<div className="row">
  <div className="align-items-center justify-content-between">
    {/* Left side content */}
    <div>
      <div style={{  textAlign:'center' }}>
        <h2 style={{ color: "#fff", padding:'20px' }}>
          Certified  <span style={{ color: "rgb(224 120 44)" }}>Cloud Computing Courses</span>
        </h2>

       
      </div>

     
        <p style={{fontSize:'20px',textAlign:'justify'}}> <img src={redo} style={{height:'30px',width:'30px'}}></img> Cloud services can be scaled up or down to meet the fluctuating needs of businesses, such as increased data storage or processing power.</p>
        <p style={{fontSize:'20px',textAlign:'justify'}}> <img src={redo} style={{height:'30px',width:'30px'}}></img> Scalability Cloud services can be scaled up or down to meet the fluctuating needs of businesses, such as increased data storage or processing power.</p>
        <p style={{fontSize:'20px',textAlign:'justify'}}> <img src={redo} style={{height:'30px',width:'30px'}}></img> Global Accessibility Data and applications can be accessed from anywhere in the world, allowing for remote work and collaboration.</p>
        <p style={{fontSize:'20px',textAlign:'justify'}}> <img src={redo} style={{height:'30px',width:'30px'}}></img> Collaboration Teams can work together in real-time, regardless of location, using cloud-hosted apps and services.</p>
      
    </div>

    {/* Right side button data*/}

  </div>
</div>




</div>
          <div className="row">
        
          </div>
          <div style={{textAlign:'center'}}>
          <Link to="/user-login" >
                      <button className="custom-btn btn-3" style={{ marginLeft: "20px" }}>
                        <span>Register Now</span>
                      </button>
                    </Link>
                    </div>
        </>
      )
    },
  //   { 
  //     id: 'tab2', 
  //     label: 'Advance Java Courses',  
  //     content: (
  //       <>
  //        <div className="row">
        



  //           <div className="col-lg-6"> 

  //           <h2 style={{color: "#rgb(246 147 75)",}}>Advance Java <span style={{ color: "#fff" }}>Courses </span></h2>
  //           <ul style={{color: "#fff",
  //   fontSize: "18px",
  //   textAlign: "justify"}}>
  //   <li> Java is a pure object-oriented language, emphasizing code reusability, modularity.</li>
  //   <li> Java's write once run anywhere capability ensures compatibility  various platforms.</li>
  //   <li> Java allows concurrent execution of threads, enhancing application performance.</li>
  //   <li> Java provides extensive libraries for networking, I/O, and enterprise applications.</li>
  //   <li>Memory Management Automatic garbage collection in Java helps manage memory efficiently.</li>
  //   <li>Security Features Java includes built-in security mechanisms like bytecode verification and encryption.</li>
  // </ul>
            
  //           </div>
  //           <div className="col-lg-6 d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
  //   <Link to="/enroll">
  //     <button className="custom-btn btn-3 " style={{ marginTop: '100px' }}>
  //       <span>Register Now</span>
  //     </button>
  //   </Link>
  // </div>
  //         </div>
  //         <div className="row">
  //         <div className=""> 
  //           <p style={{color: "#fff",
  //   fontSize: "20px",
  //   textAlign: "justify"}}> Java is a versatile, object-oriented programming language known for its portability, scalability, and security. Developed by Sun Microsystems in 1995, it allows developers to write code once and run it anywhere (WORA) on any platform with a Java Virtual Machine (JVM). Java is widely used for building web applications, mobile apps, and enterprise-level systems. Its syntax is similar to C++ but is designed to be easier to understand and implement. Java offers strong memory management, multi-threading support, and robust API libraries. The language is central to the Android development ecosystem. Java's extensive community support and vast ecosystem of libraries and frameworks make it a go-to choice for developers worldwide. Additionally, it plays a significant role in big data technologies, powering platforms like Hadoop and Spark for large-scale data processing.</p>
             
            
            
  //           </div>
  //         </div>
  //       </>
  //     )
  //   },
  
  ];
  
  const currentTab = tabs.find(tab => tab.id === activeTab);

  return (
    <div className="tab-wrapper" style={{ padding: '30px 40px',    backgroundColor: "#fff"  }}> {/* Add padding here  data fetching */}
      <div className="tab-container">
        {tabs.map(tab => (
          <button
            key={tab.id}
            className={`tab-button ${activeTab === tab.id ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="tab-content">
        <p>{currentTab.content}</p>
      </div>
      <style>
      {`
          .tab-container {
            display: flex;
            justify-content: center; /* Center the tabs horizontally */
            margin-bottom: 20px; /* Space between tabs and content */
          }
          .tab-button {
            flex: 1; /* Make all tabs take equal width */
            max-width: 500px; /* Set a maximum width for each tab */
            padding: 10px;
            border: none;
            background: none;
            cursor: pointer;
            font-size: 16px;
            transition: background-color 0.3s;
            margin-right: 5px; /* Add some space between tabs */
            outline: none; /* Remove default outline */
           
            // border-radius: 50px;
                background: #4f4e70;
                 color: #f58842; /* Default text color */
                    margin-top: 10px;
                    font-size: 18px;
          }
          .tab-button:hover {
            background-color: #f0f0f0;
          }
          .tab-button.active {
            border-bottom: 2px solid #524DA1; /* Active tab color */
            font-weight: bold;
            color: #f58842; /* Change text color for active tab */
                // border-radius: 50px;
                background: #efefef;
                    margin-top: 10px;
                    font-size: 18px;
          }
        
          .tab-content {
            padding: 20px;
            border: 1px solid #ccc;
            border-top: none; /* Remove top border to connect with tabs */
            background-color: rgb(91 86 110);
                color: #fff;
          }
                 .tab-button.disabled {
            background-color: #ddd; /* Grey out background */
            color: #888; /* Lighter text color */
            cursor: not-allowed; /* Show not-allowed cursor */
            pointer-events: none; /* Disable pointer events */
          }
             .tab-button:hover:not(.disabled) {
            background-color: #f0f0f0;
          }
        `}
    
    
      </style>
    </div>
  );
};

export default CloudComputingTabs;
