import React from "react";
import Slider from "react-slick";
import candidate1 from "../img/Placed/ppl/Placement_Poster_V1.jpg";
import candidate2 from "../img/Placed/ppl/Placement_Poster_V2.jpg";
import candidate3 from "../img/Placed/ppl/Placement_Poster_V3.jpg";
import candidate4 from "../img/Placed/ppl/Placement_Poster_V4.jpg";
import candidate5 from "../img/Placed/ppl/Placement_Poster_V5.jpg";
import candidate6 from "../img/Placed/ppl/Placement_Poster_V6.jpg";
import candidate7 from "../img/Placed/ppl/Placement_Poster_V7.jpg";

const PlacedCandidates = () => {
  const seminorSliderss = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    infinite: true,
    dots: false,
    autoplaySpeed: 2000,
    lazyLoad: 'ondemand',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className="seminor" style={{ background: '#cecaca' }}>
      <div className="partner-area ptb-100">
        <div className="container">
          <div className="partner-bg style-two">
            <h1
              className="heading-names"
              style={{
                color: "rgb(70 53 133)",
                fontSize: "40px",
                lineHeight: "1.3",
                padding: '20px'
              }}
            >
              PLACED <span style={{ color: "#F58634" }}>CANDIDATES</span>
            </h1>

            <div className="logo-sliders" style={{ paddingLeft: "0px!important", paddingRight: "0px!important" }}>
              <Slider {...seminorSliderss}>
                <div>
                  <img src={candidate1} alt="Candidate 1" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={candidate2} alt="Candidate 2" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={candidate3} alt="Candidate 3" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={candidate4} alt="Candidate 4" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={candidate5} alt="Candidate 5" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={candidate6} alt="Candidate 6" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={candidate7} alt="Candidate 7" style={{ height: "367px", width: "370px" }} />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlacedCandidates;
