import React, { useState, useEffect } from "react";
import robotics from "../img/section-images/robotics.jpg";

import { Link } from "react-router-dom";
const ErpapplicationCorses = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });


  // api integration 
  const [onlinerobotics, setOnlinerobotics] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/OnlineRobotics/get');
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setOnlinerobotics(result);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  //  if (isLoading) {
  //    return <div>Loading...</div>;
  //  }

  //  if (error) {
  //    return <div>Error: {error.message}</div>;
  //  }

  function MyChildComponent() {
    return (
      <div>
        <div class="" style={{ paddingTop: "10px" }}>
          <div class="">
            <section
              className="about-area about-style-three about-us-mobile "
              style={{ textAlign: "justify" }}
            >
              <div className="container">
                <div className="row align-items-center ">
                  <div className="about-content page-style ml-15"></div>
                  <div className="col-lg-6">


<div className="about-content page-style ml-15">
<div className="text-center" style={{marginTop: "-40px"}}>
<span
className="top-title"
style={{
  fontSize: "35px",
  paddingBottom: "15px",
}}
>
Key Features
</span>
</div>
</div>
  <ul style={{ textAlign: "justify" }}>
    <li>Centralized Data Management Streamlining business processes with a unified data.</li>
    <li>Real-Time Analytics Providing actionable insights with real-time data tracking .</li>
    <li>Inventory Management Optimizing stock levels  minimizing supply chain disruptions.</li>
    <li>Financial Management Automating accounting tasks enhancing  transparency.</li>
    <li>Human Resources Management Managing employee data, benefits efficiently.</li>
    <li>Compliance and Risk Management Ensuring business adherence to regulations and mitigating risks.</li>
  </ul>
  {/* <div className="about-img text-center">
    <img src={robotics} alt="Image" />
  </div> */}
</div>
                  <div
                    className="col-lg-6"
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      lineHeight: "2.6 ",
                    }}
                    >
                    <div className="text-center">
                      <span
                        className="top-title"
                        style={{
                          fontSize: "35px",
                          paddingBottom: "20px",
                        }}
                      >
                        Course Information
                      </span>
                    </div>

                    {onlinerobotics && (
                      <table>
                        <tbody>
                          <tr>
                            <td>Course Duration</td>
                            <td>{onlinerobotics.courseDuration}</td>
                          </tr>
                          {/* <tr>
               <td>Date</td><td>{data.date}</td>
               </tr> */}
                          <tr>
                            <td>Mode</td>
                            <td>{onlinerobotics.mode}</td>
                          </tr>
                          <tr>
                            <td>Time</td>
                            <td>{onlinerobotics.timing}</td>
                          </tr>

                          <tr>
                            <td>Price</td>
                            <td>{onlinerobotics.price}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}

<Link to="/contact-us?category=mechanical-engineering">
                  <button className="custom-btn btn-3">
                    <span>Register Now</span>
                  </button>
                </Link>
                  </div>
                </div>
              </div>
            </section>
            {/* <section
        className="about-area about-style-three about-us-mobile"
        style={{ textAlign: "justify" }}
      >
        <div className="container">
          <div className="row align-items-center " style={{paddingBottom:"10px"}}>
            <div
              className="about-content page-style ml-15  "
              style={{ textAlign: "justify", fontSize: "18px" }}
            >
              <div className="text-center">
                <span
                  style={{
                    fontSize: "30px",
                    color: "#5B4B99",
                   
                  }}
                >
                  Key Features:
                </span>
              </div>
            </div>
            <div className="col-lg-6">
            <div
                className="about-content page-style ml-15 bdstyle conheigh"
                style={{ textAlign: "justify" }}
              >
                <h4>Centralized Data Management:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                     
                    }}
                  >
                    ERP solutions centralize data from various business departments into a 
                    single system, ensuring that all stakeholders have access to accurate.
                    
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                     
                    }}
                  >
                    This eliminates data silos and improves collaboration
                     across teams customized to meet the specific needs.
                    
                  </li>
                </ul>
                <h4>Automation of Business Processes:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    ERP systems automate routine tasks like invoicing, payroll, and inventory tracking.
                     Automation reduces manual errors, speeds up workflows.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    {" "}
                    Employees to focus on more strategic activities specific needs of different industries, 
                    such as manufacturing, healthcare, or retail.
                  </li>
                </ul>
                <h4>Scalability and Customization:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                     ERP solutions are highly scalable, allowing businesses to expand 
                     system functionalities as they gro.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Customized to meet the specific needs of different industries,
                  </li>
                </ul>
                <h4>Cloud-Based ERP Solutions:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                   Cloud ERP systems allow businesses to access and manage operations 
                   from anywhere, providing greater flexibility.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                
                    }}
                  >
                    These solutions offer real-time data synchronization and reduce the 
                    need for expensive on-site IT infrastructure. 
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
            <div className="about-content page-style ml-15 bdstyle conheigh"  style={{paddingBottom:"185px"}}>
                <div className="text-center"></div>
                <h4>Enhanced Reporting and Analytics:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    ERP systems come with robust reporting and analytics tools, enabling businesses 
                    to generate detailed insights into their operations.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    With these tools, companies can make data-driven decisions to optimize 
                    performance and forecast future trends.
                  </li>
                </ul>
                <h4> Improved Compliance and Security:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    ERP solutions help ensure compliance with industry regulations and legal requirements.
                    They also provide security features such as data encryption.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    user access controls, and audit trails to safeguard sensitive business information.
                  </li>
                </ul>
                <h4>Customized ERP Solutions:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Our ERP platform solutions are tailored to fit the specific needs 
                    of your business, enabling seamless integration across various departments.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    We develop ERP systems that unify your business processes also.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <article>
        <div class="snippet">
        <section className="about-area about-style-three about-us-mobile">
            <div className="container">
              <div className="row align-items-center rev">
                <div className="about-content page-style ml-15">
                  <div className="text-center">
                  <span
                      className="top-title"
                      style={{ fontSize: "35px", paddingBottom: "20px", color: "#5B4B99"}}
                    >
                     ERP  <span style={{ color: " #F58634" }}>SOLUTIONS</span>
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content page-style ml-15">
                    <p
                      style={{
                        textAlign: "justify",
                        textIndent: "50px",
                        fontSize: "18px",
                      }}
                    >
                      
                      ERP (Enterprise Resource Planning) solutions are integrated software systems 
                      designed to streamline and automate business processes across various departments, 
                      such as finance, human resources, supply chain, and customer relationship management.
                       By centralizing data and workflows, ERP solutions provide a single source of truth 
                       for organizations, improving decision-making and operational efficiency.These systems 
                       help businesses manage and monitor day-to-day activities like inventory management, 
                       production planning, and order processing. ERP solutions are highly customizable and 
                       scalable, allowing companies to adapt the software to their unique needs as they grow.
                       These systems help businesses manage and monitor day-to-day activities like inventory 
                       management, production planning, and order processing. ERP solutions are highly customizable 
                       and scalable, allowing companies to adapt the software to their unique needs as they grow.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-img text-center">
                    <div className="videoWrapper">
                      <iframe
                        width="100%"
                        height="330"
                        src="https://www.youtube.com/embed/y0YoTKv6was?si=pDFaCVQ3__MR78oe"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MyChildComponent />
          </section>
        </div>
      </article>
    </div>
  );
};

export default ErpapplicationCorses;
