import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp icon

const WhatsAppButton = () => {
  return (
    <a
      href="https://wa.me/8096120173" // Replace with your WhatsApp number in international format
      target="_blank"
      rel="noopener noreferrer"
      style={styles.button}
    >
      <FaWhatsapp size={32} color="white" />
    </a>
  );
};

const styles = {
  button: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#25D366',
    borderRadius: '50%',
    padding: '15px',
    zIndex: 1000,
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
    cursor: 'pointer',
  },
};

export default WhatsAppButton;