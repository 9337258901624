import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios'; // You can use fetch as an alternative VARIOUS COURSES DATA ALSO NOT FETCHING IN THE DIFFERENT




const UserLoginPage = () => {
  const [isRegister, setIsRegister] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleShowRegister = () => setIsRegister(true);
  const handleShowLogin = () => setIsRegister(false);
  const handleForgotPassword = () => setShowForgotPassword(true);
  // const closeModal = () => setShowForgotPassword(false);
  const [showPassword, setShowPassword] = useState(false); // Step 1: State for password visibility and all the requiremts of the compiled system and all the refreshments 
  const navigate = useNavigate(); // Get the navigate function
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const togglePasswordVisibility = () => { // Step 2: Function to toggle password visibility
    setShowPassword(!showPassword);
  };



  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  }


  const openModal = () => setShowForgotPassword(true);
  const closeModal = () => {
    setShowForgotPassword(false);
    setMessage('');
    setEmail(''); // Clear email input when closing data and 
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };




// Create an instance of axios with the base URL
const apiClient = axios.create({
  baseURL: "https://iiaserver.iiautomate.com/IIA-Website-1.0.0/",
  headers: {
      "Content-Type": "application/json",
  },
});

// Then use this client in your function
const handleForgotPasswordSubmit = async (e) => {
  alert()
  e.preventDefault();
  try {
      const response = await apiClient.post("forgot/password", { identifier: email });
      
      if (response.status === 200) {
          setMessage("A password reset email has been sent to your email address.");
          setEmail("");
      } else {
          setMessage("Unexpected response from the server. Please try again later.");
      }
  } catch (error) {
      if (error.response) {
          setMessage("There was an error sending the email. Please try again later.");
          console.error("Server responded with:", error.response.data);
      } else if (error.request) {
          setMessage("No response from the server. Please check your connection and try again.");
          console.error("No response received:", error.request);
      } else {
          setMessage("Error: " + error.message);
          console.error("Error message:", error.message);
      }
  }
};

  // State to hold login form data
  const [loginData, setLoginData] = useState({
    identifier: "",
    password: "",
  });

  // Handle form field changes and update completed data various protocals having the different kind of things and all which not only participated 
  const handleLoginChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    // Make sure all required fields are filled
    if (!loginData.identifier || !loginData.password) {
      alert("Please enter both identifier and password.");
      return;
    }

    try {
      // API call to login
      const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/login/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      // Parse response
      const result = await response.json();

      if (response.ok) {
        // Handle successful login (e.g., redirect, store token)
        alert("Login successful!");

        // Assuming 'userId' and 'token' are in the response
        const userId = result.userId;
        const token = result.token;
        console.log(result.userId);
        console.log(result.token);

        // Store userId and token in localStorage
        localStorage.setItem("userId", userId);
        localStorage.setItem("userToken", token);

        // Redirect to enroll page
        navigate("/enroll");
      } else {
        // Handle login error
        alert(result.message || "Login failed, please try again.");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("Something went wrong, please try again later.");
    }
  };


  const [formData, setFormData] = useState({
    name: "",
    emailId: "",
    mobileNumber: "",
    password: "",
    gender: "",
    dateOfBirth: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Safely access the form fields
    const formData = {
      name: e.target.name?.value || "", // Default to an empty string if undefined
      emailId: e.target.emailId?.value || "",
      mobileNumber: e.target.mobileNumber?.value || "",
      password: e.target.password?.value || "",
      gender: e.target.gender?.value || "",
      dateOfBirth: e.target.dateOfBirth?.value || ""
    };

    try {
      const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/user/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const text = await response.text();
      try {
        const data = JSON.parse(text);
        console.log("Signup successful:", data);
      } catch (error) {
        console.error("Received non-JSON response:", text);
      }

      if (response.ok) {
        console.log("Registration successful");
        alert("Registration successful!"); // Alert for successful registration
        window.location.reload();
      } else {
        console.error("Signup failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };





  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <div style={{ maxWidth: "444px", margin: "0 auto", textAlign: "center" }}>
        <div style={{ padding: "20px", border: "1px solid #ccc", borderRadius: "10px", backgroundColor: "#f9f9f9" }}>
          {!isRegister ? (
            <form id="loginForm" onSubmit={handleLoginSubmit}>

              <h3>Login</h3>
              <div style={{ marginBottom: "15px", textAlign: "center" }}>
                <label>User Name</label>
                <input
                  name="identifier"
                  placeholder="Enter Email/Mobile Number"
                  type="text"
                  value={loginData.identifier}
                  onChange={handleLoginChange}
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    marginTop: "5px",
                  }}
                />
              </div>
              <div style={{ marginBottom: "15px", textAlign: "center" }}>
                <label>Password</label>
                <div style={{ position: "relative" }}>
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"} // Toggle input type
                    placeholder="Enter Password"
                    value={loginData.password}
                    onChange={handleLoginChange}
                    required
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      marginTop: "5px",
                    }}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility} // Toggle password visibility
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      color: "#007bff",
                    }}
                  >
                    {showPassword ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                        <path d="M16 8s-2.5-6-8-6-8 6-8 6 2.5 6 8 6 8-6 8-6zm-8 3.5a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7z" />
                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                        <path d="M8 3.5a4.5 4.5 0 0 0 0 9 4.5 4.5 0 0 0 0-9z" />
                        <path fillRule="evenodd" d="M8 2C4.5 2 1.5 4.2 0 8c1.5 3.8 4.5 6 8 6 3.5 0 6.5-2.2 8-6-1.5-3.8-4.5-6-8-6zm0 11a5.5 5.5 0 0 1-5.4-4.7 8.095 8.095 0 0 0 10.8 0A5.5 5.5 0 0 1 8 13.5z" />
                        <path fillRule="evenodd" d="M1.146 1.146a.5.5 0 0 1 .708 0l13.708 13.708a.5.5 0 0 1-.708.708L1.146 1.854a.5.5 0 0 1 0-.708z" />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
              <div style={{ textAlign: "right", marginBottom: "15px" }}>
                <a href="#" onClick={handleForgotPassword} style={{ fontSize: "12px", color: "#007bff", textDecoration: "none" }}>
                  Forgot password?
                </a>
              </div>
              <button
                type="submit"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  backgroundColor: "rgb(226 114 42)",
                  color: "#fff",
                  border: "none",
                  cursor: "pointer"
                }}
              >
                Login
              </button>
              <div style={{ marginTop: "10px" }}>
                <p>
                  Don't have an account?{" "}
                  <a href="#" onClick={handleShowRegister} style={{ color: "#007bff", textDecoration: "none" }}>
                    Signup
                  </a>
                </p>
              </div>
            </form>
          ) : (
            <form id="registerForm" onSubmit={handleSubmit}>
              <h3>Signup</h3>

              <div style={{ marginBottom: "15px", textAlign: "center" }}>
                <label>Full Name</label>
                <input
                  id="name"
                  name="name"
                  placeholder="Enter Full Name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    marginTop: "5px"
                  }}
                />
              </div>

              <div style={{ marginBottom: "15px", textAlign: "center" }}>
                <label>Mobile Number</label>
                <input
                  id="mobileNumber"
                  name="mobileNumber"
                  placeholder="Enter Mobile Number"
                  type="text"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    marginTop: "5px"
                  }}
                />
              </div>

              <div style={{ marginBottom: "15px", textAlign: "center" }}>
                <label>Email</label>
                <input
                  id="emailId"
                  name="emailId"
                  type="email"
                  placeholder="Enter Your Email"
                  value={formData.emailId}
                  onChange={handleChange}
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    marginTop: "5px"
                  }}
                />
              </div>

              <div style={{ marginBottom: "15px", textAlign: "center" }}>
                <label>Password</label>
                <div style={{ position: 'relative' }}>
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'} // Toggle input type
                    placeholder="Enter New Password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      marginTop: "5px"
                    }}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility} // Toggle password visibility
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      color: '#007bff',
                    }}
                  >
                    {showPassword ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                        <path d="M16 8s-2.5-6-8-6-8 6-8 6 2.5 6 8 6 8-6 8-6zm-8 3.5a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7z" />
                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                        <path d="M8 3.5a4.5 4.5 0 0 0 0 9 4.5 4.5 0 0 0 0-9z" />
                        <path fillRule="evenodd" d="M8 2C4.5 2 1.5 4.2 0 8c1.5 3.8 4.5 6 8 6 3.5 0 6.5-2.2 8-6-1.5-3.8-4.5-6-8-6zm0 11a5.5 5.5 0 0 1-5.4-4.7 8.095 8.095 0 0 0 10.8 0A5.5 5.5 0 0 1 8 13.5z" />
                        <path fillRule="evenodd" d="M1.146 1.146a.5.5 0 0 1 .708 0l13.708 13.708a.5.5 0 0 1-.708.708L1.146 1.854a.5.5 0 0 1 0-.708z" />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
              {/* Gender Dropdown */}
              <div style={{ marginBottom: "15px", textAlign: "center" }}>
                <label>Gender</label>
                <select
                  id="gender"
                  name="gender"
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    marginTop: "5px"
                  }}
                >
                  <option >Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div style={{ marginBottom: "15px", textAlign: "center" }}>
                <label>Date of Birth</label>
                <input
                  id="dateOfBirth"
                  name="dateOfBirth"
                  type="date"
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    marginTop: "5px"
                  }}
                />
              </div>

              <button
                type="submit"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  backgroundColor: "rgb(226 114 42)",
                  color: "#fff",
                  border: "none",
                  cursor: "pointer"
                }}
              >
                Sign Up
              </button>
              <div style={{ marginTop: "10px" }}>
                <p>
                  Already have an account?{" "}
                  <a href="#" onClick={handleShowLogin} style={{ color: "#007bff", textDecoration: "none" }}>
                    Login
                  </a>
                </p>
              </div>
            </form>
          )}
        </div>
      </div>

      {/* Forgot Password Modal data not founding in all the required fields of the procedure and all the eligible combination of the details also we are providing in */}
      {showForgotPassword && (
        <div style={{
          position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)",
          display: "flex", justifyContent: "center", alignItems: "center"
        }}>
          <div style={{
            width: "300px", backgroundColor: "#fff", padding: "20px", borderRadius: "10px", textAlign: "center"
          }}>
            <span
              onClick={closeModal}
              style={{
                position: "absolute", cursor: "pointer", fontSize: "18px", fontWeight: "bold", color: "#000", marginLeft: "90px", marginTop: "-17px", marginLeft: "118px"
              }}
            >
              &times;
            </span>
            <h2>Forgot Password</h2>
            <p style={{ marginBottom: "20px" }}>We will send you an email to reset your password.</p>
            {message && <p style={{ color: message.includes('error') ? 'red' : 'green' }}>{message}</p>}
            <form onSubmit={handleForgotPasswordSubmit}>
              <div style={{ marginBottom: "15px", textAlign: "left" }}>
                <label>Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email"
                  required
                  style={{
                    width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc", marginTop: "5px"
                  }}
                />
              </div>
              <button
                type="submit"
                style={{
                  width: "100%", padding: "10px", borderRadius: "5px", backgroundColor: "#28a745", color: "#fff",
                  border: "none", cursor: "pointer"
                }}
              >
                Send
              </button>
            </form>
          </div>
        </div>
      )}

    </div>


  );
};

export default UserLoginPage;
