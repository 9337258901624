import React from 'react'
import ErpapplicationCorses from "./ErpapplicationCorses";
import AllCourses from "./AllCourses";
import Partners from './Partners'
const Erpsolutions = () => {
  return (
    <div> 
    <ErpapplicationCorses></ErpapplicationCorses> 
    <AllCourses></AllCourses>
     <Partners></Partners>
     </div>
  )
}

export default Erpsolutions