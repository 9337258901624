
import React, { useState } from "react";

const CourseInfo = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
  
  // const [isVisible, setIsVisible] = useState(false);

  // const toggleVisibility = () => {
  //   setIsVisible(!isVisible);
  // };


  function MyChildComponent() {
    return 
  //   <div>   <div className="">
                
  //   <div className="">
  //     <section
  //       className="about-area about-style-three about-us-mobile"
  //       style={{ textAlign: "justify" }}
  //     >
  //       <div className="container">
  //         <div className="row align-items-center "  style={{ textAlign: "justify", paddingTop: "30px", paddingBottom:"10px"}}>
  //           <div
  //             className="about-content page-style ml-15  "
             
  //           >
  //             <div className="text-center">
  //               <span
  //                 style={{
  //                   fontSize: "30px",
  //                   color: "#5B4B99",
  //                   lineHeight: "1.6",
  //                 }}
  //               >
  //                  Industrial-Oriented Training
  //                  Key Features:
  //               </span>
  //             </div>
  //           </div>
  //           <div className="col-lg-6">
                  
  //                <div
  //               className="about-content page-style ml-15 bdstyle conheigh"
  //               style={{ textAlign: "justify" }}
  //             >
  //               <h4>Real-World Curriculum:</h4>
  //               <ul>
  //                 <li
  //                   style={{
  //                     textAlign: "justify",
                     
                      
  //                   }}
  //                 >
  //                   Dive into a curriculum crafted in collaboration
  //                   with industry experts. Our courses are tailored
  //                   to address the current challenges and demands of
  //                   the ever-evolving industrial landscape.
  //                 </li>
  //               </ul>
  //               <h4>Industry-Aligned Faculty:</h4>
  //               <ul>
  //                 <li
  //                   style={{
  //                     textAlign: "justify",
                     
                      
  //                   }}
  //                 >
  //                   Learn from seasoned professionals and educators
  //                   with extensive industry experience. Benefit from
  //                   their insights, practical wisdom, and guidance
  //                   as you navigate through the intricacies of your
  //                   chosen field.
  //                 </li>
  //               </ul>
  //               <h4>Hands-On Projects:</h4>
  //               <ul>
  //                 <li
  //                   style={{
  //                     textAlign: "justify",
                      
                      
  //                   }}
  //                 >
  //                   Apply theoretical knowledge to real-world
  //                   scenarios through hands-on projects. Develop a
  //                   portfolio that showcases your ability to solve
  //                   practical problems encountered in the industry.
  //                 </li>
  //               </ul>
  //               <h4 >
  //                 Simulation Labs:
  //               </h4>
  //               <ul>
  //                 <li
  //                   style={{
  //                     textAlign: "justify",
                    
  //                   }}
  //                 >
  //                   Immerse yourself in our state-of-the-art
  //                   simulation labs. Experience industry-relevant
  //                   scenarios that simulate actual workplace
  //                   conditions, providing you with a realistic
  //                   learning environment.
  //                 </li>
  //               </ul>
                
  //             </div>
  //           </div>
  //           <div className="col-lg-6">
  //           <div className="about-content page-style ml-15 bdstyle conheigh">  
  //               <h4>
  //                 Collaboration with Leading Companies:
  //               </h4>
  //               <ul>
  //                 <li
  //                   style={{
  //                     textAlign: "justify",
                     
  //                   }}
  //                 >
  //                   Our strong ties with industry leader’s open
  //                   doors to exclusive opportunities. Collaborate on
  //                   projects, attend workshops, and interact with
  //                   professionals who actively contribute to the
  //                   design and development of our courses.
  //                 </li>
  //               </ul>

  //               <h4 >
  //                 Internship Programs:
  //               </h4>
  //               <ul>
  //                 <li
  //                   style={{
  //                     textAlign: "justify",
                     
  //                   }}
  //                 >
  //                   Many of our industrial-oriented courses include
  //                   mandatory or optional internships. Gain valuable
  //                   on-the-job experience, make industry
  //                   connections, and increase your employability
  //                   upon graduation.
  //                 </li>
  //               </ul>
  //               <h4>Cutting-Edge Technologies:</h4>
  //               <ul>
  //                 <li
  //                   style={{
  //                     textAlign: "justify",
                     
                      
  //                   }}
  //                 >
  //                   Stay ahead of the curve by working with the
  //                   latest technologies relevant to your field. Our
  //                   courses integrate cutting-edge tools and
  //                   practices to ensure you are well-prepared for
  //                   the technological landscape of the industry.
  //                 </li>
  //               </ul>
  //               <h4>Industry Certification Preparation:</h4>
  //               <ul>
  //                 <li
  //                   style={{
  //                     textAlign: "justify",paddingBottom: "22px"
                     
  //                   }}
  //                 >
  //                   Prepare for industry-recognized certifications.
  //                   Our courses are structured to align with
  //                   certification requirements, giving you a
  //                   competitive edge in the job market.
  //                 </li>
  //               </ul>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
     
     
  //   </div>
  // </div></div>;
  }
  

  return (
    <div>
      <article>
        <div class="snippet">
          <section className="container-fluid" style={{background:"rgb(54, 48, 74)"}}>
            <div className="container">
              <div className="about-content page-style ml-15">
                {/* <h1
                  style={{
                    color: "#544395",
                    fontSize: "30px",
                    lineHeight: "1.8",
                  }}
                >
                  INDUSTRIAL ORIENTED TRAINING
                </h1> */}
                   <h1 className="heading-names" style={{ color: "rgb(255, 255, 255)", fontSize: "40px", lineHeight: "1.3",paddingTop:"15px" }}>
                   INDUSTRIAL ORIENTED <span style={{ color: "#F58634" }}>TRAINING</span>
                </h1>
              </div>
              <div className="row align-items-center rev">
                <div className="col-lg-6">
                  <div className="about-content page-style ml-15">
                    <p className="paradecoration" style={{    color: '#c8c7c7'}}>
                      {" "}
                      Now a days in the current industries Employers are seeking
                      applicants with industry-ready skills in the present job
                      environment. Unlock your potential and bridge the gap
                      between academia and industry with our specialized
                      Industrial-Oriented Training Courses. Our programs are
                      meticulously designed to equip you with the hands-on
                      skills, practical knowledge, and industry insights needed
                      to thrive in today's dynamic work environment. It aims to
                      prepare individuals for a successful and seamless
                      transition into an industrial career, make them valuable
                      assets to their future employers and contribute to the
                      growth and productivity of the industry.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-img text-center">
                  <div className="videoWrapper">
              <iframe
                width="100%"
                height="300"
                src="https://www.youtube.com/embed/y0YoTKv6was?si=pDFaCVQ3__MR78oe"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
                    {/* <img src={courseoffered} alt="Image" /> */}
                  </div>
                </div>
              </div>

           
              {/* <button class="custom-btn btn-7 "  ><span>Read More</span></button> */}
            </div>



            {/* <div style={{paddingBottom:"10px"}}>
              <button className="showbut" onClick={toggleVisibility}>
                Know {isVisible ? "Less" : "More"}
              </button>
              {isVisible && <MyChildComponent />}
            </div> */}

          </section>
        </div>
      </article>
    </div>
  );
};

export default CourseInfo;
