import React from "react";
// import about1 from "../img/section-images/about-us1.jpg";

const AboutUsBrief = () => {
  return (
    <div>
    <section className="about-area about-style-three about-us-mobile " style={{paddingTop:"40px",background:'rgb(54, 48, 74)'}} >
        <div className="container">
          <div className="row align-items-center">
            <div className="about-content ml-15">

            <h1 style={{ color: "#524DA1", fontSize: "40px", lineHeight: "1.3", color: "#fff"}}>
                  ABOUT <span style={{ color: "#F58634" }}>US</span>
                </h1>
                  {/* <div className="text-center">
                    <span style={{color:'#544395',fontSize: "30px",lineHeight:'1.8'}}>ABOUT <span style={{ color: " #F58634" }}>US</span> </span>
                  </div> */}
            </div>
            <div className="col-lg-6">
             
             
           
           
              <div className="about-content ml-15">
                {/* <div className="text-center">
                  <span style={{color:'#544395',fontSize: "30px",lineHeight:'1.8'}}>ABOUT US</span>
                </div> */}
                
  
                <p style={{ textAlign: 'justify',fontSize: '18px',textIndent: '50px',lineHeight:'1.4',color: '#c8c7c7'}}>
                  Established in 2019 with a Mission & vision of bringing
                  Employability Enhancement Education to the India at affordable
                  prices. To transform aspiring individuals into industry-ready
                  professionals, IIA is designed to be dynamic, crafted
                  Industrial-Oriented Training curriculum that reflecting the
                  latest industry trends and technological advancements. Our
                  courses are tailored to meet the evolving needs of the global
                  workforce. With Industry experts Live Techno-Talks brings the
                  latest technology direct to the students to enrich them with
                  the required know-how of the trends in technology in various
                  domains like Academics up gradation, career up gradation, job
                  oriented enhancement skills as a part of our integral
                  curriculum. IIA increases the chances of its students landing
                  in their dream jobs by choice not by chance and excelling in
                  their professional endeavors.
                </p>
              </div>
            
            </div>
            <div className="col-lg-6">
             
            <div className="about-img text-center">
                {/* <img src={about1} alt="Image" /> */}
                <iframe
                width="100%"
                height="300"
                src="https://www.youtube.com/embed/y0YoTKv6was?si=pDFaCVQ3__MR78oe"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
              </div>
              
            
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUsBrief;
