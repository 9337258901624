import React, { useState,useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import Contacts from "./Contacts";
import OnlineRoboticsCourseForm from "./OnlineRoboticsCourseForm"
import UserLoginPage from "./UserLoginPage"

const LoginPage = () => {
 
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
  return (
    <div>
     <br></br><br></br>
     <UserLoginPage />
<br></br><br></br>
    </div>
  )
}

export default LoginPage