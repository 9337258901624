import React from "react";
import logo1 from "../img/Partner_logo/eis-logo.png";
import logo2 from "../img/Partner_logo/logo2.jpg";
import logo3 from "../img/Partner_logo/logo4.png";
import logo4 from "../img/Partner_logo/logo5.png";
import logo5 from "../img/Partner_logo/logo6.png";
import logo6 from "../img/Partner_logo/logo7.png";
import logo7 from "../img/Partner_logo/logo8.png";
import Slider from "react-slick";
const Partners = () => {
  var logoSliders = {
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    infinite: true,
    arrows: true,
    dots: false,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <div>
      <div className="partner-area ptb-100">
        <div className="container">
          <div className="partner-bg style-two  ">
            <span
              style={{
                fontWeight: "700px",
                color: "#5B4B99",
                paddingTop: "60px!important",
              }}
            >
              OUR <span style={{ color: " #F58634" }}>CLIENTELE</span>
            </span>

            <div className="logo-sliders">
              <Slider {...logoSliders}>
                <div className="single-partner">
                  <img src={logo1} alt="Image" />
                </div>
                <div className="single-partner">
                  <img src={logo2} alt="Image" />
                </div>
                <div className="single-partner">
                  <img src={logo3} alt="Image"/>
                </div>
                <div className="single-partner">
                  <img src={logo4} alt="Image"  />
                </div>
                <div className="single-partner">
                  <img src={logo5} alt="Image" />
                </div>
                <div className="single-partner">
                  <img src={logo6} alt="Image"  />
                </div>
                <div className="single-partner">
                  <img src={logo7} alt="Image" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
