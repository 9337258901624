import React from 'react'
import technotalk from '../img/section-images/technotalks.jpg'
const Placementshome = () => {
  return (
    <div> 
        
        <section className="about-area about-style-three about-us-mobile " style={{background:"rgb(54, 48, 74)"}}>
            <div className="container">
              <div className="row align-items-center rev">
                <div className="about-content page-style ml-15">
                  <div className="text-center">
                  <h1 style={{ color: "rgb(245, 134, 52)", fontSize: "40px", lineHeight: "1.3",paddingTop:"15px" }}>
                  PLACEMENTS
                </h1>
                
                    {/* <span
                      className="top-title"
                      style={{
                        color: "#544395",
                        fontSize: "30px",
                        lineHeight: "1.8",
                      }}
                    >
                      PLACEMENTS
                    </span> */}

                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content page-style ml-15">
                    <p
                      style={{
                        textAlign: "justify",
                        fontSize: "18px",
                        textIndent: "50px",
                        color: '#c8c7c7'
                      }}
                    >
                      Career Support and Placement Services
                      We go beyond teaching by ensuring our students transition smoothly into successful careers. Our job-oriented training programs and extensive industry network help graduates secure jobs promptly after completing their training.
                    </p>
                    <p
                      style={{
                        textAlign: "justify",
                        fontSize: "18px",
                        textIndent: "50px",
                          color: '#c8c7c7'
                      }}
                    >
                    With a placement success rate of 84% and over 200 students placed, we excel in providing career opportunities. We organize both off-campus and on-campus placement drives, partnering with leading companies to connect students with top employers.
                    </p>
                    {/* <p
                      style={{
                        textAlign: "justify",
                        fontSize: "18px",
                        textIndent: "50px",
                      }}
                    >
                      We organize the Off-Campus Placements to invite leading
                      companies and colleges to conduct interviews and select
                      candidates directly at our institute. We bring leading
                      companies to conduct On-campus placement drives at college
                      premises and choose candidates respectively.
                    </p> */}
                  </div>
                </div>
               
                <div className="col-lg-6">
                  <div className="about-img text-center">
                  <div className="vedio-heading">
                    <div className="videoWrapper">
                      <iframe
                        width="100%"
                        height="300"
                        src="https://www.youtube.com/embed/y0YoTKv6was?si=pDFaCVQ3__MR78oe"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                    </div>
                    {/* <img src={placement} alt="Image" /> */}
                  </div>
                </div>
              </div>
            </div>
          
          </section>
  </div>
  )
}
export default Placementshome