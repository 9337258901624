import React, { useState, useEffect } from "react";
import robotics from "../img/section-images/robotics.jpg";

import { Link } from "react-router-dom";
const WebapplicationsCorses = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });


  // api integration 
  const [onlinerobotics, setOnlinerobotics] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/OnlineRobotics/get');
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setOnlinerobotics(result);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  //  if (isLoading) {
  //    return <div>Loading...</div>;
  //  }

  //  if (error) {
  //    return <div>Error: {error.message}</div>;
  //  }

  function MyChildComponent() {
    return (
      <div>
        <div class="" style={{ paddingTop: "10px" }}>
          <div class="">
            <section
              className="about-area about-style-three about-us-mobile "
              style={{ textAlign: "justify" }}
            >
              <div className="container">
                <div className="row align-items-center ">
                  <div className="about-content page-style ml-15"></div>
                  <div className="col-lg-6">


<div className="about-content page-style ml-15">
<div className="text-center" style={{marginTop: "-40px"}}>
<span
className="top-title"
style={{
  fontSize: "35px",
  paddingBottom: "15px",
}}
>
Key Features
</span>
</div>
</div>
  <ul style={{ textAlign: "justify" }}>
    <li>User Experience Design Creating intuitive and engaging interfaces for mobile users.</li>
    <li>Native App Development Developing platform-specific apps to leverage native.</li>
    <li>Mobile Security Implementing robust security measures to protect user data.</li>
    <li>App Store Optimization (ASO) Enhancing app visibility and downloads.</li>
    <li>Offline Functionality Designing apps that perform efficiently without constant.</li>
    <li>Push Notifications Engaging users with timely and relevant updates directly on their devices.</li>
  </ul>
  {/* <div className="about-img text-center">
    <img src={robotics} alt="Image" />
  </div> */}
</div>

                  <div
                    className="col-lg-6"
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      lineHeight: "2.6 ",
                    }}
                    >
                    <div className="text-center">
                      <span
                        className="top-title"
                        style={{
                          fontSize: "35px",
                          paddingBottom: "20px",
                        }}
                      >
                        Course Information
                      </span>
                    </div>

                    {onlinerobotics && (
                      <table>
                        <tbody>
                          <tr>
                            <td>Course Duration</td>
                            <td>{onlinerobotics.courseDuration}</td>
                          </tr>
                          {/* <tr>
               <td>Date</td><td>{data.date}</td>
               </tr> */}
                          <tr>
                            <td>Mode</td>
                            <td>{onlinerobotics.mode}</td>
                          </tr>
                          <tr>
                            <td>Time</td>
                            <td>{onlinerobotics.timing}</td>
                          </tr>

                          <tr>
                            <td>Price</td>
                            <td>{onlinerobotics.price}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}

<Link to="/contact-us?category=mechanical-engineering">
                  <button className="custom-btn btn-3">
                    <span>Register Now</span>
                  </button>
                </Link>
                  </div>
                </div>
              </div>
            </section>
            {/*   <section
        className="about-area about-style-three about-us-mobile"
        style={{ textAlign: "justify" }}
      >
        <div className="container">
          <div className="row align-items-center " style={{paddingBottom:"10px"}}>
            <div
              className="about-content page-style ml-15  "
              style={{ textAlign: "justify", fontSize: "18px" }}
            >
              <div className="text-center">
                <span
                  style={{
                    fontSize: "30px",
                    color: "#5B4B99",
                   
                  }}
                >
                  Key Features:
                </span>
              </div>
            </div>
            <div className="col-lg-6">
            <div
                className="about-content page-style ml-15 bdstyle conheigh"
                style={{ textAlign: "justify" }}
              >
                <h4>Mobile App Development Platforms:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                     
                    }}
                  >
                    Mobile applications are categorized into native, web, and hybrid apps. 
                    Native apps are developed for a specific platform (iOS, Android).
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                     
                    }}
                  >
                    Web apps are accessed through browsers, and hybrid apps combine both approaches 
                    using web technologies wrapped in a native container. 
                    
                  </li>
                </ul>
                <h4>User Experience (UX) and Interface Design:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Mobile apps focus heavily on UX and UI design to create intuitive, responsive, 
                    and visually appealing interfaces.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    {" "}
                    A seamless user experience is key for retention and satisfaction, emphasizing 
                    ease of navigation and accessibility.
                  </li>
                </ul>
                <h4> Cross-Platform Development:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                     Cross-platform development frameworks like Flutter, React
                      Native, and Xamarin allow developers to build mobile apps 
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Multiple platforms using a single codebase, saving time.
                  </li>
                </ul>
                <h4> Custom Mobile App Development:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    We specialize in building tailor-made mobile 
                    applications designed to meet the unique needs 
                    of your business.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                
                    }}
                  >
                    Our team of experts delivers feature-rich, user-friendly 
                    applications that are visually appealing and highly functional. 
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
            <div className="about-content page-style ml-15 bdstyle conheigh"  style={{paddingBottom:"185px"}}>
                <div className="text-center"></div>
                <h4>Mobile App Security:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Security is a top priority in mobile app development, involving encryption, 
                    secure data storage, and strong user authentication methods.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Mobile apps must adhere to data privacy regulations like GDPR and CCPA 
                    to protect sensitive user data.
                  </li>
                </ul>
                <h4>Integration with Device Features:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Mobile apps can access and integrate device-specific features such as GPS, camera, 
                    accelerometer, and push notifications. 
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                   This enables apps to offer personalized services.
                  </li>
                </ul>
                <h4>Monetization Strategies:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Mobile apps can be monetized through various methods, including in-app purchases, 
                    subscriptions, advertisements.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    The choice of monetization strategy depends on the app's.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <article>
        <div class="snippet">
        <section className="about-area about-style-three about-us-mobile">
            <div className="container">
              <div className="row align-items-center rev">
                <div className="about-content page-style ml-15">
                  <div className="text-center">
                  <span
                      className="top-title"
                      style={{ fontSize: "35px", paddingBottom: "20px", color: "#5B4B99"}}
                    >
                     MOBILE  <span style={{ color: " #F58634" }}>APPLICATION</span>
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content page-style ml-15">
                    <p
                      style={{
                        textAlign: "justify",
                        textIndent: "50px",
                        fontSize: "18px",
                      }}
                    >
                    
                    A mobile application, or app, is a software program designed to run on smartphones, tablets, 
                      and other mobile devices. These apps can be either native, developed specifically for a 
                      particular platform like Android or iOS, or cross-platform, built using frameworks like React 
                      Native or Flutter. Mobile apps provide users with a wide range of functionalities, from communication 
                      tools and gaming to productivity and entertainment.With increasing mobile usage, apps are integral to 
                      everyday activities, allowing users to access services and information instantly. Developers often 
                      prioritize responsive design, user-friendly interfaces, and offline capabilities to enhance the user 
                      experience. Mobile apps can also leverage device features like GPS, cameras, and sensors for enhanced 
                      functionality.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-img text-center">
                    <div className="videoWrapper">
                      <iframe
                        width="100%"
                        height="330"
                        src="https://www.youtube.com/embed/y0YoTKv6was?si=pDFaCVQ3__MR78oe"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MyChildComponent />
          </section>
        </div>
      </article>
    </div>
  );
};

export default WebapplicationsCorses;
