import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import picture1 from "../img/logo/Picture1.jpg";
import picture2 from "../img/logo/Picture2.png";

const OurCoPartners = () => {
  return (
    <div>
      <div style={{background:"rgb(19 9 230)"}}>
        <div
          className="section-full  content-inner-1 what-we-do overlay-black-dark bg-img-fix"
          id="services" 
        >
          <div className="container">
            <div className="section-head text-center text-white">
            <h1 style={{ fontSize: "40px", lineHeight: "1.3" }}>
                  OUR <span style={{ color: "#F58634" }}>PARTNERS</span>
                </h1>
              {/* <h2 className="text-uppercase" style={{ color: "#544395", }}>Our <span style={{ color: " #F58634" }}>Partners</span></h2> */}
              {/* <div className="dlab-separator bg-primary"></div> */}
            </div>
            <div className="section-content">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 ow-event-post" >
                    <div className="ow-event-info conheight " style={{    backgroundColor: "rgb(54 48 74)"}}>
                      <div
                        style={{ paddingBottom: "30px", textAlign: "center" }}
                      >
                        <img
                          src={picture1}
                          alt=""
                          style={{ width: "350px", height: "100px" }}
                        />
                      </div>
                      {/* <div className="ow-event-text">
                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            textIndent: "50px",
                          }}
                        >
                         Envision Integrated Services Pvt Ltd
                         With over 11 years of experience, Envision Integrated Services excels in transforming innovative ideas into reality through specialized engineering services. We handle projects from conceptualization to implementation in Mechanical Engineering.
                        </p>
                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            textIndent: "50px",
                          }}
                        >
                          Our expert team delivers comprehensive solutions in engineering design, industrial automation, and digital facilities, understanding industry dynamics to approach each project effectively.
                        </p>
                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            textIndent: "50px",
                          }}
                        >
                         As a market leader, Envision combines advanced technology with modern management practices to achieve solution-oriented and timely results for our clients.
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-6 ow-event-post" >
                    <div className="ow-event-info conheight " style={{    backgroundColor: "rgb(54 48 74)"}}>
                      <div
                        style={{ paddingBottom: "30px", textAlign: "center" }}
                      >
                        <img
                          src={picture2}
                          alt=""
                          style={{ width: "350px", height: "100px" }}
                        />
                      </div>
                      {/* <div className="ow-event-text">
                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            textIndent: "50px",
                          }}
                        >
                          I Robots Innovative Solutions
                          Established in 2017, I Robots Innovative Solutions specializes in Industrial Automation with a focus on robotic applications. Our expertise includes Robotic Laser Cutting, MIG and Spot Welding, Material Handling, Machine Tending, and more.
                        </p>
                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            textIndent: "50px",
                          }}
                        >
                         Our success stems from extensive research and tailored solutions, earning us partnerships with prominent clients in India and internationally, including OEMs and Tier 1 Suppliers.
                        </p>
                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            textIndent: "50px",
                          }}
                        >
                          In addition to robotic solutions, we offer engineering services such as Design Consultancy, Simulation, PLC Programming, and Integration, both onsite and offsite.
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurCoPartners;
