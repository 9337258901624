import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const RecruiterForm = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    const [formData, setFormData] = useState({
        fullName: '',
        companyName: '',
        companyLocation: '',
        emailId: '',
        contactNumber: '',
        comment: '',
        jobDescription: '',
    });

    const [submitStatus, setSubmitStatus] = useState(null);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        if (type === 'file') {
            const reader = new FileReader();

            reader.onload = () => {
                // Remove data URI prefix
                const base64StringFromFrontend = reader.result;
                const base64StringToSend = base64StringFromFrontend.substring("data:image/png;base64,".length);
                setFormData(prevState => ({
                    ...prevState,
                    [name]: base64StringToSend
                }));
            };

            reader.onerror = (error) => {
                console.error('Error reading file:', error);
            };

            // Read the file asynchronously
            reader.readAsDataURL(files[0]);
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/placement/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => {
            if (response.ok) {
                setSubmitStatus('success');
                window.alert('Form submitted successfully Our Team Will Reach You Soon....!');
            } else {
                setSubmitStatus('fail');
                window.alert('Failed to submit form. Please try again later.');
            }
        })
        .catch(error => {
            console.error('Error submitting form:', error);
            setSubmitStatus('fail');
        });
    };

    const handleClosePopup = () => {
        setSubmitStatus(null);
    };

    return (
        <div>
            <section style={{ paddingTop: "30px" }}>
                <div className="container-fluid">
                    <div className="container  boderform" style={{ backgroundColor: "#efefef", paddingBottom: "30px", borderRadius: "10px" }}>
                        <h2 style={{ textAlign: "center", color: "#5B4B99", paddingBottom: "50px", paddingTop: "30px" }}>Recruiter Form</h2>
                        <form onSubmit={handleSubmit} className="donate-form">
                            <div className="row" style={{ paddingBottom: "25px" }}>
                                <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Full Name*</Form.Label>
                                    <Form.Control type="text" name="fullName" value={formData.fullName} onChange={handleChange} placeholder="Enter Name" required />
                                </Form.Group>
                                <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Company Name*</Form.Label>
                                    <Form.Control type="text" name="companyName" value={formData.companyName} onChange={handleChange} placeholder="Enter Name" required />
                                </Form.Group>
                                <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Company Location*</Form.Label>
                                    <Form.Select aria-label="Default select example " name="companyLocation" value={formData.companyLocation} onChange={handleChange} required>
                                        <option>Enter Your Company Location</option>
                                        <option value="Hyderabad">Hyderabad</option>
                                        <option value="Bengaluru">Bengaluru</option>
                                        <option value="Pune">Pune</option>
                                        <option value="Mumbai">Mumbai</option>
                                        <option value="Chennai">Chennai</option>
                                        <option value="Delhi">Delhi</option>
                                        <option value="Kolkata">Kolkata</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email-Id*</Form.Label>
                                    <Form.Control type="email" name="emailId" value={formData.emailId} onChange={handleChange} placeholder="Enter Your Email" required />
                                </Form.Group>
                            </div>
                            <div className="row" style={{ paddingBottom: "25px" }}>
                                <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Contact No*</Form.Label>
                                    <Form.Control type="text" name="contactNumber" value={formData.contactNumber} onChange={handleChange} placeholder="Phone Number" required />
                                </Form.Group>
                                <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Required Job Description*</Form.Label>
                                    <Form.Control type="file" name="jobDescription" onChange={handleChange} required />
                                </Form.Group>
                                <div className="col-md-3">
                                    <Form.Label>Comment*</Form.Label>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <textarea type="textarea" name="comment" value={formData.comment} onChange={handleChange} rows="4" cols="40" placeholder="Enter Your Requirement" required />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row" style={{ paddingBottom: "25px" }}></div>
                            <div style={{ textAlign: "center" }}>
                                <button className="custom-btn btn-3">
                                    <span>Submit</span>
                                </button>
                            </div>
                            {submitStatus === 'success' && (
                                <div className="popup success">
                                    <p>Form submitted successfully Our team will contact You Soon!!</p>
                                    <button onClick={handleClosePopup}>Close</button>
                                </div>
                            )}
                            {submitStatus === 'fail' && (
                                <div className="popup fail">
                                    <p>Failed to submit form. Please try again later.</p>
                                    <button onClick={handleClosePopup}>Close</button>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default RecruiterForm;