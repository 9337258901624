import React from "react";
import Slider from "./Slider";
import University from "./University";
import Stats from "./Stats";
import Builders from "./Builders";
import Partners from "./Partners";
import IaaBrief from "./IiaBrief";
import CollegeSeminors from "./CollegeSeminors";
import ChooseUs from "./ChooseUs";
import PlacedCandidates from "./PlacedCandidates";
import UniversitiesAlignWithUs from "./UniversitiesAlignWithUs";
import BusinessPartners from "./BusinessPartners";
import AboutUsBrief from "./AboutUsBrief";
import CourseInfo from "./CourseInfo";
import AllCourses from "./AllCourses";
import TechnoTalksBriefExp from "./TechnoTalksBriefExp";
import Placementshome from "./Placementshome";
import AllcoursesSlider from "./AllcoursesSlider";
// import DommyHomeSlider from "./DommyHomeSlider";


const Home = () => {
  {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <div>
      <div>


        {/* <DommyHomeSlider /> */}
        <AllcoursesSlider />
       
        <Stats />
        <IaaBrief /> 
        {/* <AboutUsBrief/> */}
        <ChooseUs/>
        <CourseInfo/>
        <AllCourses/>
        {/* <University /> */}
        <TechnoTalksBriefExp/>
        <CollegeSeminors/>
        <Placementshome />
        <PlacedCandidates/>
      
      
        {/* <Builders /> */}
        {/* <BusinessPartners/>
        <UniversitiesAlignWithUs/> */}
        {/* <Partners /> */}
    
      </div>
    </div>
  );
};

export default Home;
