import React, { useState, useEffect, useRef } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import { Tab, Tabs } from "react-bootstrap";
import Contacts from "./Contacts";
import logo from "../img/logo/logo.png";
import UserLoginPage from "./UserLoginPage";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from 'axios'; // Or you can use fetch
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ReactJsForm = () => {
  const fieldSelectRef = useRef(null);
  const [key, setKey] = useState('registration'); // Managing active tab
  const [offlinerobotics, setOfflinerobotics] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [isPunchedIn, setIsPunchedIn] = useState(false);
  const [punchInTime, setPunchInTime] = useState(null);
  const [totalSeconds, setTotalSeconds] = useState(0); // Store total seconds to calculate hours
  const navigate = useNavigate(); // Initialize useNavigate
  // const [formData, setFormData] = useState({
  //   fullName: "",
  //   gender: "",
  //   dateOfBirth: "",
  //   emailId: "",
  //   contactNumber: "",
  //   course: "",
  //   timings: "",
  //   mode: "",
  //   courseDuration: "",
  //   categories: "",
  //   coursePrice: "",
  //   councillorName: "",
  // });

  const [toolbarfixture, setToolbarfixture] = useState([]); // Initialize as an empty array

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [availableSubFields, setAvailableSubFields] = useState([]);
  const [courses, setCourses] = useState([]); // State for course options
  const [courseduration, setCoursess] = useState([]); // State for course options
  const [isSubmitClicked, setIsSubmitClicked] = useState(false); // To enable/disable Pay Now button
  const [isTermsAccepted1, setIsTermsAccepted1] = useState(false);
  const [isTermsAccepted2, setIsTermsAccepted2] = useState(false);
  const [step, setStep] = useState(1);
  const [nestedKey, setNestedKey] = useState('step1');
  const [subNestedKey, setSubNestedKey] = useState('subStep1'); // For second-level sub-tabs
  const [subSubNestedKey, setSubSubNestedKey] = useState('subSubStep1'); // For third-level sub-tabs
  const [subSubSubStepKey, setSubSubSubStepKey] = useState('detail1'); // Add this line for subSubSubStepKey
  const [optionss, setOptionss] = useState([]);



  const [userId, setUserId] = useState(''); // State for userId input

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    console.log(storedUserId)
    if (storedUserId) {
      setUserId(storedUserId); // Set the userId from localStorage
      console.log("User ID:", userId);
    }

    const storedTab = localStorage.getItem('activeSubTab'); // Get the stored tab
    if (storedTab) {
      setSubNestedKey(storedTab); // Set the tab to the stored value after reload
      localStorage.removeItem('activeSubTab'); // Clean up localStorage
    }
  }, []);

  const [formData, setFormData] = useState({
    source: '',
    sourceName: '',
    courses: '',
    courseDuration: '',
    timings: '',
    mode: '',
    branches: '',
    coursePrice: '1000' // Include coursePrice if it's part of the form
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUserIdChange = (e) => {
    setUserId(e.target.value); // Update userId state with the input value
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form from reloading the page

    // Log the userId to check its value before submission
    console.log("User ID:", userId);

    // Prepare post data
    const postData = {
      source: formData.source,
      sourceName: formData.sourceName,
      courses: formData.courses.replace(/\s/g, ''), // Remove spaces if needed
      courseDuration: formData.courseDuration,
      timings: formData.timings,
      mode: formData.mode,
      branches: formData.branches,
      coursePrice: formData.coursePrice, // Include coursePrice in postData
    };

    // Log post data before making the API call
    console.log("Post Data:", JSON.stringify(postData, null, 2));

    // Check for missing required fields


    // Proceed with Razorpay Payment fetching of the data also not an easy task to fetch the data in different kind of the positions of the data and all
    try {
      // Razorpay options
      const options = {
        key: "rzp_test_rA2UalGsJwTy9v", // Your Razorpay Key ID
        amount: postData.coursePrice * 100, // Amount in paise (multiply by 100 for INR)
        currency: "INR",
        payment_capture: true,
        name: "International Institute Of Automate",
        description: "Course Enrollment Payment",
        handler: async (response) => {
          // Payment successful, proceed to submit form data to your backend  
          console.log("Payment Success:", response);

          try {
            const apiResponse = await fetch(
              `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/course/addcourse/${userId}`, // Ensure userId is appended correctly
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
              }
            );

            if (!apiResponse.ok) {
              // Log the response for debugging
              const errorData = await apiResponse.text(); // Get response body as text
              throw new Error(`HTTP error! Status: ${apiResponse.status}, Response: ${errorData}`);
            }

            const data = await apiResponse.json();
            console.log('API Response:', data);
            alert('Data added successfully!'); // Success alert
            navigate("/enroll");
          } catch (error) {
            console.error('Error submitting form:', error);
            alert('Error submitting form: ' + error.message);
          }
        },
        prefill: {
          name: formData.fullName,
          email: formData.emailId,
          contact: formData.contactNumber,
        },
        notes: {
          address: "International Institute Of Automate",
        },
        theme: {
          color: "#3399cc",
        },
      };

      // Initialize Razorpay payment modal
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error('Error with Razorpay:', error);
      alert('Error with Razorpay: ' + error.message);
    }
  };





  // Function to handle API call
  const fetchOptionss = async () => {
    try {
      const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/sources'); // Replace with your API endpoint
      const data = await response.json();
      setOptionss(data); // Assuming the response is an array like ["SocialMedia", "Councillor", "Reference"]
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Call API when component mounts
  fetchOptionss();





  useEffect(() => {
    const fetchDatas = async () => {
      const storedUserId = localStorage.getItem('userId');
      setIsLoading(true);

      try {
        const controller = new AbortController();
        const timeout = setTimeout(() => controller.abort(), 10000); // Set timeout for 10 seconds

        const response = await fetch(
          `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/user/getprofile/${storedUserId}`,
          { signal: controller.signal }  // Pass the abort signal to the fetch request
        );

        clearTimeout(timeout); // Clear timeout if fetch completes

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();
        setOfflinerobotics(result);
      } catch (error) {
        if (error.name === 'AbortError') {
          setError('The request took too long and was aborted.');
        } else {
          setError(error);
        }
      }
      setIsLoading(false);
    };

    fetchDatas();
  }, []);




  // const fetchCourses = async (category) => {
  //   try {
  //     const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/category?category=${category}`);
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch courses");
  //     }
  //     const result = await response.json();
  //     setCourses(result); 
  //   } catch (error) {
  //     setError(error);
  //   }
  // };


  const fetchTime = async (time) => {
    try {
      const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/timings?courseDuration=${time}`);
      if (!response.ok) {
        throw new Error("Failed to fetch timings");
      }
      const result = await response.json();

      // Map the response to a more usable format
      const subFieldOptions = result.map((item) => ({
        value: item,  // Keep the original value
        label: item.replace(/_/g, ' ').substring(1), // Replace underscores and remove leading underscore
      }));

      setAvailableSubFields(subFieldOptions);
    } catch (error) {
      setError(error);
    }
  };


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const fetchCourseDuration = async () => {
      try {
        const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/courseduration"); // Replace with your courses API endpoint
        if (!response.ok) {
          throw new Error("Failed to fetch courses");
        }
        const result = await response.json();
        setCoursess(result); // Assuming result is an array of course objects
      } catch (error) {
        setError(error);
      }
    };

    fetchCourseDuration();

    // Initial fetch for default course price (if needed)
    // const fetchData = async () => {
    //   setIsLoading(true);
    //   try {
    //     const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/amount?course=Java");
    //     if (!response.ok) {
    //       throw new Error("Failed to fetch data");
    //     }
    //     const result = await response.json();
    //     setOfflinerobotics(result);
    //     setFormData((prev) => ({ ...prev, coursePrice: result }));
    //   } catch (error) {
    //     setError(error);
    //   }
    //   setIsLoading(false);
    // };

    // fetchData();
  }, []);






  const handleChange1 = (e) => {
    setFormData({ ...formData, mode: e.target.value });
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   // Handle category selection
  //   if (name === "categories") {
  //     fetchCourses(value); 
  //   }


  //   setFormData({ ...formData, [name]: value });
  // };

  // Handle main field dropdown change
  const handleFieldChange = async (e) => {
    const { name, value } = e.target;

    if (name === "courseDuration") {
      // Update the formData with the selected course duration
      setFormData({ ...formData, courseDuration: value });

      console.log("Fetching timings for duration:", value);

      await fetchTime(value); // Fetch timings based on selected course duration
    } else {
      const selectedField = value;
      let subFields = [];



      setAvailableSubFields(subFields);
      setFormData({ ...formData, course: selectedField });


      try {
        const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/amount?course=${selectedField}`);
        if (!response.ok) {
          throw new Error("Failed to fetch price data");
        }
        const result = await response.json();
        setOfflinerobotics(result);
        setFormData((prev) => ({ ...prev, coursePrice: result })); // Update price in formData
      } catch (error) {
        setError(error);
      }
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();



  //   const options = {
  //     key: "rzp_test_rA2UalGsJwTy9v",
  //     key_secret: "RG7A51JvDSuFfhUzkGVbpAtn",
  //     amount: formData.price * 100, // Use price from formData
  //     currency: "INR",
  //     captured: true,
  //     payment_capture: true,
  //     name: "International Institute Of Automate",
  //     handler: function (response) {
  //       alert("Thanks for your interest \n Your Payment has been successfully completed");
  //       window.location.href = `/online-preview-form`;
  //     },
  //     prefill: {
  //       name: formData.fullName,
  //       email: formData.emailId,
  //       contact: formData.contactNumber,
  //     },
  //     notes: {
  //       address: "International Institute Of Automate",
  //     },
  //     theme: {
  //       color: "#3399cc",
  //     },
  //   };

  //   const pay = new window.Razorpay(options);
  //   pay.open();

  //   try {
  //     const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/registercandidate", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         ...formData,
  //       }),
  //     });
  //     const responseData = await response.json();
  //     console.log(formData);
  //     console.log(responseData)
  //     localStorage.setItem("responseData", responseData.candidateId);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };



  const handleTermsClick = (e) => {
    e.preventDefault();
    if (isTermsAccepted) {
      setShowModal(true);
    } else {
      alert("Please accept the Terms and Conditions to proceed.");
    }
  };



  const handleModalSubmit = () => {
    setIsSubmitClicked(true); // Enable Pay Now button
    setShowModal(false); // Hide modal
  };
  const handleCheckboxClick = () => {
    const newValue = !isTermsAccepted;
    setIsTermsAccepted(newValue);


  };

  const handleModalSubmit1 = () => {
    setIsTermsAccepted1(!isTermsAccepted1);
  };

  const handleModalSubmit2 = () => {
    setIsTermsAccepted2(!isTermsAccepted2);
  };

  const handleNext = () => {
    console.log("Next button clicked in nested tabs"); // Debugging line
    if (nestedKey === 'subStep1') {
      setNestedKey('subStep2'); // Move to subStep2 on next click
      console.log("nestedKey updated to: subStep2");
    } else if (nestedKey === 'subStep2') {
      setSubNestedKey('subSubStep1'); // Move to first sub-sub-step
      console.log("nestedKey updated to: subSubStep1");
    }
  };


  const handleNextNested = async (e) => {
    e.preventDefault(); // Prevent default form behavior

    // First, handle the form submission
    try {
      await handleSubmit(e); // Wait for the form submission to complete

      // Store the active tab ('subStep2') in localStorage before refreshing
      localStorage.setItem('activeSubTab', 'subStep2');

      // Refresh the page
      window.location.reload();
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };




  const handleNextNesteds = () => {
    setSubNestedKey("subStep3"); // Change the active tab to 'subStep2'
  };
  const handleNextNesteded = () => {
    setSubNestedKey("subStep4"); // Change the active tab to 'subStep2' 
  };
  const handleNextNestedsss = () => {
    setSubNestedKey("subStep6"); // Change the active tab to 'subStep2'
  };


  const handleNextNestedses = () => {
    setSubNestedKey("subStep5"); // Change the active tab to 'subStep2'
  };
  const handleNextNestedspay = () => {
    setSubSubSubStepKey("detail2"); // Change the active tab to 'subStep2'
  };
  const handleNextNestedoptiont = () => {
    setSubSubSubStepKey("detail2"); // Change the active tab to 'subStep2'
  };
  const handleNextNestedoptiontp = () => {
    setSubSubSubStepKey("detail3"); // Change the active tab to 'subStep2'
  };

  const handleNextSubNested = () => {
    console.log("Next button clicked in sub-nested tabs"); // Debugging line
    if (subNestedKey === 'subSubStep1') {
      setSubNestedKey('subSubStep2'); // Move to subSubStep2 on next click
      console.log("subNestedKey updated to: subSubStep2");
    }
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleLogin = (userId) => {
    localStorage.setItem('userId', userId); // Store the userId in local storage
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Get the user ID from local storage directly
        const userId = localStorage.getItem('userId'); // Update key to 'userId'
        console.log("Fetched ID from localStorage:", userId);

        // Check if userId is available
        if (!userId) {
          console.error('No User ID found in localStorage');
          setError('No User ID found in localStorage');
          setIsLoading(false);
          return;
        }

        // Use the user ID to create the dynamic API endpoint
        const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/course/preview/${userId}`);

        console.log("Response Status:", response.status); // Log status
        if (!response.ok) {
          const errorBody = await response.text(); // Log the response body for details
          console.error("Response not OK:", response, errorBody);
          throw new Error(`Failed to fetch data: ${errorBody}`);
        }

        const result = await response.json();
        console.log("API Result:", result); // Log entire result

        // Check if result is not empty and access the data correctly
        if (result && Object.keys(result).length > 0) {
          setToolbarfixture(result); // Set the fetched data to state
          console.log("Fetched User Data:", result);
        } else {
          console.warn("No data found in result");
          setToolbarfixture([]); // Set to empty array or handle accordingly
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setError(error.message); // Store only the message in state
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);


  const handleDownload = () => {
    const input = document.querySelector(".form-content");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      // A4 dimensions
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Adjust top margin here
      const topMargin = 20; // Set your desired top margin in mm
      let heightLeft = imgHeight;
      let position = topMargin;

      // Add the image to the first page with the top margin
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= (pageHeight - topMargin);

      // Add extra pages if content is larger than one page
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("form-data.pdf");
    });
  };



  const handlePrintS = () => {
    const printContent = document.querySelector(".form-content");
    if (!printContent) {
      console.error("Element not found for printing");
      return;
    }

    const printWindow = window.open('', '_blank', 'height=600,width=800');
    printWindow.document.write(`
    <html>
        <head>
            <title>Print Table</title>
            <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; margin-top: 20px; }
                th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
                @media print {
                    @page { margin: 50px; }
                    body { margin: 50px; }
                }
            </style>
        </head>
        <body>
            <h2 style="text-align: center;">Form Details</h2>
            ${printContent.innerHTML}
        </body>
    </html>
`);
    printWindow.document.close(); // Close the document to finish loading
    printWindow.print(); // Trigger the print dialog
    printWindow.close(); // Close the print window after printing
  };



  // <--------------------------------------------------   OPTION 2 DOWNLOAD BUTTON ---------------------------------------------------->

  const handleDownloads = () => {
    setTimeout(() => {
      const input = document.querySelector(".form-contents");
      if (!input) {
        console.error("Element not found for download");
        return;
      }

      html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
        console.log("Canvas generated:", canvas); // Log the canvas
        const imgData = canvas.toDataURL("image/png");
        console.log("Image Data URL:", imgData); // Log the image data URL

        if (imgData && imgData.startsWith("data:image/png;base64,")) {
          const pdf = new jsPDF("p", "mm", "a4");
          const imgWidth = 210; // A4 width in mm
          const pageHeight = 297; // A4 height in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          const topMargin = 10; // Set your desired top margin in mm
          let heightLeft = imgHeight - topMargin; // Adjust heightLeft to account for top margin
          let position = topMargin; // Start drawing after the top margin

          // Add the first image
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= (pageHeight - topMargin); // Adjust height left for the next page

          // Loop for additional pages
          while (heightLeft >= 0) {
            pdf.addPage(); // Add a new page
            position = heightLeft - imgHeight + topMargin; // Maintain top margin for subsequent pages
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight; // Reduce height left for the next iteration
          }

          pdf.save("form-data.pdf");
        } else {
          console.error("Invalid image data URL:", imgData);
        }

      }).catch((error) => {
        console.error("Error generating PDF:", error);
      });
    }, 100); // Delay added to ensure the element is fully rendered
  };




  const handlePrint = () => {
    const printContent = document.querySelector(".form-contents");
    if (!printContent) {
      console.error("Element not found for printing");
      return;
    }

    const printWindow = window.open('', '_blank', 'height=600,width=800');
    printWindow.document.write(`
      <html>
          <head>
              <title>Print Table</title>
              <style>
                  body { font-family: Arial, sans-serif; }
                  table { width: 100%; border-collapse: collapse; margin-top: 20px; }
                  th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                  th { background-color: #f2f2f2; }
                  @media print {
                      @page { margin: 50px; }
                      body { margin: 50px; }
                  }
              </style>
          </head>
          <body>
              <h2 style="text-align: center;">Form Details</h2>
              ${printContent.innerHTML}
          </body>
      </html>
  `);
    printWindow.document.close(); // Close the document to finish loading
    printWindow.print(); // Trigger the print dialog
    printWindow.close(); // Close the print window after printing
  };




  // <---------------------------------------------  STAGE 2 Registration form download button --------------------------------------------------------------------->



  const handleDownloade = () => {
    setTimeout(() => {
      const input = document.querySelector(".form-contentse");
      if (!input) {
        console.error("Element not found for download");
        return;
      }

      html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
        console.log("Canvas generated:", canvas); // Log the canvas
        const imgData = canvas.toDataURL("image/png");
        console.log("Image Data URL:", imgData); // Log the image data URL

        if (imgData && imgData.startsWith("data:image/png;base64,")) {
          const pdf = new jsPDF("p", "mm", "a4");
          const imgWidth = 210; // A4 width in mm
          const pageHeight = 297; // A4 height in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          const topMargin = 10; // Set your desired top margin in mm
          let heightLeft = imgHeight - topMargin; // Adjust heightLeft to account for top margin
          let position = topMargin; // Start drawing after the top margin

          // Add the first image
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= (pageHeight - topMargin); // Adjust height left for the next page

          // Loop for additional pages
          while (heightLeft >= 0) {
            pdf.addPage(); // Add a new page
            position = heightLeft - imgHeight + topMargin; // Maintain top margin for subsequent pages
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight; // Reduce height left for the next iteration
          }

          pdf.save("form-data.pdf");
        } else {
          console.error("Invalid image data URL:", imgData);
        }

      }).catch((error) => {
        console.error("Error generating PDF:", error);
      });
    }, 100); // Delay added to ensure the element is fully rendered
  };


  // <------------------------------------------- STAGE 2 PRINT BUTTON ------------------------------------------->


  const handlePrinte = () => {
    const printContent = document.querySelector(".form-contentse");
    if (!printContent) {
      console.error("Element not found for printing");
      return;
    }

    const printWindow = window.open('', '_blank', 'height=600,width=800');
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Table</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            table { width: 100%; border-collapse: collapse; margin-top: 50px; }
            th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
  
            /* Ensure icons look correct when printing */
            .fa, .fa-phone, .fa-envelope { 
              font-size: 12px !important; /* Adjust icon size for print */
              margin-right: 5px !important;
              width: 12px !important;
              height: 12px !important;
            }
  
            /* Ensure print media-specific styles */
            @media print {
              @page { margin: 10px; }
              body { margin: 20px; }
  
              /* Icon size specific to printing */
              .fa, .fa-phone, .fa-envelope {
                font-size: 12px !important; /* Ensure smaller icons when printing */
                margin-right: 5px !important;
                width: 12px !important;
                height: 12px !important;
              }
  
              /* Adjustments for print layout */
              table { margin-top: 20px; }
              h2 { font-size: 18px; text-align: center; }
            }
          </style>
        </head>
        <body>
          <h2 style="text-align: center;">Form Details</h2>
          ${printContent.innerHTML}
        </body>
      </html>
    `);

    printWindow.document.close(); // Close the document to finish loading
    printWindow.print(); // Trigger the print dialog
    printWindow.close(); // Close the print window after printing
  };



  // <---------------------------------------------- STAGE 6 DOWNLOAD AND PRINT BUTTON ----------------------------------------->//

  const handleDownloadsL = () => {
    setTimeout(() => {
      const input = document.querySelector(".form-contentsL");
      if (!input) {
        console.error("Element not found for download");
        return;
      }

      html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
        console.log("Canvas generated:", canvas); // Log the canvas
        const imgData = canvas.toDataURL("image/png");
        console.log("Image Data URL:", imgData); // Log the image data URL

        if (imgData && imgData.startsWith("data:image/png;base64,")) {
          const pdf = new jsPDF("p", "mm", "a4");
          const imgWidth = 210; // A4 width in mm
          const pageHeight = 297; // A4 height in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          const topMargin = 10; // Set your desired top margin in mm
          let heightLeft = imgHeight - topMargin; // Adjust heightLeft to account for top margin
          let position = topMargin; // Start drawing after the top margin

          // Add the first image
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= (pageHeight - topMargin); // Adjust height left for the next page

          // Loop for additional pages
          while (heightLeft >= 0) {
            pdf.addPage(); // Add a new page
            position = heightLeft - imgHeight + topMargin; // Maintain top margin for subsequent pages
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight; // Reduce height left for the next iteration
          }

          pdf.save("form-data.pdf");
        } else {
          console.error("Invalid image data URL:", imgData);
        }

      }).catch((error) => {
        console.error("Error generating PDF:", error);
      });
    }, 100); // Delay added to ensure the element is fully rendered
  };




  const handlePrintL = () => {
    const printContent = document.querySelector(".form-contentsL");
    if (!printContent) {
      console.error("Element not found for printing");
      return;
    }

    const printWindow = window.open('', '_blank', 'height=600,width=800');
    printWindow.document.write(`
    <html>
        <head>
            <title>Print Table</title>
            <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; margin-top: 20px; }
                th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
                @media print {
                    @page { margin: 50px; }
                    body { margin: 50px; }
                }
            </style>
        </head>
        <body>
            <h2 style="text-align: center;">Form Details</h2>
            ${printContent.innerHTML}
        </body>
    </html>
`);
    printWindow.document.close(); // Close the document to finish loading
    printWindow.print(); // Trigger the print dialog
    printWindow.close(); // Close the print window after printing
  };





  // <---------========================================== PUNCH OUT =====================================----------------------->
  useEffect(() => {
    let timer;

    // Start the timer if punched in
    if (isPunchedIn) {
      timer = setInterval(() => {
        setTotalSeconds(prev => prev + 1); // Increment seconds data not funding in the combined in the user data not fe
      }, 1000); // Update every second
    }

    // Cleanup function to clear the timer
    return () => clearInterval(timer);
  }, [isPunchedIn]); // Effect depends on isPunchedIn data comunity and all the 

  const handlePunchIn = () => {
    setIsPunchedIn(true);
    setPunchInTime(new Date());
    setTotalSeconds(0); // Reset total seconds when punched in
  };

  const handlePunchOut = () => {
    setIsPunchedIn(false);
    const totalHours = totalSeconds / 3600; // Calculate total hours from seconds
    console.log(`Punched Out. Total worked hours: ${totalHours.toFixed(2)} hrs`);
    // Here you could save the totalHours to your backend or perform other actions
  };

  // Convert totalSeconds to hours, minutes, and seconds 
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;


  return (
    <div>
      <style>
        {`
          .tab-content {
            margin-top: 20px; /* Adjust this value to set the gap */
          }
               nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: none !important;
  
    
}
    /* General container styling */
.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Punch-status card styles */
.punch-status .punch-det h6 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

.punch-status .punch-det p {
  font-size: 14px;
}

.punch-status .punch-info .punch-hours {
  text-align: center;
  margin-bottom: 10px;
}

.punch-status .punch-btn {
  width: 100%;
  font-size: 14px;
  padding: 10px;
}



/* Responsive adjustments */
@media (max-width: 768px) {
  .card {
    margin-bottom: 15px;
  }
}
.clock-background {
    border-radius: 50%;
    background-color: #efefef; /* Your desired color */
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 107px; /* Set a width for the circle */
    height: 105px; /* Set a height for the circle */
    border: 2px solid #000; /* Add a 2px solid border, change #000 to your desired border color */
    margin-left:63px;
}


.card-title-bg {
    background-color: #e7f1ff; /* Change this to your desired background color */
    padding: 10px; /* Add some padding for spacing */
    border-radius: 5px; /* Optional: Add rounded corners */
    display: inline-block; /* Make the background fit the content */
        margin-left: 20px;
}




.custom-width {
  width: 250px; /* Adjust this value as needed */
}



         
        `}
      </style>

      <section>
        <div className="container-fluid">
          <div
            className="container boderform"
            style={{
              backgroundColor: "#efefef",
              paddingBottom: "30px",
              borderRadius: "10px",
              marginTop: "20px",
            }}
          >

            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
              style={{ display: 'flex', justifyContent: 'space-evenly', background: '#65646a', width: '101.8%', marginLeft: '-11px', padding: "7px", fontSize: '20px', }}
            >
              {/* Parent Tab 1 */}
              <Tab
                eventKey="registration"
                title={<span style={{ fontWeight: '500', color: '#f58938' }}>COURSES</span>}
              >
                {/* Nested Tabs inside the "Courses" tab */}
                <Tabs
                  id="nested-tab-example"
                  activeKey={nestedKey}
                  onSelect={(k) => setNestedKey(k)}
                  className="mb-3"
                  style={{ marginTop: '-20px', justifyContent: 'space-evenly', background: '#65646a', width: '101.8%', marginLeft: '-11px', fontSize: '20px', padding: '5px' }}
                >
                  {/* Sub Tab 1 */}
                  <Tab eventKey="step1"
                    title={<span style={{
                      fontWeight: '500', color: '#f58938', display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center', padding: "3px", fontSize: '20px',
                    }}>REGISTRATION</span>} >
                    <Tabs
                      id="sub-nested-tab-example"
                      activeKey={subNestedKey}
                      onSelect={(k) => setSubNestedKey(k)}
                      className="mb-3"
                      style={{ marginTop: '-20px', justifyContent: 'space-evenly', background: '#65646a', width: '101.8%', marginLeft: '-11px', padding: "3px", fontSize: '20px' }}
                    >
                      {/* Sub Tab under REGISTRATION */}
                      <Tab eventKey="subStep1" title={<span style={{
                        fontWeight: '500', color: '#f58938', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', padding: "2px", fontSize: '14px',
                      }}>STAGE 1</span>} >

                        <div>
                          <form onSubmit={handleSubmit} className="donate-form">
                            <div className="row" style={{ paddingBottom: '25px' }}>

                              {offlinerobotics && (
                                <>
                                  <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>Full Name*</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder={offlinerobotics.name}
                                      disabled
                                      required
                                    />
                                  </Form.Group>

                                  <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>Gender*</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder={offlinerobotics.gender}
                                      disabled
                                      required
                                    />
                                  </Form.Group>

                                  <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>DOB*</Form.Label>
                                    <Form.Control
                                      type="text"

                                      placeholder={offlinerobotics.dateOfBirth}
                                      disabled
                                      required
                                    />
                                  </Form.Group>
                                  <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>EMail-Id*</Form.Label>
                                    <Form.Control
                                      type="email"

                                      placeholder={offlinerobotics.emailId}
                                      disabled
                                      required
                                    />
                                  </Form.Group>
                                  <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>Contact No.*</Form.Label>
                                    <Form.Control
                                      type="number"

                                      placeholder={offlinerobotics.mobileNumber}
                                      disabled
                                      required
                                    />
                                  </Form.Group>
                                </>
                              )}






                              <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Source*</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter source"
                                  name="source" // API field name
                                  value={formData.source} // Ensure value is linked to formData.source
                                  onChange={handleChange} // Handle change to update formData.source
                                />
                              </Form.Group>


                              <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Source Name*</Form.Label>
                                <Form.Control
                                  type="text"

                                  placeholder="Source Name"

                                  name="sourceName" // API field name
                                  value={formData.sourceName} // Ensure value is linked to formData.sourceName
                                  onChange={handleChange}

                                />
                              </Form.Group>




                              <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Courses*</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  name="courses" // API field name
                                  value={formData.courses} // Ensure value is linked to formData.courses
                                  onChange={handleChange} // Handle change to update formData

                                >
                                  <option value="">Select Course</option>
                                  <option value="ToolandFixture Design">Tool & Fixture Design</option>
                                  <option value="Offline Robotics">Offline Robotics</option>
                                  <option value="Online Robotics">Online Robotics</option>
                                  <option value="Java">Java</option>
                                  <option value="React Js">React Js</option>
                                  <option value="Flutter">Flutter</option>
                                  <option value="Java">Cloud Computing</option>
                                  <option value="React Js">Data Analystics</option>
                                  <option value="Flutter">Artificial Intelligence</option>
                                  <option value="Flutter">Automation Solutions</option>
                                  <option value="Flutter">Digital Marketing</option>
                                </Form.Select>
                              </Form.Group>


                              <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Courses Duration*</Form.Label>
                                <Form.Select
                                  aria-label="Default select example "
                                  name="branchs" onChange={handleChange} 

                                >
                                  <option>Select Any Field</option>
                                  <option value="Hyderabad" >160_HRS</option>
                                  <option value="Bengaluru" >320_HRS</option>
                             
                                </Form.Select>
                              </Form.Group>





                            </div>
                            <div className="row" style={{ paddingBottom: "25px" }}>
                            

                              <Form.Group className="col-md-3" controlId="subFieldSelect">
                                <Form.Label>Time*</Form.Label>
                                <Form.Select
                                  aria-label="Default select example "
                                  name="branchs" onChange={handleChange} 

                                >
                                  <option>Select Any Field</option>
                                  <option value="Hyderabad" >9AM-1PM</option>
                                  <option value="Bengaluru" >2PM-6PM</option>
                             
                                </Form.Select>
                              </Form.Group>


                              <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Mode*</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  name="mode"
                                  value={formData.mode} // Ensure the value is linked to formData.mode 
                                  onChange={handleChange1} // Update the form data on change

                                >
                                  <option value="">Select Mode</option> {/* Optional placeholder */}
                                  <option value="Offline">OFFLINE</option>
                                  <option value="Online">ONLINE</option>
                                </Form.Select>
                              </Form.Group>






                              <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Branches*</Form.Label>
                                <Form.Select
                                  aria-label="Default select example "
                                  name="branches" onChange={handleChange} value={formData.branches}

                                >
                                  <option>Select Any Field</option>
                                  <option value="Hyderabad" >Hyderabad</option>
                                  <option value="Bengaluru" >Bangloore</option>
                                  <option value="Chennai" >Chennai</option>
                                  <option value="Pune" >Pune</option>
                                </Form.Select>
                              </Form.Group>
                              <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                               >
                                <Form.Label>Registration Fee*</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder={offlinerobotics?.coursePrice || 1000}
                                  value={formData.coursePrice || ""}
                                  disabled

                                />
                              </Form.Group>

                            </div>
                            





                            {/* Add the Next button */}
                            <button type="submit" className="custom-btn btn-3" >
                              Pay Now
                            </button>
                          </form>
                        </div>

                      </Tab>

                      <Tab eventKey="subStep2" title={<span style={{
                        fontWeight: '500', color: '#f58938', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', padding: "2px", fontSize: '14px',
                      }}>STAGE 2</span>} >
                        <div className="form-contentse" style={{
                          width: "794px", // A4 width in pixels
                          height: "746px", // A4 height in pixels data not fetching in all the required feilds 
                          backgroundColor: "#ffffff", // Background color
                          padding: "20px", // Padding around the content
                          border: "1px solid rgb(33 29 29)", // Optional: add a border
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: add shadow for depth
                          margin: "0 auto", // Center the A4 container
                          overflow: "hidden" // Prevent content overflow
                        }}>
                          <div className="row" style={{ paddingBottom: '25px' }}>
                            <div className="container-fluid " id="table-container" style={{ paddingTop: '60px' }}>
                              <div className="container">
                                <div style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  padding: "0px",
                                  flexWrap: "wrap",
                                  paddingBottom: "16px",
                                  marginTop: "-30px"
                                }}>
                                  <img src={logo} alt="" style={{ width: "83px", height: "70px", marginRight: "20px" }} />
                                  <h3 style={{ color: "#f58938", margin: 0 }}>
                                    INTERNATIONAL INSTITUTE OF AUTOMATE
                                  </h3>

                                </div>

                                <div className="row" style={{ fontSize: "22px" }}>
                                  <div>
                                    {isLoading ? (
                                      <p>Loading...</p>
                                    ) : error ? (
                                      <p>Error fetching data: {error.message}</p>
                                    ) : toolbarfixture && Object.keys(toolbarfixture).length > 0 ? (
                                      <table style={{ paddingTop: '15px', paddingBottom: "15px" }}>
                                        <tr>
                                          <td>Full Name</td>
                                          <td>{toolbarfixture.name ?? ""}</td>
                                        </tr>
                                        <tr>
                                          <td>Email Id</td>
                                          <td style={{ wordBreak: "break-word" }}>{toolbarfixture.emailId ?? ""}</td>
                                        </tr>
                                        <tr>
                                          <td>Mobile Number</td>
                                          <td>{toolbarfixture.mobileNumber ?? ""}</td>
                                        </tr>
                                        <tr>
                                          <td>Gender</td>
                                          <td>{toolbarfixture.gender ?? ""}</td>
                                        </tr>
                                        <tr>
                                          <td>Date of Birth</td>
                                          <td>{toolbarfixture.dateOfBirth ? new Date(toolbarfixture.dateOfBirth).toLocaleDateString('en-GB') : ""}</td>
                                        </tr>
                                        <tr>
                                          <td>Courses</td>
                                          <td>{toolbarfixture.courses ?? ""}</td>
                                        </tr>
                                        <tr>
                                          <td>Source</td>
                                          <td>{toolbarfixture.source ?? ""}</td>
                                        </tr>
                                        <tr>
                                          <td>Source Name</td>
                                          <td>{toolbarfixture.sourceName ?? ""}</td>
                                        </tr>
                                        <tr>
                                          <td>Mode</td>
                                          <td>{toolbarfixture.mode ?? ""}</td>
                                        </tr>
                                        <tr>
                                          <td>Duration</td>
                                          <td>{toolbarfixture.courseDuration ?? ""}</td>
                                        </tr>
                                        <tr>
                                          <td>Time</td>
                                          <td>{toolbarfixture.timings ?? ""}</td>
                                        </tr>
                                        <tr>
                                          <td>Branch</td>
                                          <td>{toolbarfixture.branches ?? ""}</td>
                                        </tr>
                                        <tr>
                                          <td>Registration Fee</td>
                                          <td>1000</td>
                                        </tr>
                                        <tr>
                                          <td>Status</td>
                                          <td>{toolbarfixture.registrationAmountStatus ?? ""}</td>
                                        </tr>
                                      </table>
                                    ) : (
                                      <p>No data available</p>
                                    )}
                                  </div>

                                  {/* <hr></hr> */}
                                  {/* <h2 style={{ textAlign: "center", padding: "10px",paddingTop:'40px',paddingBottom:"40PX" }}>
                                         INTERNATIONAL INSTITUTE OF AUTOMATE
                                    </h2> */}
                                  <div >



                                    <div class="row" style={{ textAlign: "center" }}>
                                      <p>www.iiautomate.com</p>
                                    </div>
                                    <div class="row" style={{ display: "flex", justifyContent: "space-between", }}>
                                      <p style={{ textAlign: "right" }}>
                                        <i className="fa fa-phone m-r5"></i>
                                        <FontAwesomeIcon icon="fa-solid fa-phone" /> +91 80961201731
                                      </p>
                                      <p style={{ textAlign: "left", marginTop: "-39px" }}>
                                        {" "}
                                        <i className="fa fa-envelope-o m-r5"></i>{" "}
                                        <FontAwesomeIcon icon="fa-solid fa-envelope" />{" "}
                                        Info@iiautomate.com
                                      </p>
                                    </div>

                                  </div>

                                </div>
                              </div>

                            </div>
                            {/* Add other fields for step 2 */}
                          </div>


                        </div>
                        <div className="row" style={{ justifyContent: 'center', padding: '20px' }}>
                          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                            <button type="button" className="custom-btn btn-3" onClick={handleDownloade}>
                              Download
                            </button>
                            <button type="button" className="custom-btn btn-3" onClick={handlePrinte}>
                              Print
                            </button>
                          </div>
                        </div>
                        <button type="button" className="custom-btn btn-3" onClick={handleNextNesteds}>
                          Next
                        </button>
                      </Tab>
                      <Tab eventKey="subStep3" title={<span style={{
                        fontWeight: '500', color: '#f58938', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', padding: "2px", fontSize: '14px',
                      }}>STAGE 3</span>} >
                        <div>

                          <h2
                            style={{
                              textAlign: "center",
                              color: "#5B4B99",
                              paddingBottom: "50px",
                              paddingTop: "30px",
                            }}
                          >
                            Terms And Conditions
                          </h2>
                          <div className="row" style={{ paddingBottom: '25px', width: "70%", margin: "0 auto" }}>
                            <p style={{ textAlign: "justify" }}>
                              By submitting the IIA Automate course payment form, you agree to the terms and conditions
                              outlined. Payment is required in full upon registration, and refunds are only available
                              within 14 days of payment, provided course materials have not been accessed. Course access
                              is granted after successful payment and will remain available for the specified duration.
                            </p>
                            <p style={{ textAlign: "justify" }}>
                              All course materials are the intellectual property of IIA Automate and may not be reproduced
                              without permission. Participants must adhere to professional conduct during the course.
                              Personal data will be handled according to our Privacy Policy. IIA Automate reserves the
                              right to update these terms at any time.
                            </p>
                            <p style={{ textAlign: "justify" }}>
                              Any breach of these terms and conditions may result in the immediate termination of course access
                              without refund. IIA Automate retains the right to revoke access if it is determined that the terms
                              have been violated, including but not limited to sharing login credentials or course materials
                              with unauthorized individuals. Participants are expected to complete the course independently, and
                              any form of academic dishonesty will not be tolerated.
                            </p>

                            <p style={{ textAlign: "justify" }}>
                              The course content is designed for educational purposes only, and IIA Automate does not guarantee
                              specific career outcomes or certifications upon completion. While we strive to provide accurate and
                              up-to-date information, we are not liable for any errors or omissions in the course materials. It is
                              the participant's responsibility to ensure they meet any technical or hardware requirements needed
                              to complete the course.
                            </p>


                            <div className="checkbox-container" style={{ display: "flex", alignItems: "center" }}>
                              <input
                                type="checkbox"
                                id="terms"
                                checked={isTermsAccepted}
                                onClick={handleModalSubmit} // Use the new handleModalSubmit function
                                required
                                style={{ display: "none" }}
                              />
                              <div
                                onClick={handleCheckboxClick}
                                style={{
                                  cursor: "pointer",
                                  width: "15px",
                                  height: "15px",
                                  border: "2px solid green",
                                  borderRadius: "4px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: isTermsAccepted ? "green" : "white",
                                  marginTop: "-6px",
                                }}
                              >
                                {isTermsAccepted && (
                                  <FontAwesomeIcon icon={faCheckSquare} style={{ color: "white" }} />
                                )}
                              </div>
                              <label htmlFor="terms" style={{ marginLeft: "10px", cursor: "default" }}>
                                I agree to the{" "}
                                <span
                                  style={{ color: "blue", cursor: "pointer" }}

                                >
                                  Terms and Conditions
                                </span>
                              </label>


                            </div>

                          </div>


                          <button
                            className="custom-btn "
                            onClick={handleNextNesteded}
                            disabled={!isTermsAccepted} // Disable the button if terms are not accepted

                            style={{
                              backgroundColor: isTermsAccepted ? "#f6954e" : "lightgray", // Green if enabled, light gray if disabled
                              color: isTermsAccepted ? "white" : "black", // White text if enabled, dark gray if disabled
                              cursor: isTermsAccepted ? "pointer" : "not-allowed", // Pointer if enabled, not-allowed if disabled
                              border: "none", // Optional: remove border if you want
                              padding: "10px 20px", // Optional: padding for the button
                              borderRadius: "5px", // Optional: rounded corners
                              transition: "background-color 0.3s ease", // Smooth transition for color change
                            }}

                          >
                            Next
                          </button>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="subStep4"
                        title={
                          <span
                            style={{
                              fontWeight: "500",
                              color: "#f58938",
                              // height: "36px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "2px", fontSize: '14px'
                            }}
                          >
                            STAGE 4
                          </span>
                        }
                      >
                        <div>
                          {/* Nested Tabs */}
                          <Tabs
                            activeKey={subSubNestedKey}
                            onSelect={(k) => {
                              console.log("Sub Sub nested key changed to:", k);
                              setSubSubNestedKey(k);
                            }}
                            className="mb-3"
                            style={{
                              marginTop: "-20px",
                              justifyContent: "space-evenly",
                              background: "#65646a",
                              width: "101.8%",
                              marginLeft: "-11px",
                              // height: "55px",
                              padding: "4px", fontSize: '16px'
                            }}
                          >
                            <Tab
                              eventKey="subSubSubStep1"
                              title={
                                <span
                                  style={{
                                    fontWeight: "500",
                                    color: "#f58938",
                                    // height: "39px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "2px", fontSize: '14px'
                                  }}
                                >
                                  OPTION 1
                                </span>
                              }
                            >
                              {/* Sub-tabs for OPTION 1 */}
                              <Tabs
                                activeKey={subSubSubStepKey}
                                onSelect={(k) => {
                                  console.log("Sub-sub tab changed to:", k);
                                  setSubSubSubStepKey(k);
                                }}
                                className="mb-3"
                                style={{
                                  justifyContent: 'space-evenly', fontSize: '25px', background: '#65646a', width: '101.8%', marginLeft: '-11px', marginTop: '-20px', padding: "3px", fontSize: '16px'
                                }}
                              >
                                <Tab eventKey="detail1" title={<span style={{
                                  fontWeight: '500', color: '#f58938', padding: "3px", fontSize: '16px', display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}> DETAILS</span>} >
                                  <div style={{ padding: "20px", marginLeft: "160px" }}>




                                    <div style={{ padding: '20px', marginLeft: '160px' }}>
                                      <div style={{ display: 'table', width: '100%', }}>
                                        {/* Table Header */}
                                        <div style={{ display: 'table-row', }}>
                                          <div style={{ display: 'table-cell', padding: '10px', fontWeight: 'bold' }}>Category</div>
                                          <div style={{ display: 'table-cell', padding: '10px' }}>Course</div>
                                        </div>

                                        <div style={{ display: 'table-row' }}>
                                          <div style={{ display: 'table-cell', padding: '10px', fontWeight: 'bold' }}>Particular</div>
                                          <div style={{ display: 'table-cell', padding: '10px' }}>Online Robotics</div>
                                        </div>

                                        <div style={{ display: 'table-row' }}>
                                          <div style={{ display: 'table-cell', padding: '10px', fontWeight: 'bold' }}>Fee amount</div>
                                          <div style={{ display: 'table-cell', padding: '10px' }}>10,000</div>
                                        </div>

                                        <div style={{ display: 'table-row' }}>
                                          <div style={{ display: 'table-cell', padding: '10px', fontWeight: 'bold' }}>Paid amount</div>
                                          <div style={{ display: 'table-cell', padding: '10px' }}>30,000</div>
                                        </div>

                                        <div style={{ display: 'table-row' }}>
                                          <div style={{ display: 'table-cell', padding: '10px', fontWeight: 'bold' }}>Pending amount</div>
                                          <div style={{ display: 'table-cell', padding: '10px' }}>20,000</div>
                                        </div>
                                      </div>
                                    </div>


                                    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                                      <button type="button" className="custom-btn btn-3" onClick={handleNextNestedspay}>
                                        Pay
                                      </button>
                                    </div>
                                  </div>
                                </Tab>
                                <Tab eventKey="detail2" title={<span style={{
                                  fontWeight: '500', color: '#f58938', display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center', padding: "3px", fontSize: '16px'
                                }}>PREVIEW</span>} >
                                  <div className="row" style={{ paddingBottom: '25px' }}>
                                    <div className="container-fluid " id="table-container" style={{ paddingTop: '60px' }}>
                                      <div className="container">
                                        <div className="row" style={{ fontSize: "22px" }}>
                                          <div></div>

                                          <div className="form-content" style={{
                                            width: "794px", // A4 width in pixels
                                            height: "750px", // A4 height in pixels
                                            backgroundColor: "#ffffff", // Background color
                                            padding: "20px", // Padding around the content
                                            border: "1px solid rgb(33 29 29)", // Optional: add a border
                                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: add shadow for depth
                                            margin: "0 auto", // Center the A4 container
                                            overflow: "hidden" // Prevent content overflow
                                          }}>
                                            <div style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              textAlign: "center",
                                              padding: "0px",
                                              flexWrap: "wrap",
                                              paddingBottom: "16px",
                                              marginTop: "0px"
                                            }}>
                                              <img src={logo} alt="" style={{ width: "83px", height: "70px", marginRight: "20px" }} />
                                              <h3 style={{ color: "#f58938", margin: 0 }}>
                                                INTERNATIONAL INSTITUTE OF AUTOMATE
                                              </h3>


                                            </div>
                                            {/* <div >
                                              <p style={{ textAlign: "right" }}>
                                                <i className="fa fa-phone m-r5"></i>
                                                +91 80961201731
                                              </p>
                                              <p style={{ textAlign: "right" }}>
                                                {" "}
                                                <i className="fa fa-envelope-o m-r5"></i>{" "}
                                                {" "}
                                                Info@iiautomate.com
                                              </p>
                                            </div> */}
                                            {/* <br></br> */}
                                            <table style={{ width: "100%", borderCollapse: "collapse", paddingTop: "15px", paddingBottom: "15px" }}>
                                              <tr style={{ borderBottom: "none" }}>
                                                <td style={{ border: "none" }}>Full Name</td>
                                                <td style={{ border: "none" }}>Naresh</td>
                                              </tr>
                                              <tr style={{ borderBottom: "none" }}>
                                                <td style={{ border: "none" }}>Email Id</td>
                                                <td style={{ border: "none", wordBreak: "break-word" }}>nareshbojja0009@gmail.com</td>
                                              </tr>
                                              <tr style={{ borderBottom: "none" }}>
                                                <td style={{ border: "none" }}>Mobile Number</td>
                                                <td style={{ border: "none" }}>8688037081</td>
                                              </tr>
                                              <tr style={{ borderBottom: "none" }}>
                                                <td style={{ border: "none" }}>Gender</td>
                                                <td style={{ border: "none" }}>Male</td>
                                              </tr>
                                              <tr style={{ borderBottom: "none" }}>
                                                <td style={{ border: "none" }}>Date of Birth</td>
                                                <td style={{ border: "none" }}>10-05-2000</td>
                                              </tr>
                                              <tr style={{ borderBottom: "none" }}>
                                                <td style={{ border: "none" }}>Category</td>
                                                <td style={{ border: "none" }}>Courses</td>
                                              </tr>
                                              <tr style={{ borderBottom: "none" }}>
                                                <td style={{ border: "none" }}>Particular</td>
                                                <td style={{ border: "none" }}>Offline Robotics</td>
                                              </tr>
                                              <tr style={{ borderBottom: "none" }}>
                                                <td style={{ border: "none" }}>Mode</td>
                                                <td style={{ border: "none" }}>Online</td>
                                              </tr>
                                              <tr style={{ borderBottom: "none" }}>
                                                <td style={{ border: "none" }}>Duration</td>
                                                <td style={{ border: "none" }}>160 HRS</td>
                                              </tr>
                                              <tr style={{ borderBottom: "none" }}>
                                                <td style={{ border: "none" }}>Date</td>
                                                <td style={{ border: "none" }}>10-05-2000</td>
                                              </tr>
                                              <tr style={{ borderBottom: "none" }}>
                                                <td style={{ border: "none" }}>Time</td>
                                                <td style={{ border: "none" }}>9AM-6PM</td>
                                              </tr>
                                              <tr style={{ borderBottom: "none" }}>
                                                <td style={{ border: "none" }}>Branch</td>
                                                <td style={{ border: "none" }}>Hyderabad</td>
                                              </tr>
                                              <tr style={{ borderBottom: "none" }}>
                                                <td style={{ border: "none" }}>Fee amount</td>
                                                <td style={{ border: "none" }}>40,000</td>
                                              </tr>
                                              <tr style={{ borderBottom: "none" }}>
                                                <td style={{ border: "none" }}>Paid amount</td>
                                                <td style={{ border: "none" }}>10,000</td>
                                              </tr>
                                              <tr style={{ borderBottom: "none" }}>
                                                <td style={{ border: "none" }}>Pending Amount</td>
                                                <td style={{ border: "none" }}>30,000</td>
                                              </tr>
                                            </table>


                                            <div >



                                              <div class="row" style={{ textAlign: "center" }}>
                                                <p>www.iiautomate.com</p>
                                              </div>
                                              <div class="row" style={{ display: "flex", justifyContent: "space-between", }}>
                                                <p style={{ textAlign: "right" }}>
                                                  <i className="fa fa-phone m-r5"></i>
                                                  <FontAwesomeIcon icon="fa-solid fa-phone" /> +91 80961201731
                                                </p>
                                                <p style={{ textAlign: "left", marginTop: "-39px" }}>
                                                  {" "}
                                                  <i className="fa fa-envelope-o m-r5"></i>{" "}
                                                  <FontAwesomeIcon icon="fa-solid fa-envelope" />{" "}
                                                  Info@iiautomate.com
                                                </p>
                                              </div>

                                            </div>

                                          </div>

                                          {/* Download Button */}
                                          <br />

                                          {/* <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                                            <button type="button" className="custom-btn btn-3" onClick={handleDownload}>
                                              Download
                                            </button>
                                          </div> */}


                                          <div className="row" style={{ justifyContent: 'center', padding: '20px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                              <button type="button" className="custom-btn btn-3" onClick={handleDownload}>
                                                Download
                                              </button>
                                              <button type="button" className="custom-btn btn-3" onClick={handlePrintS}>
                                                Print
                                              </button>
                                            </div>
                                          </div>
                                          {/* </div> */}

                                        </div>
                                      </div>

                                    </div>
                                    {/* Add other fields for step 2 */}
                                  </div>

                                  {/* <button type="button" className="custom-btn btn-3" onClick={handleNextNesteds}>
                Next
              </button> */}

                                </Tab>
                              </Tabs>


                            </Tab>

                            {/* Existing OPTION 2 Tab */}
                            <Tab
                              eventKey="subSubSubStep2"
                              title={
                                <span
                                  style={{
                                    fontWeight: "500",
                                    color: "#f58938",
                                    // height: "39px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "2px", fontSize: '14px'
                                  }}
                                >
                                  OPTION 2
                                </span>
                              }
                            >


                              <Tabs
                                activeKey={subSubSubStepKey}
                                onSelect={(k) => {
                                  console.log("Sub-sub tab changed to:", k);
                                  setSubSubSubStepKey(k);
                                }}
                                className="mb-3"
                                style={{
                                  justifyContent: 'space-evenly', fontSize: '25px', background: '#65646a', width: '101.8%', marginLeft: '-11px', marginTop: '-20px', padding: "3px", fontSize: '16px'
                                }}
                              >
                                <Tab eventKey="detail1" title={<span style={{
                                  fontWeight: '500', color: '#f58938', padding: "3px", fontSize: '15px', display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}> DETAILS</span>} >

                                  <div style={{ padding: "20px", marginLeft: "160px" }}>
                                    <div >
                                      <div style={{ display: 'table', width: '100%', marginLeft: '150px' }}>
                                        {/* Table Header */}
                                        <div style={{ display: 'table-row', }}>
                                          <div style={{ display: 'table-cell', padding: '10px', fontWeight: 'bold' }}>Category</div>
                                          <div style={{ display: 'table-cell', padding: '10px' }}>Course</div>
                                        </div>

                                        <div style={{ display: 'table-row' }}>
                                          <div style={{ display: 'table-cell', padding: '10px', fontWeight: 'bold' }}>Particular</div>
                                          <div style={{ display: 'table-cell', padding: '10px' }}>Online Robotics</div>
                                        </div>

                                        <div style={{ display: 'table-row' }}>
                                          <div style={{ display: 'table-cell', padding: '10px', fontWeight: 'bold' }}>Fee amount</div>
                                          <div style={{ display: 'table-cell', padding: '10px' }}>10,000</div>
                                        </div>

                                        <div style={{ display: 'table-row' }}>
                                          <div style={{ display: 'table-cell', padding: '10px', fontWeight: 'bold' }}>Paid amount</div>
                                          <div style={{ display: 'table-cell', padding: '10px' }}>30,000</div>
                                        </div>

                                        <div style={{ display: 'table-row' }}>
                                          <div style={{ display: 'table-cell', padding: '10px', fontWeight: 'bold' }}>Pending amount</div>
                                          <div style={{ display: 'table-cell', padding: '10px' }}>20,000</div>
                                        </div>

                                        <div style={{ display: 'table-row' }}>
                                          <div style={{ display: 'table-cell', padding: '10px', fontWeight: 'bold' }}>Partial amount</div>
                                          <div style={{ display: 'table-cell', padding: '10px' }}>
                                            <input
                                              type="text"
                                              defaultValue="20,000"
                                              style={{
                                                width: '10%', // Make the input take the full width of the cell
                                                padding: '5px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                              }}
                                            />
                                          </div>
                                        </div>

                                        <div style={{ display: 'table-row' }}>
                                          <div style={{ display: 'table-cell', padding: '10px', fontWeight: 'bold' }}>Remaining amount</div>
                                          <div style={{ display: 'table-cell', padding: '10px' }}>
                                            <input
                                              type="text"
                                              defaultValue="20,000"
                                              style={{
                                                width: '10%', // Make the input take the full width of the cell
                                                padding: '5px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>




                                    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                                      <button type="button" className="custom-btn btn-3" onClick={handleNextNestedoptiont}>
                                        Pay
                                      </button>
                                    </div>
                                  </div>
                                </Tab>
                                <Tab eventKey="detail2" title={<span style={{
                                  fontWeight: '500', color: '#f58938', display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center', padding: "3px", fontSize: '16px'
                                }}>EMI</span>} >
                                  <div style={{ padding: "20px", }}>

                                    <table method="Get" style={{ paddingTop: '15px', paddingBottom: "15PX", fontSize: "26px" }}>

                                      <tr >
                                        <td >Total Amount</td>
                                        <td>30,000</td>
                                      </tr>
                                      <tr>
                                        <td>Number of EMI's</td>
                                        <td>10</td>
                                      </tr>
                                      <tr>
                                        <td>Monthly EMI Cost</td>
                                        <td>3000</td>
                                      </tr>

                                      <tr>
                                        <td>EMI Starting Month</td>
                                        <td>10-05-2024</td>
                                      </tr>

                                      <tr>
                                        <td>EMI End Month</td>
                                        <td>18-09-2024</td>
                                      </tr>



                                    </table>

                                    <div style={{ display: 'flex', marginLeft: "20px", justifyContent: 'center', }}>
                                      <button type="button" className="custom-btn btn-3" onClick={handleNextNestedoptiontp}>
                                        Pay
                                      </button>
                                    </div>

                                  </div>

                                </Tab>
                                <Tab eventKey="detail3" title={<span style={{
                                  fontWeight: '500', color: '#f58938', display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center', padding: "3px", fontSize: '16px'
                                }}>PREVIEW</span>} >

                                  <div className="container">

                                    {/* <hr></hr> */}


                                    <div className="row" style={{ fontSize: "22px" }}>
                                      <div></div>

                                      <div className="form-contents" style={{
                                        width: "794px", // A4 width in pixels
                                        height: "800px", // A4 height in pixels
                                        backgroundColor: "#ffffff", // Background color
                                        padding: "20px", // Padding around the content
                                        border: "1px solid rgb(33 29 29)", // Optional: add a border
                                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: add shadow for depth
                                        margin: "0 auto", // Center the A4 container
                                        overflow: "hidden" // Prevent content overflow
                                      }}>
                                        <div className="row" style={{ fontSize: "22px" }}>
                                          <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            textAlign: "center",
                                            padding: "0px",
                                            flexWrap: "wrap",
                                            paddingBottom: "16px",
                                            marginTop: "20px"
                                          }}>
                                            <img src={logo} alt="" style={{ width: "83px", height: "70px", marginRight: "20px" }} />
                                            <h3 style={{ color: "#f58938", margin: 0 }}>
                                              INTERNATIONAL INSTITUTE OF AUTOMATE
                                            </h3>

                                            {/* <p style={{ textAlign: "right" }}>
                                                <i className="fa fa-phone m-r5"></i>
                                                <FontAwesomeIcon icon="fa-solid fa-phone" /> +91 80961201731
                                                    </p>
                                                    <p style={{ textAlign: "right" }}>
                                                     {" "}
                                                     <i className="fa fa-envelope-o m-r5"></i>{" "}
                                                   <FontAwesomeIcon icon="fa-solid fa-envelope" />{" "}
                                                    Info@iiautomate.com
                                                </p> */}


                                          </div>
                                          {/* <div >
                                            <p style={{ textAlign: "right" }}>
                                              <i className="fa fa-phone m-r5"></i>
                                              +91 80961201731
                                            </p>
                                            <p style={{ textAlign: "right" }}>
                                              {" "}
                                              <i className="fa fa-envelope-o m-r5"></i>{" "}
                                              {" "}
                                              Info@iiautomate.com
                                            </p>
                                          </div> */}

                                        </div>
                                        {/* <br></br> */}
                                        <table method="Get" style={{ paddingTop: '15px', paddingBottom: "15PX" }}>

                                          <tr>
                                            <td>Full Name</td>
                                            <td>Naresh</td>
                                          </tr>
                                          <tr>
                                            <td>Email Id</td>
                                            <td style={{ wordBreak: "break-word" }}>nareshbojja0009@gmail.com</td>
                                          </tr>
                                          <tr>
                                            <td>Mobile Number</td>
                                            <td>8688037081</td>
                                          </tr>
                                          <tr>
                                            <td>Gender</td>
                                            <td>Male</td>
                                          </tr>

                                          <tr>
                                            <td>Date of Birth</td>
                                            <td>10-05-2000</td>
                                          </tr>


                                          <tr>
                                            <td>Category</td>
                                            <td>Courses</td>
                                          </tr>
                                          <tr>
                                            <td>Particular</td>
                                            <td>Offline Rootics</td>
                                          </tr>
                                          <tr>
                                            <td>Mode</td>
                                            <td>
                                              Online
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>Duration</td>
                                            <td>160 HRS</td>
                                          </tr>
                                          <tr>
                                            <td>Date</td>
                                            <td>10-05-2000</td>
                                          </tr>
                                          <tr>
                                            <td>Time</td>
                                            <td>9AM-6PM</td>
                                          </tr>
                                          <tr>
                                            <td>Start Date</td>
                                            <td>10-06-2024</td>
                                          </tr>


                                          <tr>
                                            <td>End Date</td>
                                            <td>18-09-2024</td>
                                          </tr>
                                          <tr>
                                            <td>Batch</td>
                                            <td>45</td>
                                          </tr>


                                          <tr>
                                            <td>Status</td>
                                            <td>Paid</td>
                                          </tr>




                                        </table>
                                        <div >



                                          <div class="row" style={{ textAlign: "center" }}>
                                            <p>www.iiautomate.com</p>
                                          </div>
                                          <div class="row" style={{ display: "flex", justifyContent: "space-between", }}>
                                            <p style={{ textAlign: "right" }}>
                                              <i className="fa fa-phone m-r5"></i>
                                              <FontAwesomeIcon icon="fa-solid fa-phone" /> +91 80961201731
                                            </p>
                                            <p style={{ textAlign: "left", marginTop: "-39px" }}>
                                              {" "}
                                              <i className="fa fa-envelope-o m-r5"></i>{" "}
                                              <FontAwesomeIcon icon="fa-solid fa-envelope" />{" "}
                                              Info@iiautomate.com
                                            </p>
                                          </div>

                                        </div>
                                      </div>
                                      <div className="row" style={{ justifyContent: 'center', padding: '20px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                          <button type="button" className="custom-btn btn-3" onClick={handleDownloads}>
                                            Download
                                          </button>
                                          <button type="button" className="custom-btn btn-3" onClick={handlePrint}>
                                            Print
                                          </button>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                  <button type="button" className="custom-btn btn-3" onClick={handleNextNestedses}>
                                    Next
                                  </button>

                                </Tab>
                              </Tabs>




                            </Tab>
                          </Tabs>
                        </div>
                      </Tab>
                      <Tab eventKey="subStep5" title={<span style={{
                        fontWeight: '500', color: '#f58938', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', padding: "2px", fontSize: '14px',
                      }}>STAGE 5</span>} >


                        <div className="row">
                          {/* Timesheet card */}
                          <div className="col-md-3">
                            <div className="card punch-status">


                              <div className="card-body">
                                <h5 className="card-title card-title-bg">
                                  Timesheet <small className="text-muted">{new Date().toLocaleDateString()}</small>
                                </h5>

                                <div className="punch-det">
                                  <h6>Punch {isPunchedIn ? "Out" : "In"} at</h6>
                                  <p>{isPunchedIn ? punchInTime.toString() : "Not punched in"}</p>
                                </div>

                                <div className="clock-background">
                                  <span className="punch-hours">
                                    {hours} : {minutes} : {seconds}
                                  </span>
                                </div>

                                <div className="punch-btn-section">
                                  {isPunchedIn ? (
                                    <button
                                      type="button"
                                      className="btn btn-primary punch-btn"
                                      style={{ background: "#f77f31" }}
                                      onClick={handlePunchOut}
                                    >
                                      Punch Out
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-primary punch-btn"
                                      style={{ background: "#28a745" }} // Green for punch in
                                      onClick={handlePunchIn}
                                    >
                                      Punch In
                                    </button>
                                  )}
                                </div>

                                <div className="statistics">
                                  <div className="row">
                                    <div className="col-md-6 col-6 text-center">
                                      <div className="stats-box">
                                        <p>Break</p>
                                        <h6>0 hrs</h6> {/* Example for break time */}
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-6 text-center">
                                      <div className="stats-box">
                                        <p>Overtime</p>
                                        <h6>0 hrs</h6> {/* Example for overtime */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>



                            </div>
                          </div>

                          {/* Table Section */}
                          <div className="col-md-9">
                            <div className="d-flex align-items-center mb-3">
                              <div className="me-2 custom-width">
                                <label htmlFor="dateSelect" className="form-label">Date:</label>
                                <input type="date" id="dateSelect" className="form-control" />
                              </div>
                              <div className="me-2 custom-width">
                                <label htmlFor="monthSelect" className="form-label">Month:</label>
                                <select id="monthSelect" className="form-select">
                                  <option value="">Select Month</option>
                                  {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map((month, index) => (
                                    <option key={index} value={index + 1}>{month}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="me-2 custom-width">
                                <label htmlFor="yearSelect" className="form-label">Year:</label>
                                <select id="yearSelect" className="form-select">
                                  <option value="">Select Year</option>
                                  {[...Array(10).keys()].map((year) => {
                                    const currentYear = new Date().getFullYear();
                                    return (
                                      <option key={year} value={currentYear - year}>{currentYear - year}</option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="me-2 custom-width">

                                <div className="input-group">

                                  <button className="btn btn-primary" type="button" id="searchButton" style={{ background: "#f77f31" }}>Search</button>
                                </div>
                              </div>
                            </div>


                            <div className="table-responsive" style={{ overflowX: 'auto' }}>
                              <table
                                className="table table-striped custom-table mb-0"
                                style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'left' }}
                              >
                                <thead>
                                  <tr className="table-header">
                                    <th style={{ padding: '12px', borderBottom: '1px solid #dee2e6', fontWeight: 'bold', height: '48px' }}>S.NO</th>
                                    <th style={{ padding: '12px', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Date</th>
                                    <th style={{ padding: '12px', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Punch In</th>
                                    <th style={{ padding: '12px', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Punch Out</th>
                                    <th style={{ padding: '12px', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Production</th>
                                    <th style={{ padding: '12px', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Break</th>
                                    <th style={{ padding: '12px', borderBottom: '1px solid #dee2e6', fontWeight: 'bold' }}>Overtime</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* Table data */}
                                  <tr>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6', height: '40px' }}>1</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>19 Feb 2019</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>10 AM</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>7 PM</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>9 hrs</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>1 hr</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>0</td>
                                  </tr>
                                  <tr>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6', height: '40px' }}>2</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>20 Feb 2019</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>10 AM</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>7 PM</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>9 hrs</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>1 hr</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>0</td>
                                  </tr>
                                  <tr>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6', height: '40px' }}>3</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>21 Feb 2019</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>10 AM</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>7 PM</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>9 hrs</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>1 hr</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>0</td>
                                  </tr>
                                  <tr>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6', height: '40px' }}>5</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>22 Feb 2019</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>10 AM</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>7 PM</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>9 hrs</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>1 hr</td>
                                    <td style={{ padding: '12px', borderBottom: '1px solid #dee2e6' }}>0</td>
                                  </tr>
                                  {/* Additional rows */}
                                </tbody>
                              </table>
                            </div>

                          </div>




                        </div>
                        <div className="d-flex justify-content-center mb-3">
                          <button type="button" className="custom-btn btn-3" onClick={handleNextNestedsss}>
                            Next
                          </button>
                        </div>

                      </Tab>

                      <Tab eventKey="subStep6" title={<span style={{
                        fontWeight: '500', color: '#f58938', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', padding: "2px", fontSize: '14px',
                      }}>STAGE 6</span>} >





                        <div className="row" >
                          <div className="container-fluid " id="table-container" style={{ paddingTop: '20px' }}>
                            <div className="container">
                              <div className="row" style={{ fontSize: "22px" }}>
                                <div></div>

                                <div className="a4-container" style={{
                                  width: "794px", // A4 width in pixels
                                  height: "700px", // A4 height in pixels
                                  backgroundColor: "#ffffff", // Background color
                                  padding: "20px", // Padding around the content
                                  border: "1px solid rgb(33 29 29)", // Optional: add a border
                                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: add shadow for depth
                                  margin: "0 auto", // Center the A4 container
                                  overflow: "hidden" // Prevent content overflow
                                }}>
                                  <div className="form-contentsL">
                                    <div style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      padding: "0px",
                                      flexWrap: "wrap",
                                      paddingBottom: "16px",
                                      marginTop: "0px"
                                    }}>
                                      <img src={logo} alt="" style={{ width: "83px", height: "70px", marginRight: "20px" }} />
                                      <h3 style={{ color: "#f58938", margin: 0, wordBreak: "break-word" }}>
                                        INTERNATIONAL INSTITUTE OF AUTOMATE
                                      </h3>


                                    </div>
                                    <h2 style={{ textAlign: "center", color: "rgb(245 136 66)", marginBottom: "10px" }}>
                                      Certification Of Completion
                                    </h2>
                                    <h3 style={{ textAlign: "center", color: "#5B4B99", marginBottom: "11px" }}>
                                      This Certifies that
                                    </h3>
                                    <h3 style={{ textAlign: "center", color: "#5B4B99", fontWeight: "bold", marginBottom: "15px" }}>
                                      B. Naresh
                                    </h3>
                                    <h3 style={{ textAlign: "center", color: "#5B4B99", marginBottom: "11px", wordBreak: "break-word" }}>
                                      Has successfully Completed the Course Of
                                    </h3>
                                    <h3 style={{ textAlign: "center", color: "#5B4B99", fontWeight: "bold", marginBottom: "15px" }}>
                                      Online Robotics
                                    </h3>
                                    <h3 style={{ textAlign: "center", color: "#5B4B99", marginBottom: "5px" }}>
                                      Duration: 160 HRS
                                    </h3>

                                    <div
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns: "auto auto",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "50px",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <h3 style={{ textAlign: "center", color: "#5B4B99" }}>
                                        Start Date: 10-02-2024
                                      </h3>
                                      <h3 style={{ textAlign: "center", color: "#5B4B99" }}>
                                        End Date: 15-08-2024
                                      </h3>
                                    </div>

                                    <br />

                                    <div className="date-signnature"
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns: "auto auto",
                                        justifyContent: "center",
                                        alignItems: "center",

                                        marginTop: "0px",
                                        gap: "357px"

                                      }}
                                    >
                                      <h5 style={{ color: "#5B4B99", marginBottom: "5px" }}>Signature:</h5>
                                      <h5 style={{ color: "#5B4B99", marginBottom: "5px" }}>Date: 15-08-2024</h5>
                                    </div>

                                    <h3 style={{ textAlign: "center", marginTop: "40px" }}>www.iiautomate.com</h3>
                                  </div>
                                </div>



                                <br />






                                <div className="row" style={{ justifyContent: 'center', padding: '20px' }}>
                                  <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                    <button type="button" className="custom-btn btn-3" onClick={handleDownloadsL}>
                                      Download
                                    </button>
                                    <button type="button" className="custom-btn btn-3" onClick={handlePrintL}>
                                      Print
                                    </button>
                                  </div>
                                </div>


                              </div>
                            </div>

                          </div>
                          {/* Add other fields for step 2 */}
                        </div>
                      </Tab>





                    </Tabs>

                    {/* Second-Level Sub Tabs */}

                  </Tab>

                  {/* Sub Tab 2 */}
                  <Tab eventKey="step2"
                    title={<span style={{
                      fontWeight: '500', color: '#f58938', color: '#f58938', height: '39px', display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>ENQUIRY</span>} >


                    <Contacts />
                  </Tab>
                </Tabs>
              </Tab>

              {/* Parent Tab 2 */}
              <Tab
                eventKey="enquiry"
                title={<span style={{ color: '#f58938', fontWeight: '500' }}>WEBINAR</span>}
              >
                <Tabs
                  id="sub-nested-tab-example"
                  activeKey={subNestedKey}
                  onSelect={(k) => setSubNestedKey(k)}
                  className="mb-3"
                  style={{ marginTop: '-20px', justifyContent: 'space-evenly', background: '#65646a', width: '101.8%', marginLeft: '-11px', padding: "5px", fontSize: '20px', }}
                >
                  {/* Sub Tab under REGISTRATION */}
                  <Tab eventKey="subStep1" title={<span style={{
                    fontWeight: '500', color: '#f58938', display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center', padding: "3px", fontSize: '20px',
                  }}>STAGE 1</span>} >

                    <div>

                      <div className="row" style={{ paddingBottom: '25px' }}>
                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Full Name*</Form.Label>
                          <Form.Control
                            type="text"

                            placeholder="Enter Name"

                            name="fullName" value={formData.fullName} onChange={handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Gender*</Form.Label>
                          <Form.Select
                            aria-label="Default select example "
                            name="gender" value={formData.gender} onChange={handleChange}
                            required
                          >
                            <option>Enter Your Gender</option>
                            <option value="Male" >Male</option>
                            <option value="Female" >Female</option>
                            <option value="Others" >Others</option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>DOB*</Form.Label>
                          <Form.Control
                            type="date"

                            placeholder="date of birth"
                            name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>EMail-Id*</Form.Label>
                          <Form.Control
                            type="email"

                            placeholder="Enter Your Email"

                            name="emailId" value={formData.emailId} onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </div>

                      <div className="row" style={{ paddingBottom: "25px" }}>
                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Contact No.*</Form.Label>
                          <Form.Control
                            type="number"

                            placeholder="Enter Your Contact No."
                            name="contactNumber" value={formData.contactNumber} onChange={handleChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Source*</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="categories"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select Field</option>
                            <option value="SocialMedia">SocialMedia</option>
                            <option value="Councillor">Councillor</option>
                            <option value="Reference">Reference</option>



                          </Form.Select>
                        </Form.Group>
                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Source Name*</Form.Label>
                          <Form.Control
                            type="text"

                            placeholder="Enter Sourse Name"

                            name="fullName" onChange={handleChange}
                            required
                          />

                        </Form.Group>

                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Webinar*</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="categories"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select Field</option>
                            <option value="Cources">Tool & fixture design</option>
                            <option value="Webinars">Online robotics</option>
                            <option value="Webinars">Offline robotics</option>
                            <option value="Webinars">Java</option>
                            <option value="Webinars">React Js</option>
                            <option value="Webinars">Flutter</option>
                          </Form.Select>
                        </Form.Group>


                        {/* <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Particular*</Form.Label>
                           <Form.Select
                             aria-label="Default select example"
                             name="course"
                             value={formData.course} 
                             onChange={handleFieldChange} 
                             required
                            >
                            <option value="">Choose your course</option>
                          {courses.map((course, index) => (
                          <option key={index} value={course}>
                          {course}
                          </option>
                          ))}
                        </Form.Select>
                        </Form.Group> */}






                      </div>
                      <div className="row" style={{ paddingBottom: "25px" }}>

                        <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Webinar Duration*</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="courseDuration"
                            value={formData.courseDuration}  // Correct value field
                            onChange={handleFieldChange}
                            required
                          >
                            <option value="">Choose your course</option>
                            {courseduration.map((duration, index) => (
                              <option key={index} value={duration}>
                                {duration.replace(/_/g, ' ')} {/* Replace underscores with spaces */}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="col-md-3" controlId="subFieldSelect">
                          <Form.Label>Time*</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="timings"
                            value={formData.timings}
                            onChange={(e) => setFormData({ ...formData, timings: e.target.value })}
                            required
                          >
                            <option value="">Select Sub-Field</option>
                            {availableSubFields.map((subField) => (
                              <option key={subField.value} value={subField.value}>
                                {subField.label}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>


                        <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Mode*</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="mode"
                            value={formData.mode} // Ensure the value is linked to formData.mode
                            onChange={handleChange1} // Update the form data on change
                            required
                          >
                            <option value="">Select Mode</option> {/* Optional placeholder */}
                            <option value="Offline">OFFLINE</option>
                            <option value="Online">ONLINE</option>
                          </Form.Select>
                        </Form.Group>


                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Webinar Price*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={offlinerobotics?.coursePrice || "Price will appear here"}
                            value={formData.coursePrice || ""}

                            required
                          />
                        </Form.Group>






                      </div>

                      {/* Add the Next button */}
                      <button type="button" className="custom-btn btn-3" onClick={handleNextNested}>
                        Next
                      </button>
                    </div>

                  </Tab>

                  <Tab eventKey="subStep2" title={<span style={{
                    fontWeight: '500', color: '#f58938', height: '39px', display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>STAGE 2</span>} >
                    <div>
                      <div className="row" style={{ paddingBottom: '25px' }}>
                        <div className="container-fluid " id="table-container" style={{ paddingTop: '60px' }}>
                          <div className="container">
                            {/* <h2
                style={{
                  textAlign: "center",
                  color: "#5B4B99",
              
                
                }}
              >
                Receipt
              </h2> */}
                            <div className="row" style={{ fontSize: "22px" }}>
                              <div>


                              </div>
                              {/* <hr></hr> */}
                              {/* <h2 style={{ textAlign: "center", padding: "10px",paddingTop:'40px',paddingBottom:"40PX" }}>
              INTERNATIONAL INSTITUTE OF AUTOMATE
            </h2> */}
                              <div >

                                <br></br>
                                {/* <div class="row" style={{textAlign:"center"}}>
              <p>www.iiautomate.com</p>
              </div> */}
                                <hr></hr>

                              </div>

                            </div>
                          </div>

                        </div>
                        {/* Add other fields for step 2 */}
                      </div>

                      <button type="button" className="custom-btn btn-3" onClick={handleNextNesteds}>
                        Next
                      </button>
                    </div>
                  </Tab>




                </Tabs>

                {/* Second-Level Sub Tabs */}

              </Tab>
              <Tab
                eventKey="placements"
                title={<span style={{ color: '#f58938', fontWeight: '500' }}>PLACEMENTS</span>}
              >
                <Tabs
                  id="sub-nested-tab-example"
                  activeKey={subNestedKey}
                  onSelect={(k) => setSubNestedKey(k)}
                  className="mb-3"
                  style={{ marginTop: '-20px', justifyContent: 'space-evenly', background: '#65646a', width: '101.8%', marginLeft: '-11px', height: '55px' }}
                >
                  {/* Sub Tab under REGISTRATION */}
                  <Tab eventKey="subStep1" title={<span style={{
                    fontWeight: '500', color: '#f58938', height: '39px', display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>STAGE 1</span>} >

                    <div>

                      <div className="row" style={{ paddingBottom: '25px' }}>
                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Full Name*</Form.Label>
                          <Form.Control
                            type="text"

                            placeholder="Enter Name"

                            name="fullName" value={formData.fullName} onChange={handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Gender*</Form.Label>
                          <Form.Select
                            aria-label="Default select example "
                            name="gender" value={formData.gender} onChange={handleChange}
                            required
                          >
                            <option>Enter Your Gender</option>
                            <option value="Male" >Male</option>
                            <option value="Female" >Female</option>
                            <option value="Others" >Others</option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>DOB*</Form.Label>
                          <Form.Control
                            type="date"

                            placeholder="date of birth"
                            name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>EMail-Id*</Form.Label>
                          <Form.Control
                            type="email"

                            placeholder="Enter Your Email"

                            name="emailId" value={formData.emailId} onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </div>

                      <div className="row" style={{ paddingBottom: "25px" }}>
                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Contact No.*</Form.Label>
                          <Form.Control
                            type="number"

                            placeholder="Enter Your Contact No."
                            name="contactNumber" value={formData.contactNumber} onChange={handleChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Source*</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="categories"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select Field</option>
                            <option value="SocialMedia">SocialMedia</option>
                            <option value="Councillor">Councillor</option>
                            <option value="Reference">Reference</option>



                          </Form.Select>
                        </Form.Group>
                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Source Name*</Form.Label>
                          <Form.Control
                            type="text"

                            placeholder="Enter Sourse Name"

                            name="fullName" onChange={handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Registration Fee*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={offlinerobotics?.coursePrice || "Price will appear here"}
                            value={formData.coursePrice || ""}

                            required
                          />
                        </Form.Group>



                      </div>



                      {/* Add the Next button */}
                      {/* <button type="button" className="custom-btn btn-3" onClick={handleNextNested}>
                Next
              </button> */}
                    </div>

                  </Tab>

                  <Tab eventKey="subStep2" title={<span style={{
                    fontWeight: '500', color: '#f58938', height: '39px', display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>STAGE 2</span>} >
                    <div>
                      <div className="row" style={{ paddingBottom: '25px' }}>
                        <div className="container-fluid " id="table-container" style={{ paddingTop: '60px' }}>
                          <div className="container">
                            <h2
                              style={{
                                textAlign: "center",
                                color: "#5B4B99",


                              }}
                            >
                              Receipt
                            </h2>
                            <div className="row" style={{ fontSize: "22px" }}>
                              <div>


                              </div>
                              {/* <hr></hr> */}
                              {/* <h2 style={{ textAlign: "center", padding: "10px",paddingTop:'40px',paddingBottom:"40PX" }}>
              INTERNATIONAL INSTITUTE OF AUTOMATE
            </h2> */}
                              <div >

                                <br></br>
                                {/* <div class="row" style={{textAlign:"center"}}>
              <p>www.iiautomate.com</p>
              </div> */}
                                <hr></hr>

                              </div>

                            </div>
                          </div>

                        </div>
                        {/* Add other fields for step 2 */}
                      </div>

                      <button type="button" className="custom-btn btn-3" onClick={handleNext}>
                        Next
                      </button>
                    </div>
                  </Tab>
                  <Tab eventKey="subStep3" title={<span style={{
                    fontWeight: '500', color: '#f58938', height: '39px', display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>STAGE 3</span>} >
                    <div>

                      <h2
                        style={{
                          textAlign: "center",
                          color: "#5B4B99",
                          paddingBottom: "50px",
                          paddingTop: "30px",
                        }}
                      >
                        Terms And Conditions
                      </h2>
                      <div className="row" style={{ paddingBottom: '25px' }}>
                        <p style={{ textAlign: "justify" }}>
                          By submitting the IIA Automate course payment form, you agree to the terms and conditions
                          outlined. Payment is required in full upon registration, and refunds are only available
                          within 14 days of payment, provided course materials have not been accessed. Course access
                          is granted after successful payment and will remain available for the specified duration.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          All course materials are the intellectual property of IIA Automate and may not be reproduced
                          without permission. Participants must adhere to professional conduct during the course.
                          Personal data will be handled according to our Privacy Policy. IIA Automate reserves the
                          right to update these terms at any time.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          Any breach of these terms and conditions may result in the immediate termination of course access
                          without refund. IIA Automate retains the right to revoke access if it is determined that the terms
                          have been violated, including but not limited to sharing login credentials or course materials
                          with unauthorized individuals. Participants are expected to complete the course independently, and
                          any form of academic dishonesty will not be tolerated.
                        </p>

                        <p style={{ textAlign: "justify" }}>
                          The course content is designed for educational purposes only, and IIA Automate does not guarantee
                          specific career outcomes or certifications upon completion. While we strive to provide accurate and
                          up-to-date information, we are not liable for any errors or omissions in the course materials. It is
                          the participant's responsibility to ensure they meet any technical or hardware requirements needed
                          to complete the course.
                        </p>


                        <div className="checkbox-container" style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="checkbox"
                            id="terms"
                            checked={isTermsAccepted}
                            onClick={handleModalSubmit} // Use the new handleModalSubmit function
                            required
                            style={{ display: "none" }}
                          />
                          <div
                            onClick={handleCheckboxClick}
                            style={{
                              cursor: "pointer",
                              width: "15px",
                              height: "15px",
                              border: "2px solid green",
                              borderRadius: "4px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: isTermsAccepted ? "green" : "white",
                              marginTop: "-6px",
                            }}
                          >
                            {isTermsAccepted && (
                              <FontAwesomeIcon icon={faCheckSquare} style={{ color: "white" }} />
                            )}
                          </div>
                          <label htmlFor="terms" style={{ marginLeft: "10px", cursor: "default" }}>
                            I agree to the{" "}
                            <span
                              style={{ color: "blue", cursor: "pointer" }}
                              onClick={handleTermsClick}
                            >
                              Terms and Conditions
                            </span>
                          </label>


                        </div>
                        {/* Add other fields for step 3 */}
                      </div>
                      {/* Add the Previous and Submit buttons */}

                      <button className="custom-btn btn-3"

                        onClick={handleNext}


                      >
                        Next
                      </button>
                    </div>
                  </Tab>
                  <Tab eventKey="subStep4" title={<span style={{
                    fontWeight: '500', color: '#f58938', height: '39px', display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>STAGE 4</span>} >
                    <div >
                      <h2
                        style={{
                          textAlign: "center",
                          color: "#5B4B99",
                          paddingBottom: "50px",
                          paddingTop: "30px",
                        }}
                      >
                        Registration Fee
                      </h2>
                      <div style={{ paddingBottom: '25px', textAlign: 'center' }}>
                        <Form.Group
                          className="col-md-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Registration Fee*</Form.Label>
                          <Form.Control
                            type="text"

                            placeholder="Enter Name"

                            name="fullName" value={formData.fullName} onChange={handleChange}
                            required
                          />
                        </Form.Group>


                        {/* Add other fields for step 1 */}
                      </div>
                      {/* Add the Next button */}
                      <button type="button" className="custom-btn btn-3" onClick={handleNext}>
                        Pay
                      </button>
                    </div>
                  </Tab>

                </Tabs>



              </Tab>
            </Tabs>
          </div>
        </div>
      </section>


      {/* Modal for Terms and Conditions */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>

        <Modal.Body style={{ maxHeight: '500px', overflowY: 'auto', scrollbarWidth: 'none' }}>
          {/* Add your terms and conditions text here */}
          <p style={{ textAlign: "justify" }}>
            By submitting the IIA Automate course payment form, you agree to the terms and conditions
            outlined. Payment is required in full upon registration, and refunds are only available
            within 14 days of payment, provided course materials have not been accessed. Course access
            is granted after successful payment and will remain available for the specified duration.
          </p>
          <p style={{ textAlign: "justify" }}>
            All course materials are the intellectual property of IIA Automate and may not be reproduced
            without permission. Participants must adhere to professional conduct during the course.
            Personal data will be handled according to our Privacy Policy. IIA Automate reserves the
            right to update these terms at any time.
          </p>
          <p style={{ textAlign: "justify" }}>
            Any breach of these terms and conditions may result in the immediate termination of course access
            without refund. IIA Automate retains the right to revoke access if it is determined that the terms
            have been violated, including but not limited to sharing login credentials or course materials
            with unauthorized individuals. Participants are expected to complete the course independently, and
            any form of academic dishonesty will not be tolerated.
          </p>

          <p style={{ textAlign: "justify" }}>
            The course content is designed for educational purposes only, and IIA Automate does not guarantee
            specific career outcomes or certifications upon completion. While we strive to provide accurate and
            up-to-date information, we are not liable for any errors or omissions in the course materials. It is
            the participant's responsibility to ensure they meet any technical or hardware requirements needed
            to complete the course.
          </p>


          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              variant="secondary"
              onClick={handleModalSubmit}
              style={{ backgroundColor: "linear-gradient(0deg, rgb(247, 136, 55) 0%, rgb(243 166 109) 100%)" }}
            >
              Submit
            </Button>

          </div>
        </Modal.Body>

      </Modal>


    </div>
  )
}

export default ReactJsForm