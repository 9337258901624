import React from 'react';
import { useInView } from 'react-intersection-observer';
import Slider from "react-slick"; // Import the Slider component from react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import logo2 from "../img/Partner_logo/logo2.jpg";
import logo3 from "../img/Partner_logo/logo4.png";
import logo4 from "../img/Partner_logo/logo5.png";
import logo5 from "../img/Partner_logo/logo6.png";
import logo6 from "../img/Partner_logo/logo7.png";
import logo7 from "../img/Partner_logo/logo8.png";
import educationpartner from "../img/educational-partners/christu-jyothi.jpg";
import klr from "../img/educational-partners/klr.jpg";
import vidwan from "../img/educational-partners/vidwan-sarvatra.jpg";

const OureducationsPathners = () => {
  const { ref: educationPartnerRef, inView: educationPartnerInView } = useInView();
  const { ref: logo2Ref, inView: logo2InView } = useInView();
  const { ref: logo3Ref, inView: logo3InView } = useInView();
  const { ref: klrRef, inView: klrInView } = useInView();
  const { ref: logo5Ref, inView: logo5InView } = useInView();
  const { ref: logo6Ref, inView: logo6InView } = useInView();
  const { ref: logo7Ref, inView: logo7InView } = useInView();
  const { ref: vidwanRef, inView: vidwanInView } = useInView();

  // Slider settings
  const settingskj = {
    dots: false,
    infinite: true,
    centerPadding: '5px',
    arrows: false,
    // Adds 50px padding on both sides
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (

    <div>
      <style>{
        `
        .slick-next:before{
           margin-left: -51px;}
        `}

      </style>
      <div className="container-fluid" style={{ paddingBottom: "20px", backgroundColor: "rgb(54, 48, 74)" }}>
        <div className="container">
          <div className="row" style={{ textAlign: "center" }}>
            <div className="section-head text-black text-center">
              <h1 className='educationpatner-content' style={{ color: "#fff", fontSize: "40px", lineHeight: "1.3" }}>
                OUR EDUCATION<span style={{ color: "#F58634" }}>PARTNERS</span>
              </h1>
              <div className="bg-primary"></div>
            </div>
            <Slider {...settingskj}>
              <div className=" alg">
                <div className={`single-partner ${educationPartnerInView ? 'visible' : ''}`} ref={educationPartnerRef} style={{ backgroundColor: "#aca3cb", padding: "20px", width: "312px", height: "143px", marginRight: "25px" }}>
                  <img src={educationpartner} alt="Image" style={{ height: "100px", width: "200px" }} /> {/* Increased width and height */}
                </div>
              </div>
              <div className=" alg">
                <div className={`single-partner ${logo3InView ? 'visible' : ''}`} ref={logo3Ref} style={{ backgroundColor: "#aca3cb", padding: "20px", width: "312px", height: "143px", marginRight: "25px" }}>
                  <img src={logo3} alt="Image" style={{ height: "100px", width: "200px" }} /> {/* Increased width and height */}
                </div>
              </div>
              <div className=" alg">
                <div className={`single-partner ${klrInView ? 'visible' : ''}`} ref={klrRef} style={{ backgroundColor: "#aca3cb", padding: "20px", width: "312px", height: "143px", marginRight: "25px" }}>
                  <img src={klr} alt="Image" style={{ height: "100px", width: "200px" }} /> {/* Increased width and height */}
                </div>
              </div>
              <div className="alg">
                <div className={`single-partner ${logo5InView ? 'visible' : ''}`} ref={logo5Ref} style={{ backgroundColor: "#aca3cb", padding: "20px", width: "312px", height: "143px", marginRight: "25px" }}>
                  <img src={logo5} alt="Image" style={{ height: "100px", width: "200px" }} /> {/* Increased width and height */}
                </div>
              </div>
              {/* You can add more logos in the same way */}
            </Slider>

          </div>
        </div>
      </div>
    </div>
  );
};

export default OureducationsPathners;
