import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
const EnrollProvideServices = () => {
  return (
    <div>
         <section id="cards-container" >
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="text-center">
                <span
                  style={{
                    color: "#F6944A",
                    fontSize: "30px",
                    lineHeight: "1.8",
                  }}
                >
                  Our Services
                </span>
              </div>
              <div className="col-md-6 card-wrapper">
                <div className="card" style={{ height: "320px" }}>
                  <div>
                    <div className="card-top gradient-greens">
                      <div className="icon">
                      <FontAwesomeIcon icon="fa-solid fa-graduation-cap" flip  />
                      </div>
                    </div>
                    <div className="card-content">
                      <h4 className="card-title" style={{ paddingTop: "40px" }}>
                      COURSES
                      </h4>
                    </div>
                  </div>
                  <div className="overlay zoom" style={{ height: "320px" }}>
                    <h4 style={{ color: "#695B9D" }}>Our Courses</h4>
                    <p
                      style={{
                        color: "#000",
                        fontSize: "14px",
                        textAlign: "justify",
                      }}
                    >
                    Courses We Offering<br/>
                    1. Tool and Fixture Design<br/>
                    2. Offline Robotics<br/>
                    3. Online Robotics<br/>

                    </p>
                    <Link to={'/contact-us'}> <button className="custom-btn btn-3"><span>Apply Now</span></button></Link>
                  </div>
                </div>
              </div>        
              <div className="col-md-6 card-wrapper">
                <div className="card" style={{ height: "320px" }}>
                  <div>
                    <div className="card-top gradient-greens">
                      <div className="icon">
                      <FontAwesomeIcon icon="fa-solid fa-microchip" flip />
                      </div>
                    </div>
                    <div className="card-content">
                      <h4 className="card-title" style={{ paddingTop: "40px" }}>
                       TECHNOTALKS
                      </h4>
                    </div>
                  </div>
                  <div className="overlay zoom" style={{ height: "320px" }}>
                    <h4 style={{ color: "#695B9D" }}>Registraton for Service</h4>
                    <p
                      style={{
                        color: "#000",
                        fontSize: "14px",
                        textAlign: "justify",
                      }}
                    >
                    Courses We Offering<br/>
                    1. Fixture Design<br/>
                    2. Industrial Automation<br/>
                    3. Digital Facility Solutions<br/>
                    </p>
                    <Link to={'/contact-us'}> <button className="custom-btn btn-3"><span>Apply Now</span></button></Link>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4 card-wrapper">
                <div className="card" style={{ height: "320px" }}>
                  <div>
                    <div className="card-top gradient-greens">
                      <div className="icon">                    
                      <FontAwesomeIcon icon="fa-brands fa-servicestack" flip />
                      </div>
                    </div>
                    <div className="card-content">
                      <h4 className="card-title" style={{ paddingTop: "40px" }}>
                       PLACEMENTS
                      </h4>
                    </div>
                  </div>
                  <div className="overlay zoom" style={{ height: "320px" }}>
                    <h4 style={{ color: "#695B9D" }}>Placements</h4>
                    <p
                      style={{
                        color: "#000",
                        fontSize: "14px",
                        textAlign: "justify",
                      }}
                    >
                     Selected Candidates Through Our Institutions
                    </p>
                    <Link to={'/placements'}> <button className="custom-btn btn-3"><span>placements</span></button></Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default EnrollProvideServices
