import React from 'react'
import { Form } from "react-bootstrap";
const TechPersonalInformationForm = () => {

  { window.scrollTo({ top: 0, behavior: 'smooth' }); };


  return (
    <div>
        <section>
      <div className="container-fluid">
        <div
          className="container  boderform"
          style={{
            backgroundColor: "#efefef",
            paddingBottom: "30px",
            borderRadius: "10px",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              color: "#5B4B99",
              paddingBottom: "50px",
              paddingTop:"30px"
            }}
          >
            PERSONAL INFORMATION
          </h2>

          <div className="row" style={{ paddingBottom: "25px" }}>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Full Name*</Form.Label>
              <Form.Control
                type="email"
                name="full_name"
                placeholder="Enter Name"
               
              />
            </Form.Group>

            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Gender*</Form.Label>
              <Form.Select
                aria-label="Default select example "
                name="gender"
               
              >
                <option>Enter Your Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </Form.Select>
            </Form.Group>

            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>DOB*</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                placeholder="date of birth"
                
              />
            </Form.Group>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>EMail-Id*</Form.Label>
              <Form.Control
                type="email"
                name="email_id"
                placeholder="date of birth"
            
              />
            </Form.Group>
          </div>
          <div className="row" style={{ paddingBottom: "25px" }}>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Contact No.*</Form.Label>
              <Form.Control
                type="number"
                name="contact_no"
                placeholder="Enter Your Contact No."
                
              />
            </Form.Group>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Emergency Contact No.*</Form.Label>
              <Form.Control
                type="number"
                name="emergency_contact_no"
                placeholder="Enter EC No."
                
              />
            </Form.Group>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Address*</Form.Label>
              <Form.Control
                type="text"
                name="address"
                placeholder="Enter Your Address"
                
              />
            </Form.Group>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Zip Code*</Form.Label>
              <Form.Control
                type="text"
                name="zip_code"
                placeholder="Enter The Zip Code"
                
              />
            </Form.Group>
          </div>
          <div className="row" style={{ paddingBottom: "25px" }}>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Aadhar No.*</Form.Label>
              <Form.Control
                type="number"
                name="aadhar_no"
                placeholder="Enter The Aadhar No"
                
              />
            </Form.Group>
            <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Technotalk course*</Form.Label>
              <Form.Select
                aria-label="Default select example "
                name="gender"
                
              >
                <option>Choose your Technotalk course</option>
                <option value="student"> Fixture design</option>
                <option value="Trainer">Industrial Automation</option>
                <option value="Speaker">Digital Facility Solution</option>
              </Form.Select>
            </Form.Group>
            
          </div>
          <button className="custom-btn btn-3"><span>Submit</span></button>
        </div>
      </div>
    </section>
    </div>
  )
}

export default TechPersonalInformationForm