import React, { useState } from "react";

const CoursesBrief = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  function MyChildComponent() {
    return <div><div className="">
                
    <div className="">
      <section
        className="about-area about-style-three about-us-mobile"
        style={{ textAlign: "justify" }}
      >
        <div className="container">
          <div className="row align-items-center " style={{paddingBottom:"10px"}}>
            <div
              className="about-content page-style ml-15  "
              style={{ textAlign: "justify", fontSize: "18px" }}
            >
              <div className="text-center">
                <span
                  style={{
                    fontSize: "30px",
                    color: "#5B4B99",
                   
                  }}
                >
                  Key Features:
                </span>
              </div>
            </div>
            <div className="col-lg-6">
            <div
                className="about-content page-style ml-15 bdstyle conheigh"
                style={{ textAlign: "justify" }}
              >
                <h4>Cutting-edge Curriculum:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                     
                    }}
                  >
                    Our curriculum is carefully crafted to cover
                    core mechanical engineering principles, ensuring
                    a strong foundation in theoretical knowledge.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                     
                    }}
                  >
                    Specialized courses in areas such as robotics,
                    automotive engineering, and sustainable energy
                    systems provide students with a well-rounded
                    education.
                  </li>
                </ul>
                <h4>Practical Learning:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Laboratory sessions and real-world projects
                    complement classroom learning, allowing students
                    to apply theoretical concepts in a hands-on
                    environment.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    {" "}
                    Industry-relevant software tools and
                    simulations, including CAD and FEA, are
                    integrated into the coursework.
                  </li>
                </ul>
                <h4>Expert Faculty:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Learn from seasoned professionals and experts in
                    the field who bring a wealth of industry
                    experience and academic knowledge.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Small class sizes foster personalized attention
                    and mentorship opportunities.
                  </li>
                </ul>
                <h4>State-of-the-Art Facilities:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Our institution boasts modern laboratories
                    equipped with the latest technologies, providing
                    students with a conducive environment for
                    experimentation and exploration
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                
                    }}
                  >
                    Dedicated spaces for collaborative projects and
                    design thinking sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
            <div className="about-content page-style ml-15 bdstyle conheigh"  style={{paddingBottom:"185px"}}>
                <div className="text-center"></div>
                <h4>Industry Partnerships:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Collaborations with leading companies and
                    organizations provide students with internship
                    and placement opportunities.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Regular guest lectures and industry visits
                    ensure a real-world perspective on engineering
                    challenges.
                  </li>
                </ul>
                <h4>Soft Skills Development:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    In addition to technical expertise, our
                    curriculum emphasizes the development of
                    communication, teamwork, and project management
                    skills.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Workshops and seminars on engineering ethics and
                    professionalism.
                  </li>
                </ul>
                <h4>Flexibility and Electives:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Tailor your learning experience with elective
                    courses that align with your interests and
                    career goals.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Flexible scheduling options to accommodate
                    diverse student needs.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div></div>;
  }
  return (
    <div>
      <article>
        <div className="snippet">
          <section className="about-area about-style-three about-us-mobile">
            <div className="container">
              <div className="row align-items-center rev">
                <div className="about-content page-style ml-15">
                  <div className="text-center">
                    <span
                      className="top-title"
                      style={{ fontSize: "35px", paddingBottom: "20px", color: "#5B4B99"}}
                    >
                     MECHANICAL  <span style={{ color: " #F58634" }}>ENGINEERING</span>
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content page-style ml-15">
                    {/* <h1>MECHANICAL ENGINEERING</h1> */}
                    <p
                      style={{
                        textAlign: "justify",
                        textIndent: "50px",
                        fontSize: "18px",
                        // lineHeight: "1.4",
                      }}
                    >
                      {" "}
                      Welcome to the Mechanical Engineering Training Institute,
                      where innovation meets expertise. Our institution is
                      dedicated to providing a comprehensive and hands-on
                      learning experience for aspiring mechanical engineers. We
                      take pride in shaping future leaders in the field through
                      a curriculum designed to meet the industry's dynamic
                      demands. A career in mechanical engineering offers a
                      dynamic landscape of opportunities, encompassing design,
                      development, and innovation across various industries.
                      Mechanical engineers craft solutions that power our modern
                      world, from creating intricate machines to enhancing
                      energy systems and advancing transportation technologies.
                      It plays a critical role in various industries by applying
                      their knowledge and skills to design, innovate, and
                      maintain mechanical systems. Their work contributes to
                      advancements in technology, efficiency, safety, and
                      overall progress across numerous sectors, making them
                      indispensable in the modern world.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-img text-center">
                    {/* <img src={courseoffered} alt="Image" /> */}
                    <div className="videoWrapper">
                      <iframe
                        width="100%"
                        height="330"
                        src="https://www.youtube.com/embed/y0YoTKv6was?si=pDFaCVQ3__MR78oe"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            
            <div style={{paddingBottom:"10px"}}>
              <button className="showbut" onClick={toggleVisibility}>
                Know {isVisible ? "Less" : "More"}
              </button>
              {isVisible && <MyChildComponent />}
            </div>
          </section>
        </div>
      </article>
    </div>
  );
};

export default CoursesBrief;
