import React from 'react'
import MobileappilcationCorses from "./MobileappilcationCorses";
import AllCourses from "./AllCourses";
import Partners from './Partners'
const Mobileapplication = () => {
  return (
    <div>
      <MobileappilcationCorses></MobileappilcationCorses> 
      <AllCourses></AllCourses>
      <Partners></Partners>
    </div>
  )
}

export default Mobileapplication
