import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TechnoTalksCources from "./TechnoTalksCources";
import sensor from "../img/section-images/sensor.jpg";
import DigitalFacilityBanner from "./DigitalFacilityBanner";
import DigitalfacilityTabs from "./DigitalfacilityTabs";

const DigitalSolution = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [industrialauto, setIndustrialauto] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://iiaserver.iiautomate.com/IIA-Website-1.0.0/dfs/get"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setIndustrialauto(result);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  function MyChildComponent() {
    return (
      <div>
        <div className="" style={{ paddingTop: "10px" }}>
          <div class="">
            <section className="about-area" style={{ textAlign: "justify" }}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="about-content page-style ml-15"></div>
                  <div className="col-lg-6">




                    {/* <div className="about-img text-center">
                      <img src={sensor} alt="Image" />
                    </div> */}

               <div className="about-content page-style ml-15">
                 <div className="text-center" style={{marginTop: "0px"}}>
                   <span
                       className="top-title"
                                  style={{
                                   fontSize: "35px",
                                paddingBottom: "15px",
                                color: "#f08821",
                                  }}
                                   >
                                Key Features
                             </span>
                         </div>
                     </div>

                    <ul style={{ textAlign: "justify" }}>
                      <li>Digital Facility Solutions encompass the use of advanced digital tools, 
                        technologies, and platforms to manage, monitor, and optimize the operations 
                        of physical facilities such as buildings, factories, campuses, and infrastructure.</li>
                      <li>A BMS is a centralized system that controls and monitors a building’s electrical.</li>
                      <li>EMS focuses on monitoring and optimizing energy consumption within a facility.</li>
                      <li>Facilities can use data from occupancy sensors to optimize the use of space. </li>
                      <li>AI and ML analyze data from IoT sensors and facility systems to optimize operations.</li>
                      <li>Predictive maintenance ensures that equipment is maintained before failures occur.</li>
                    
                    
                    </ul>
                    {/* <div className="about-img text-center">
                      <img src={robotics} alt="Image" />
                    </div> */}
                  </div>
                  <div
                    className="col-lg-6"
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      lineHeight: "2",
                    }}
                  >
                    <div className="text-center">
                      <span
                        className="top-title"
                        style={{
                          fontSize: "35px",
                          paddingBottom: "20px",
                        }}
                      >
                        Webinar Details
                      </span>
                    </div>

                    {industrialauto && (
                      <table>
                        <tbody>
                          <tr>
                            <td>Webinar Name</td>
                            <td>{industrialauto.webinarName}</td>
                          </tr>
                          <tr>
                            <td>Course Duration</td>
                            <td>{industrialauto.courseDuration}</td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td>{industrialauto.date}</td>
                          </tr>
                          <tr>
                            <td>Mode</td>
                            <td>{industrialauto.mode}</td>
                          </tr>
                          <tr>
                            <td>Time</td>
                            <td>{industrialauto.timing}</td>
                          </tr>
                          <tr>
                            <td>Speaker Name</td>
                            <td>{industrialauto.speakerName}</td>
                          </tr>
                          <tr>
                            <td>Price</td>
                            <td>{industrialauto.price}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}

                    <Link to="/enroll">
                      <button className="custom-btn btn-3">
                        <span>Register Now</span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            

            {/* Other Sections */}
            {/* <section
              className="about-area about-style-three about-us-mobile"
              style={{
                textAlign: "justify",
                paddingTop: "30px",
                paddingBottom: "10px",
              }}
              >
              <div className="container">
                <div className="row align-items-center">
                  <div className="about-content page-style ml-15">
                    <div className="text-center">
                      <span
                        className="top-title"
                        style={{
                          fontSize: "30px",
                          paddingBottom: "20px",
                        }}
                      >
                        Key Features
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="about-content page-style ml-15 bdstyle conheigh"
                      style={{ textAlign: "justify" }}
                    >
                      <h5>Introduction to Industrial Automation:</h5>
                      <ul>
                        <li>Definition and purpose of industrial automation.</li>
                        <li>Historical perspective and evolution.</li>
                        <li>
                          Importance in modern manufacturing and industries.
                        </li>
                      </ul>
                      <h5>Key Components of Industrial Automation:</h5>
                      <ul>
                        <li>Control Systems: PLCs, DCS, industrial PCs.</li>
                        <li>Sensors and Actuators.</li>
                        <li>Human-Machine Interface (HMI).</li>
                        <li>Communication Networks.</li>
                      </ul>
                      <h5>Applications and Industries:</h5>
                      <ul>
                        <li>
                          Showcase real-world examples of industrial automation
                          in different sectors (e.g., manufacturing, automotive,
                          pharmaceuticals).
                        </li>
                      </ul>
                      <h5>Robotics in Industrial Automation:</h5>
                      <ul>
                        <li>
                          Overview of industrial robots and their applications.
                        </li>
                        <li>Advantages of using robotics in manufacturing.</li>
                      </ul>
                      <h5>SCADA Systems:</h5>
                      <ul>
                        <li>
                          Explanation of SCADA and its role in monitoring and
                          control.
                        </li>
                        <li>
                          Case studies illustrating successful SCADA
                          implementations.
                        </li>
                      </ul>
                      <h5>Safety in Industrial Automation:</h5>
                      <ul>
                        <li>Importance of safety systems.</li>
                        <li>
                          Examples of safety features in industrial processes.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div
                      className="about-content page-style ml-15 bdstyle conheigh"
                      style={{ textAlign: "justify" }}
                    >
                      <h5>Process Optimization and Efficiency:</h5>
                      <ul>
                        <li>How automation contributes to process optimization.</li>
                        <li>
                          Introduction to algorithms, machine learning, and data
                          analytics in industrial settings.
                        </li>
                      </ul>
                      <h5>Energy Management:</h5>
                      <ul>
                        <li>
                          How industrial automation can contribute to
                          sustainable and efficient energy usage.
                        </li>
                        <li>Examples of energy management systems.</li>
                      </ul>
                      <h5>Future Trends and Innovations:</h5>
                      <ul>
                        <li>Emerging technologies in industrial automation.</li>
                        <li>The role of AI, IoT, and Industry 4.0.</li>
                      </ul>
                      <h5>Q&A Session:</h5>
                      <ul>
                        <li>
                          Encourage participants to ask questions throughout or
                          dedicate a specific time for Q&A at the end.
                        </li>
                      </ul>
                      <h5>Closing and Resources:</h5>
                      <ul>
                        <li>Summarize key points.</li>
                        <li>Provide additional resources for further learning.</li>
                        <li>Mention any upcoming events or related webinars.</li>
                      </ul>
                      <div
                        style={{
                          paddingTop: "30px",
                          paddingBottom: "32px",
                          textAlign: "center",
                        }}
                      >
                        <Link to="/contact-us?category=technotalks">
                          <button className="custom-btn btn-3">
                            <span>Register Now</span>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <DigitalFacilityBanner />
      <DigitalfacilityTabs />
      {/* <article>
        <div class="snippet">
          <section className="about-area about-style-three about-us-mobile">
            <div className="container">
              <div className="row align-items-center rev">
                <div className="about-content page-style ml-15">
                <div className="text-center">
                <h1 style={{ color: "#524DA1", fontSize: "40px", lineHeight: "1.3" }}>
                 DIGITAL FACILITY <span style={{ color: "#F58634" }}>SOLUTIONS</span>
                </h1>
                    
                    </div>
                    </div>
                <div className="col-lg-6">
                  <div className="about-content  ml-15">
                    <p
                      style={{
                        textAlign: "justify",
                        textIndent: "50px",
                        fontSize: "22px",
                        lineHeight: "1.8",
                      }}
                    >
                      A digital facility solution typically refers to the
                      integration of digital technologies and software
                      applications to manage and optimize various aspects of
                      facility operations. This can include a wide range of
                      functionalities aimed at enhancing efficiency,
                      sustainability, and user experience within a physical
                      space. Here are some key components often associated with
                      digital facility solutions.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-img text-center">
                  <div className="videoWrapper">
                  <iframe
                        width="100%"
                        height="300"
                        src="https://www.youtube.com/embed/y0YoTKv6was?si=pDFaCVQ3__MR78oe"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="snippet">
          <MyChildComponent />
        </div>
      </article> */}

      
      <TechnoTalksCources />
    </div>
  );
};

export default DigitalSolution;
