import React from 'react';

const PrivacyPolicy = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div style={{ padding: '20px', lineHeight: '1.6' }}>
      <h1>Privacy Policy</h1>
      <p>Last Updated: September 23, 2024</p>
      
      <p>
        Welcome to IIAutomate. Your privacy is of utmost importance to us. This privacy policy explains how we collect, use, and safeguard your information when you visit our website, use our services, or engage with us.
      </p>

      <h2>1. Information We Collect</h2>
      <p>We collect different types of information to provide and improve our service to you:</p>
      <ul>
        <li>
          <strong>Personal Information:</strong> When you register, contact us, or use our services, we may collect personal details like your name, email address, phone number, and company information.
        </li>
        <li>
          <strong>Usage Data:</strong> We collect information on how our website and services are accessed, including IP address, browser type, the pages you visit, and time spent on those pages.
        </li>
        <li>
          <strong>Cookies:</strong> We use cookies and similar tracking technologies to monitor activity and hold certain information to enhance user experience.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>
        We may use the collected information for several purposes:
      </p>
      <ul>
        <li>To provide, operate, and maintain our services</li>
        <li>To improve and customize our website</li>
        <li>To respond to inquiries and provide customer support</li>
        <li>To send promotional and marketing communications (only if you consent)</li>
        <li>To prevent and address technical issues or misuse of our platform</li>
      </ul>

      <h2>3. How We Protect Your Information</h2>
      <p>
        The security of your data is important to us. We implement a variety of security measures to maintain the safety of your personal information, including encryption, secure data storage, and access control. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
      </p>

      <h2>4. Sharing Your Information</h2>
      <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following situations:</p>
      <ul>
        <li>To comply with legal requirements or protect our rights</li>
        <li>To trusted third-party service providers who assist us in operating our business (under strict confidentiality agreements)</li>
        <li>In the event of a merger, acquisition, or sale of assets, where your information may be transferred as part of the transaction</li>
      </ul>

      <h2>5. Your Data Rights</h2>
      <p>
        You have rights regarding your personal information, including the right to:
      </p>
      <ul>
        <li>Access the information we hold about you</li>
        <li>Request correction of incorrect or outdated information</li>
        <li>Request deletion of your personal data (subject to legal or contractual limitations)</li>
        <li>Withdraw your consent for data processing, where consent is the legal basis</li>
      </ul>
      <p>
        To exercise any of these rights, please contact us at <a href="mailto:support@iiautomate.com">support@iiautomate.com</a>.
      </p>

      <h2>6. Cookies</h2>
      <p>
        Our website uses cookies to improve user experience, analyze performance, and understand usage. You can control the use of cookies through your browser settings. Note that disabling cookies may affect the functionality of certain features on our website.
      </p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically for any updates.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or the way we handle your data, please contact us at:
      </p>
      <p>
        <strong>Email:</strong> <a href="mailto:info@iiautomate.com">info@iiautomate.com</a><br />
        <strong>Address:</strong> #913, Manjeera Trinity Corporate, 9th floor, Office No.913, KPHB, Hyderabad, Telangana, India - 500085
      </p>

      
    </div>
    
  );
};

export default PrivacyPolicy;
