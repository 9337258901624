import React from 'react'
import WebapplicationsCorses from "./WebapplicationsCorses";
import AllCourses from "./AllCourses";
import Partners from './Partners'
const Mobileapplication = () => {
  return (
    <div>
      <WebapplicationsCorses></WebapplicationsCorses> 
      <AllCourses></AllCourses>
      <Partners></Partners>
    </div>
  )
}

export default Mobileapplication
