import React, { useState } from "react";
import DownloadBrocherpopup from "./DownloadBrocherpopup"; // Modal
import ButtonPage from './ButtonPage'; // Button component
import offlinecourse from '../img/banners/V3.jpg'
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
const AiandMlBanner = () => {
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    return (
        <section  >
            <Carousel className="res-nav-mob no-indicators" interval={300000} controls={false}>
                <img className="d-block w-100" src={offlinecourse} alt="Tool & Fixture Design" style={{ height: "650px" }} />

                <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
                    <div className="row">
                        {/* Column 1 */}
                        <div className="col-lg-4 dtd" style={{}}>
                            <div className="" style={{ width: "110%" }}>

                                <h2 className="banner-content" style={{ fontSize: "32px", fontFamily: "Raleway!important", fontWeight: "600", }}     >
                                Unlocking Human Potential with Artificial Intelligence Solutions for Progress.
                                </h2>
                                <h2 className="banner-content" style={{ fontSize: "30px", color: "#524DA1", fontFamily: "Raleway!important", fontWeight: "bold", }}     >

                                    ARTIFICIAL <span style={{ color: "#F58842" }}> INTELLIGENCE</span>

                                </h2>
                                {/* <h2   style={{  fontSize: "40px", color: "#524DA1",  fontFamily: "roboto",  fontWeight: "800",    }}     >
          
                                 ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>
                                 </h2> */}
                                <p className="banner-contentul"
                                    style={{
                                        fontSize: "17px",

                                        fontWeight: "bold",
                                        textAlign: "justify",
                                        textIndent: "50px",
                                    }}
                                     >
                                    1. Industry-Specific AI & ML Training (ISAM)
                                </p>
                                <p className="banner-contentul"
                                    style={{
                                        fontSize: "17px",

                                        fontWeight: "bold",
                                        textAlign: "justify",
                                        textIndent: "50px",
                                    }}
                                >
                                    2. Career-Focused Skills Advancement (CFSA)
                                </p>
                                <p className="banner-contentul"
                                    style={{
                                        fontSize: "17px",

                                        fontWeight: "bold",
                                        textAlign: "justify",
                                        textIndent: "50px",
                                    }}
                                >
                                    3. 100% Placement Support
                                </p>

                            </div>
                        </div>

                        <div className="col-lg-8 dtdt ">
                            <h2 className="banner-contentof" style={{ fontSize: "32px", marginTop: "-50px", color: "#fff", fontFamily: "Raleway!important", fontWeight: "600", width: '72%', textAlign: 'center' }}    >
                                “Transform the Future with Artificial Intelligence and Machine Learning”
                            </h2>

                        </div>
                    </div>
                    <div style={{}}>
                        <div > {/* ButtonPage to trigger the modal */}

                            <ButtonPage handleShow={handleShow} />

                            {/* Modal Component data fetching computing data all procedures*/}
                            <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} /></div>


                    </div>
                </Carousel.Caption>
            </Carousel>
        </section>
    )
}

export default AiandMlBanner