import React from 'react'
import JavaCorses from "./JavaCorses";
import AllCourses from "./AllCourses";
import Partners from './Partners'
import CloudComputingBanner from './CloudComputingBanner';
import CloudComputingTabs from './CloudComputingTabs';

const CloudComputing = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div> 

      <CloudComputingBanner/>
      <CloudComputingTabs></CloudComputingTabs>
    {/* <JavaCorses></JavaCorses>  */}
    
    <AllCourses></AllCourses>
     {/* <Partners></Partners> */}
   
     </div>
  )
}

export default CloudComputing