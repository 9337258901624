import React, { useState,useEffect } from "react";
import { Form } from "react-bootstrap";
const PersonalInfoForm = ({}) => {
  {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  const [formData, setFormData] = useState({
    fullName: '',
    gender: '',
    dateOfBirth: '',
    emailId: '',
    contactNumber: '',
    specialization:'',
    category: '',
    // Add more fields as needed
  });
  const [submitStatus, setSubmitStatus] = useState(null);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enrolltrainer/courses', 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        setSubmitStatus('success');
        window.alert('Form submitted successfully Our Team Will Reach You Soon....!');
    } else {
        setSubmitStatus('fail');
        window.alert('Failed to submit form. Please try again later.');
    }
      // Handle response as needed
    } catch (error) {
      console.error('Error:', error);
      setSubmitStatus('fail');
    }
  };
  const handleClosePopup = () => {
    setSubmitStatus(null);
  };
  
  return (
    <section style={{ paddingTop: "30px" }}>
      <div className="container-fluid">
        <div
          className="container  boderform"
          style={{
            backgroundColor: "#efefef",
            paddingBottom: "30px",
            borderRadius: "10px",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              color: "#5B4B99",
              paddingBottom: "50px",
              paddingTop:"10px"
            }}
          >
           Trainer Enroll Form
          </h2>
          <form
            onSubmit={handleSubmit}
            className="donate-form"
          >
            <div className="row" style={{ paddingBottom: "25px" }}>
              <Form.Group
                className="col-md-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Full Name*</Form.Label>
                <Form.Control
                  type="text"
                 
                  placeholder="Enter Name"
                  name="fullName" value={formData.fullName} onChange={handleChange}
                required
                />
              </Form.Group>
              <Form.Group
                className="col-md-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Gender*</Form.Label>
                <Form.Select
                  aria-label="Default select example "
                  name="gender" value={formData.gender} onChange={handleChange}
                  required
                >
                  <option>Enter Your Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="col-md-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>DOB*</Form.Label>
                <Form.Control
                  type="date"
                 
                  placeholder="date of birth"
                  name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group
                className="col-md-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>EMail-Id*</Form.Label>
                <Form.Control
                  type="email"
                 
                  placeholder="Enter Your Email"
                  name="emailId" value={formData.emailId} onChange={handleChange}
                  required
                />
              </Form.Group>
            </div>
            <div className="row" style={{ paddingBottom: "25px" }}>
              <Form.Group
                className="col-md-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Contact No.*</Form.Label>
                <Form.Control
                  type="number"
                 
                  placeholder="Enter Your Contact No."
                  name="contactNumber" value={formData.contactNumber} onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group
              className="col-md-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Specialization*</Form.Label>
              <Form.Select
                aria-label="Default select example "
                name="specialization" value={formData.specialization} onChange={handleChange}
               
              >
                <option>Enter Your Stream</option>
                <option value="Mechanical_Engineering">MECHANICAL ENGG</option>
                <option value="Electrical_Electronics_Engineering">ELECTRICAL & ELECTRONICS ENGG</option>
                <option value="Computer_Science_Engineering">COMPUTER SCIENCE ENGG</option>
              </Form.Select>
            </Form.Group>
           
              <Form.Group
                className="col-md-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Category*</Form.Label>
                <Form.Select
                  aria-label="Default select example "
                  name="category" value={formData.category} onChange={handleChange}
                  required
                >
                  <option>Choose the Category</option>
                  <option value="Tool_Fixture_Design">Tool And Fixture</option>
                  <option value="Online_Robotics">Online Robotics</option>
                  <option value="Offline_Robotics">Offline Robotics</option>
                </Form.Select>
              </Form.Group>
              
            </div>
            <button
            type="submit"
            className="custom-btn btn-3"
           
          >
            <span>Submit</span>
          </button>
          </form>
          {submitStatus === 'success' && (
                <div className="popup success">
                    <p>Form submitted successfully Our team will contact You Soon!!</p>
                    <button onClick={handleClosePopup}>Close</button>
                </div>
            )}
            {submitStatus === 'fail' && (
                <div className="popup fail">
                    <p>Failed to submit form. Please try again later.</p>
                    <button onClick={handleClosePopup}>Close</button>
                </div>
            )}
        </div>
      </div>
    </section>
  );
};

export default PersonalInfoForm;
