import React, { useState } from 'react';
import { Link } from "react-router-dom";
import redo from '../img/education-icon/redo.png'

const EcommerceTabs = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const tabs = [
    {
      id: 'tab1',
      label: 'Level 1 Certification',
      content: (
        <>
          <div className="row">

            <div className="row">
              <div className="align-items-center justify-content-between">
                {/* Left side content */}
                <div>
                <div style={{  textAlign:'center' }}>
                <h2 style={{ color: "#fff", padding:'20px' }}>
                      Certified  <span style={{ color: "rgb(224 120 44)" }}>E-commers & Digital Marketing </span>
                    </h2>

                  </div>

                 
                    <p style={{fontSize:'20px'}}> <img src={redo} style={{height:'30px',width:'30px'}}></img>Online Storefront A digital platform where businesses showcase their products or services, accessible to customers anywhere, anytime.</p>
                    <p style={{fontSize:'20px'}}> <img src={redo} style={{height:'30px',width:'30px'}}></img>Payment Integration Secure, multi-channel payment options that facilitate smooth and safe transactions.</p>
                    <p style={{fontSize:'20px'}}> <img src={redo} style={{height:'30px',width:'30px'}}></img> Global Reach Ability to sell products and services to customers across the world without geographical limitations.</p>
                    <p style={{fontSize:'20px'}}> <img src={redo} style={{height:'30px',width:'30px'}}></img> Customer Analytics Tracking and analyzing customer behavior to improve marketing strategies and optimize user experience.</p>
                
                </div>

                {/* Right side button */}

              </div>
            </div>




          </div>
          <div style={{textAlign:'center'}}>
          <Link to="/user-login" >
                      <button className="custom-btn btn-3" style={{ marginLeft: "20px" }}>
                        <span>Register Now</span>
                      </button>
                    </Link>
                    </div>
        </>
      )
    },
    // {
    //   id: 'tab2',
    //   label: 'Advance Flutter Courses ',
    //   content: (
    //     <>
    //       <div className="row">

    //         <div className="col-lg-6"> 


    //           <h2 style={{ color: "#F58634", }}>Advanced Flutter <span style={{ color: "#fff" }}>Courses</span></h2>
    //           <ul style={{
    //             color: "#fff",
    //             fontSize: "18px",
    //             textAlign: "justify"
    //           }}>
    //             <li className="fas fa-check-circle">Flutter Build Natively Compiled Applications for Mobile, Web, and Desktop. </li>
    //             <li>Flutter Empowering Developers to Create High-Performance Apps with Ease.</li>
    //             <li>Create Stunning Cross-Platform Apps with Flutter’s Rich Set of Widgets.</li>
    //             <li>Flutter The Toolkit for Building Fast, Expressive, and Flexible UIs.</li>
    //             <li>Accelerate App Development with Flutter’s Hot Reload and Rich Ecosystem.</li>
    //             <li>Flutter Your Solution for Consistent, High-Quality Apps Across Multiple Platforms.</li>
    //           </ul>

    //         </div>
    //         <div className="col-lg-6 d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
    //           <Link to="/enroll">
    //             <button className="custom-btn btn-3 " style={{ marginTop: '100px' }}>
    //               <span>Register Now</span>
    //             </button>
    //           </Link>
    //         </div>




    //       </div>
    //       <div className="row">
    //         <div className=""> 
    //           <p style={{
    //             color: "#fff",
    //             fontSize: "20px",
    //             textAlign: "justify"
    //           }}>                      Flutter stands out as a powerful and flexible framework for developing cross-platform applications.
    //             Its ability to streamline the development process, combined with its rich set of features and
    //             performance benefits, makes it an attractive choice for developers looking to build high-quality,
    //             native experiences across multiple platforms. As the ecosystem continues to grow, Flutter’s capabilities
    //             and adoption are expected to expand, further solidifying its position in the app development landscape.

    //           </p>



    //         </div>
    //       </div>
    //     </>
    //   )
    // },
  ];

  const currentTab = tabs.find(tab => tab.id === activeTab);

  return (
    <div className="tab-wrapper" style={{ padding: '30px 40px', backgroundColor: "#fff" }}> {/* Add padding here  data fetching */}
      <div className="tab-container">
        {tabs.map(tab => (
          <button
            key={tab.id}
            className={`tab-button ${activeTab === tab.id ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="tab-content">
        <p>{currentTab.content}</p>
      </div>
      <style>
        {`
          .tab-container {
            display: flex;
            justify-content: center; /* Center the tabs horizontally */
            margin-bottom: 20px; /* Space between tabs and content */
          }
          .tab-button {
            flex: 1; /* Make all tabs take equal width */
            max-width: 500px; /* Set a maximum width for each tab */
            padding: 10px;
            border: none;
            background: none;
            cursor: pointer;
            font-size: 16px;
            transition: background-color 0.3s;
            margin-right: 5px; /* Add some space between tabs */
            outline: none; /* Remove default outline */
           
            // border-radius: 50px;
                background: #4f4e70;
                 color: #f58842; /* Default text color */
                    margin-top: 10px;
                    font-size: 18px;
          }
          .tab-button:hover {
            background-color: #f0f0f0;
          }
          .tab-button.active {
            border-bottom: 2px solid #524DA1; /* Active tab color */
            font-weight: bold;
            color: #f58842; /* Change text color for active tab */
                // border-radius: 50px;
                background: #efefef;
                    margin-top: 10px;
                    font-size: 18px;
          }
        
          .tab-content {
            padding: 20px;
            border: 1px solid #ccc;
            border-top: none; /* Remove top border to connect with tabs */
            background-color: rgb(91 86 110);
                color: #fff;
          }
                 .tab-button.disabled {
            background-color: #ddd; /* Grey out background */
            color: #888; /* Lighter text color */
            cursor: not-allowed; /* Show not-allowed cursor */
            pointer-events: none; /* Disable pointer events */
          }
             .tab-button:hover:not(.disabled) {
            background-color: #f0f0f0;
          }
        `}

      </style>
    </div>
  );
};

export default EcommerceTabs;
