import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import Slider from 'react-slick'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import team1 from '../img/our-team/ccr.png'
import team2 from '../img/our-team/ASHA.png'
import team3 from '../img/our-team/KIRAN.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Builders = () => {
    var OurTeamSlider = {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        infinite: true,
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    autoplay: true,
                    autoplaySpeed: 3000,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,
                    dots: false,
                    arrows: false, 
                    autoplaySpeed: 3000,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,
                    dots: false,
                    arrows: false, 
                    autoplaySpeed: 3000,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    autoplay: true,
                    arrows: false,
                    autoplaySpeed: 3000,
                    infinite: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    infinite: true,
                }
            }
        ]
    };
  return (
    <div>
<div className="container">
<div className="section-head text-black text-center">
					<h2 className="text-uppercase" style={{color:'#5B4B99'}}>Meet Our Career  <span style={{ color: " #F58634" }}>Builders</span></h2>
					<div className=" bg-primary" ></div>
					</div>
<div className="container" >
            <div className="containers"  >
                <Slider {...OurTeamSlider} >
                    <div className="card single-volunteers">
                        <img src={team1} alt="" />
                        <div className="volunteer-content">
                            <h3>ChandraShaker Reddy</h3>
                                    <ul>
										<li><a className="site-button facebook sharp outline" target="_blank"><FontAwesomeIcon icon="fa-brands fa-facebook-f" /></a></li>
										<li><a className="site-button google-plus sharp outline" target="_blank"><FontAwesomeIcon icon="fa-brands fa-google-plus-g" /></a></li>
										<li><a className="site-button linkedin sharp outline" target="_blank"><FontAwesomeIcon icon="fa-brands fa-linkedin" /></a></li>
										<li><a className="site-button twitter sharp outline" target="_blank"><FontAwesomeIcon icon="fa-brands fa-twitter" /></a></li>
									</ul>                       
                        </div>
                    </div>
                    <div className="card single-volunteers"  >
                        <img src={team2} alt="" />
                        <div className="volunteer-content">
                            <h3>G Asha</h3>
                                    <ul >
										<li><a className="site-button facebook sharp outline" target="_blank"><FontAwesomeIcon icon="fa-brands fa-facebook-f" /></a></li>
										<li><a className="site-button google-plus sharp outline" target="_blank"><FontAwesomeIcon icon="fa-brands fa-google-plus-g" /></a></li>
										<li><a className="site-button linkedin sharp outline" target="_blank"><FontAwesomeIcon icon="fa-brands fa-linkedin" /></a></li>
										<li><a className="site-button twitter sharp outline" target="_blank"><FontAwesomeIcon icon="fa-brands fa-twitter" /></a></li>
									</ul>
                        </div>
                    </div>
                    <div className="card single-volunteers" >
                        <img src={team3} alt="" />
                        <div className="volunteer-content">
                            <h3>Kiran Kumar</h3>
                                     <ul >
										<li><a className="site-button facebook sharp outline" target="_blank"><FontAwesomeIcon icon="fa-brands fa-facebook-f" /></a></li>
										<li><a className="site-button google-plus sharp outline" target="_blank"><FontAwesomeIcon icon="fa-brands fa-google-plus-g" /></a></li>
										<li><a className="site-button linkedin sharp outline" target="_blank"><FontAwesomeIcon icon="fa-brands fa-linkedin" /></a></li>
										<li><a className="site-button twitter sharp outline" target="_blank"><FontAwesomeIcon icon="fa-brands fa-twitter" /></a></li>
									 </ul>
                        </div>
                    </div>
                </Slider>
            </div>
            </div>
            </div>
    </div>
  )
}

export default Builders
library.add(fab, fas, far)