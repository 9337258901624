import React from 'react'
import Fluttercorses from "./Fluttercorses";
import FlipCards from "./FlipCards";
import Partners from './Partners'
import EcommerceandDigitalBanner from './EcommerceandDigitalBanner';
import EcommerceTabs from './EcommerceTabs';
const PlacementsEcommers = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div> 
      <EcommerceandDigitalBanner/>
      <EcommerceTabs></EcommerceTabs>
    {/* <Fluttercorses></Fluttercorses>  */}
    
    <FlipCards />
     {/* <Partners></Partners> */}
  
     </div>
  )
}

export default PlacementsEcommers