import React, { useEffect, useRef } from "react";
// import "./IiaBrief.css";
 // Create a separate CSS file for animations
// import "../../public/css/"
const IiaBrief = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the section is visible
      }
    );

    const section = sectionRef.current;
    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);

  return (
    <div>
      <section className="container-fluid pd-top" ref={sectionRef} style={{backgroundColor:"rgb(54, 48, 74)"}}>
        <div className="container">
          <div className="row align-items-center rev">
          <h1 className="heading-names" style={{ color: "#fff", fontSize: "40px", lineHeight: "1.3" }}>
                  INTERNATIONAL INSTITUTE OF <span style={{ color: "#F58634" }}>AUTOMATE</span>
                </h1>
            <div className="col-lg-6 left-col" style={{color:"#fff"}}>
              <div className="about-content page-style ml-15">
               
                <p style={{ textAlign: "justify", fontSize: "18px", textIndent: "50px", lineHeight: "1.4",color: '#c8c7c7' }}>
                  Welcome to International Institute of Automate, which ignites
                  minds and empowers the future, it is a dynamic learning
                  environment where innovation, expertise, and excellence
                  converge to shape the leaders of tomorrow. We believe in the
                  power of education to transform lives.
                </p>
                <p style={{ textAlign: "justify", fontSize: "18px", textIndent: "50px", lineHeight: "1.4",color: '#c8c7c7' }}>
                  This center was created to carry out the goal for
                  ”Employability Enhancement Education“ to the India at
                  affordable prices. It is also recognized as famous “Finishing
                  school” for engineering aspirants and working employees, which
                  aims to bridge the gap between theoretical knowledge, promotes
                  advance technological information, it brings the best
                  practical applications and business needs.
                </p>
              </div>
            </div>
            <div className="col-lg-6 right-col">
              <div className="about-img text-center">
                <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/y0YoTKv6was?si=pDFaCVQ3__MR78oe"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default IiaBrief;