import React, { useState } from 'react';
import { Link } from "react-router-dom";
import redo from '../img/education-icon/redo.png'

const AIandMlTabs = () => {
    const [activeTab, setActiveTab] = useState('tab1');

    const tabs = [
        {
            id: 'tab1',
            label: 'Level 1 Certification',
            content: (
                <>
                    <div className="row">

                        <div className="row">
                            <div className=" align-items-center justify-content-between">
                                {/* Left side content data not fetching community all the */}
                                <div>
                                    <div style={{ textAlign: 'center', fontWeight: '700' }}>
                                        <h2 style={{ color: "#fff", padding: '20px' }}>
                                            Certified AI and ML<span style={{ color: "rgb(224 120 44)" }}> Solutions </span>
                                        </h2>


                                    </div>

                                    <p style={{ fontSize: '20px' }}> <img src={redo} style={{ height: '30px', width: '30px' }}></img> Data-Driven Decision Making: AI systems analyze vast amounts of data to make intelligent decisions and predictions.</p>
                                    <p style={{ fontSize: '20px' }}> <img src={redo} style={{ height: '30px', width: '30px' }}></img> Learn to choose materials based on their characteristics to optimize performance, durability, and cost.</p>
                                    <p style={{ fontSize: '20px' }}> <img src={redo} style={{ height: '30px', width: '30px' }}></img> Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.</p>
                                    <p style={{ fontSize: '20px' }}> <img src={redo} style={{ height: '30px', width: '30px' }}></img> Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.</p>

                                </div>

                                {/* Right side button data fontSize Community and all the refresh */}
                               

                            </div>
                        </div>




                    </div>
                    <div className="row">
                        <div className="">
                            <p style={{
                                color: "#fff",
                                fontSize: "20px",
                                textAlign: "justify"
                            }}>Artificial Intelligence (AI) and Machine Learning (ML) are revolutionary technologies 
                            that empower machines to mimic human intelligence, learn from data, and improve their 
                            performance over time without being explicitly programmed.</p>


                        </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <Link to="/user-login" >
                            <button className="custom-btn btn-3" style={{ marginLeft: "20px" }}>
                                <span>Register Now</span>
                            </button>
                        </Link>
                    </div>
                </>
            )
        },





    ];

    const currentTab = tabs.find(tab => tab.id === activeTab);

    return (
        <div className="tab-wrapper" style={{ padding: '30px 40px', backgroundColor: "#fff" }}> {/* Add padding here  data fetching */}
            <div className="tab-container">
                {tabs.map(tab => (
                    <button
                        key={tab.id}
                        className={`tab-button ${activeTab === tab.id ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab.id)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            <div className="tab-content">
                <p>{currentTab.content}</p>
            </div>
            <style>
                {`
          .tab-container {
            display: flex;
            justify-content: center; /* Center the tabs horizontally */
            margin-bottom: 20px; /* Space between tabs and content */
          }
          .tab-button {
            flex: 1; /* Make all tabs take equal width */
            max-width: 500px; /* Set a maximum width for each tab */
            padding: 10px;
            border: none;
            background: none;
            cursor: pointer;
            font-size: 16px;
            transition: background-color 0.3s;
            margin-right: 5px; /* Add some space between tabs */
            outline: none; /* Remove default outline */
           
            // border-radius: 50px;
                background: #4f4e70;
                 color: #f58842; /* Default text color */
                    margin-top: 10px;
                    font-size: 18px;
          }
          .tab-button:hover {
            background-color: #f0f0f0;
          }
          .tab-button.active {
            border-bottom: 2px solid #524DA1; /* Active tab color */
            font-weight: bold;
            color: #f58842; /* Change text color for active tab */
                // border-radius: 50px;
                background: #efefef;
                    margin-top: 10px;
                    font-size: 18px;
          }
        
          .tab-content {
            padding: 20px;
            border: 1px solid #ccc;
            border-top: none; /* Remove top border to connect with tabs */
            background-color: rgb(91 86 110);
                color: #fff;
          }
                 .tab-button.disabled {
            background-color: #ddd; /* Grey out background */
            color: #888; /* Lighter text color */
            cursor: not-allowed; /* Show not-allowed cursor */
            pointer-events: none; /* Disable pointer events */
          }
             .tab-button:hover:not(.disabled) {
            background-color: #f0f0f0;
          }
        `}

            </style>
        </div>
    );
};

export default AIandMlTabs;
