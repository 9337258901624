import React, { useState, useEffect } from "react";
import robotics from "../img/section-images/robotics.jpg";

import { Link } from "react-router-dom";
const WebapplicationsCorses = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });


  // api integration 
  const [onlinerobotics, setOnlinerobotics] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/OnlineRobotics/get');
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setOnlinerobotics(result);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  //  if (isLoading) {
  //    return <div>Loading...</div>;
  //  }

  //  if (error) {
  //    return <div>Error: {error.message}</div>;
  //  }

  function MyChildComponent() {
    return (
      <div>
        <div class="" style={{ paddingTop: "10px" }}>
          <div class="">
            <section
              className="about-area about-style-three about-us-mobile "
              style={{ textAlign: "justify" }}
            >
              <div className="container">
                <div className="row align-items-center ">
                  <div className="about-content page-style ml-15"></div>
                  <div className="col-lg-6">


<div className="about-content page-style ml-15">
<div className="text-center" style={{marginTop: "-40px"}}>
<span
className="top-title"
style={{
  fontSize: "35px",
  paddingBottom: "15px",
}}
>
Key Features
</span>
</div>
</div>
  <ul style={{ textAlign: "justify" }}>
    <li>User Interface Design Crafting intuitive and visually appealing front-end interfaces.</li>
    <li>Backend Development Building the server-side logic that powers web applications.</li>
    <li>Database Integration Managing data storage and retrieval for dynamic content.</li>
    <li>Authentication and Authorization Securing access with user verification .</li>
    <li>Scalability and Load Balancing Designing for growth and handling traffic efficiently.</li>
    <li>Testing and Debugging Identifying and fixing issues to ensure smooth operation.</li>
  </ul>
  {/* <div className="about-img text-center">
    <img src={robotics} alt="Image" />
  </div> */}
</div>
<div
                    className="col-lg-6"
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      lineHeight: "2.6 ",
                    }}
                    >
                    <div className="text-center">
                      <span
                        className="top-title"
                        style={{
                          fontSize: "35px",
                          paddingBottom: "20px",
                        }}
                      >
                        Course Information
                      </span>
                    </div>

                    {onlinerobotics && (
                      <table>
                        <tbody>
                          <tr>
                            <td>Course Duration</td>
                            <td>{onlinerobotics.courseDuration}</td>
                          </tr>
                          {/* <tr>
               <td>Date</td><td>{data.date}</td>
               </tr> */}
                          <tr>
                            <td>Mode</td>
                            <td>{onlinerobotics.mode}</td>
                          </tr>
                          <tr>
                            <td>Time</td>
                            <td>{onlinerobotics.timing}</td>
                          </tr>

                          <tr>
                            <td>Price</td>
                            <td>{onlinerobotics.price}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}

<Link to="/contact-us?category=mechanical-engineering">
                  <button className="custom-btn btn-3">
                    <span>Register Now</span>
                  </button>
                </Link>
                  </div>
                </div>
              </div>
            </section>
            {/*  <section
        className="about-area about-style-three about-us-mobile"
        style={{ textAlign: "justify" }}
      >
        <div className="container">
          <div className="row align-items-center " style={{paddingBottom:"10px"}}>
            <div
              className="about-content page-style ml-15  "
              style={{ textAlign: "justify", fontSize: "18px" }}
            >
              <div className="text-center">
                <span
                  style={{
                    fontSize: "30px",
                    color: "#5B4B99",
                   
                  }}
                >
                  Key Features:
                </span>
              </div>
            </div>
            <div className="col-lg-6">
            <div
                className="about-content page-style ml-15 bdstyle conheigh"
                style={{ textAlign: "justify" }}
              >
                <h4>Web Development Technologies:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                     
                    }}
                  >
                    Web applications are built using a variety of 
                    technologies, including HTML, CSS, and JavaScript 
                    for the front end, and server-side languages. 
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                     
                    }}
                  >
                    server-side languages like PHP, Python, Ruby, and frameworks 
                    such as Node.js for the back end. 
                    
                  </li>
                </ul>
                <h4> Responsive Design:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Responsive design ensures that web applications function smoothly 
                    across different devices and screen sizes.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    {" "}
                    enhancing user experience by adapting the layout and content to fit 
                    various viewports.
                  </li>
                </ul>
                <h4>User Authentication and Security:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                     Web applications often include user authentication mechanisms such
                      as login systems and multi-factor authentication.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Multi-factor authentication (MFA) to protect user data and ensure secure access.
                  </li>
                </ul>
                <h4> Database Integration:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Web apps typically interact with databases to store and manage data. 
                    Common database systems include MySQL, PostgreSQL, and NoSQL databases 
                    like MongoDB.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                
                    }}
                  >
                    which support various data storage and retrieval needs. 
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
            <div className="about-content page-style ml-15 bdstyle conheigh"  style={{paddingBottom:"185px"}}>
                <div className="text-center"></div>
                <h4>Performance Optimization:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Optimizing web application performance involves techniques like code minification, image 
                    compression, and caching to improve load times and ensure.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    We develop secure, scalable web applications that integrate 
                    seamlessly with your existing systems.
                  </li>
                </ul>
                <h4>Scalability and Cloud Hosting:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                   Scalability is crucial for web applications to handle increasing traffic and user demand. 
                   Cloud hosting solutions like AWS, Azure.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                   Google Cloud provide scalable infrastructure and services to support growth.
                  </li>
                </ul>
                <h4>API Integration:</h4>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    API integration allows web applications to connect with external services and systems, 
                    enhancing functionality and enabling data exchange.
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                   APIs can facilitate features like payment processing, social media sharing.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <article>
        <div class="snippet">
        <section className="about-area about-style-three about-us-mobile">
            <div className="container">
              <div className="row align-items-center rev">
                <div className="about-content page-style ml-15">
                  <div className="text-center">
                  <span
                      className="top-title"
                      style={{ fontSize: "35px", paddingBottom: "20px", color: "#5B4B99"}}
                    >
                     WEB  <span style={{ color: " #F58634" }}>APPLICATION</span>
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content page-style ml-15">
                    <p
                      style={{
                        textAlign: "justify",
                        textIndent: "50px",
                        fontSize: "18px",
                      }}
                    >
                    
                    A web application is a software application that runs on a web server and is accessed 
                      through a browser. Unlike desktop applications, web apps do not require installation 
                      and can be used on any device with internet access. They are typically built using 
                      technologies like HTML, CSS, JavaScript, and frameworks such as Angular, React, or Vue.
                      js for front-end development, while the back-end may involve languages like PHP, Python, or Node.js.
                      Web applications are widely used for tasks ranging from online banking and e-commerce to 
                      social networking and project management. They provide real-time updates, cross-platform 
                      compatibility, and scalability. Additionally, web apps are easy to maintain, as updates 
                      can be made on the server-side without requiring user intervention.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-img text-center">
                    <div className="videoWrapper">
                      <iframe
                        width="100%"
                        height="330"
                        src="https://www.youtube.com/embed/y0YoTKv6was?si=pDFaCVQ3__MR78oe"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MyChildComponent />
          </section>
        </div>
      </article>
    </div>
  );
};

export default WebapplicationsCorses;
