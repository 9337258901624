import React from 'react'
import JavaCorses from "./JavaCorses";
import AllCourses from "./AllCourses";
import Partners from './Partners'
import JavaCourseBanner from './JavaCourseBanner';
import JavaTabs from './JavaTabs';
import FlipCards from './FlipCards';

const PlacementsJava = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div> 

      <JavaCourseBanner/>
      <JavaTabs></JavaTabs>
    {/* <JavaCorses></JavaCorses>  */}
    <FlipCards />
    {/* <AllCourses></AllCourses> */}
     {/* <Partners></Partners> */}
   
     </div>
  )
}

export default PlacementsJava