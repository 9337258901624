import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import necessary hooks
import axios from "axios"; // Assuming you're using axios for API calls

const ForgetPasswordpage = () => {
    const { token } = useParams(); // Get token from URL
    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const [loginData, setLoginData] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // Initialize navigate for redirection

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Handle input changes
    const handleLoginChange = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value,
        });
    };

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
    
        // Check if the new password and confirm password fields are filled
        if (!loginData.newPassword || !loginData.confirmPassword) {
            setMessage("Please fill in both fields.");
            return;
        }
    
        // Check if passwords match
        if (loginData.newPassword !== loginData.confirmPassword) {
            setMessage("Passwords do not match!"); // Show error message
            return;
        }
    
        // Get the token from localStorage or URL
        const token = localStorage.getItem("userToken") || ""; // Ensure the token is retrieved
    
        try {
            setLoading(true); // Set loading state to true
    
            // Make the API call to reset the password
            const response = await axios.post(
                `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/forgot/reset/${token}`, // Ensure this is the correct URL
                {
                    newPassword: loginData.newPassword,
                    confirmPassword: loginData.confirmPassword,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            
    
            // Handle successful response
            if (response.status === 200) {
                setMessage("Password reset successful.");
                setLoginData({ newPassword: "", confirmPassword: "" }); // Clear input fields
    
                // Redirect to the login page after a short delay
                setTimeout(() => {
                    navigate("/login");
                }, 2000);
            } else {
                setMessage("Unexpected response from server. Please try again.");
            }
        } catch (error) {
            // Enhanced error handling
            let errorMsg = "Error resetting password. Please try again.";
    
            if (error.response) {
                console.error("Response data:", error.response.data); // Log the response data
                console.error("Response status:", error.response.status); // Log the status code
                errorMsg = error.response.data?.message || errorMsg; // Extract specific message from the response
            } else if (error.request) {
                errorMsg = "No response from the server. Please check your network.";
            } else {
                errorMsg = error.message; // General error message
            }
    
            setMessage(errorMsg); // Display the appropriate error message
        } finally {
            setLoading(false); // Set loading state to false
        }
    };
    
      

    return (
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
            <div style={{ maxWidth: "444px", margin: "0 auto", textAlign: "center" }}>
                <div style={{ padding: "20px", border: "1px solid #ccc", borderRadius: "10px", backgroundColor: "#f9f9f9", marginTop: "80px" }}>
                    <form id="loginForm" onSubmit={handleLoginSubmit}>
                        <h3>Reset Password</h3>
                        {message && <p>{message}</p>}
                        <div style={{ marginBottom: "15px", textAlign: "center" }}>
                            <label>New Password</label>
                            <div style={{ position: "relative" }}>
                                <input
                                    name="newPassword"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="New Password"
                                    value={loginData.newPassword}
                                    onChange={handleLoginChange}
                                    required
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        border: "1px solid #ccc",
                                        marginTop: "5px",
                                    }}
                                />

                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        color: "#007bff",
                                    }}
                                >
                                    {showPassword ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                            <path d="M16 8s-2.5-6-8-6-8 6-8 6 2.5 6 8 6 8-6 8-6zm-8 3.5a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7z" />
                                            <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                                            <path d="M8 3.5a4.5 4.5 0 0 0 0 9 4.5 4.5 0 0 0 0-9z" />
                                            <path fillRule="evenodd" d="M8 2C4.5 2 1.5 4.2 0 8c1.5 3.8 4.5 6 8 6 3.5 0 6.5-2.2 8-6-1.5-3.8-4.5-6-8-6zm0 11a5.5 5.5 0 0 1-5.4-4.7 8.095 8.095 0 0 0 10.8 0A5.5 5.5 0 0 1 8 13.5z" />
                                            <path fillRule="evenodd" d="M1.146 1.146a.5.5 0 0 1 .708 0l13.708 13.708a.5.5 0 0 1-.708.708L1.146 1.854a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div style={{ marginBottom: "15px", textAlign: "center" }}>
                            <label>Confirm Password</label>
                            <div style={{ position: "relative" }}>
                                <input
                                    name="confirmPassword"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Confirm Password"
                                    value={loginData.confirmPassword}
                                    onChange={handleLoginChange}
                                    required
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        border: "1px solid #ccc",
                                        marginTop: "5px",
                                    }}
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        color: "#007bff",
                                    }}
                                >
                                    {showPassword ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                            <path d="M16 8s-2.5-6-8-6-8 6-8 6 2.5 6 8 6 8-6 8-6zm-8 3.5a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7z" />
                                            <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                                            <path d="M8 3.5a4.5 4.5 0 0 0 0 9 4.5 4.5 0 0 0 0-9z" />
                                            <path fillRule="evenodd" d="M8 2C4.5 2 1.5 4.2 0 8c1.5 3.8 4.5 6 8 6 3.5 0 6.5-2.2 8-6-1.5-3.8-4.5-6-8-6zm0 11a5.5 5.5 0 0 1-5.4-4.7 8.095 8.095 0 0 0 10.8 0A5.5 5.5 0 0 1 8 13.5z" />
                                            <path fillRule="evenodd" d="M1.146 1.146a.5.5 0 0 1 .708 0l13.708 13.708a.5.5 0 0 1-.708.708L1.146 1.854a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </div>

                        <button
                            type="submit"
                            style={{
                                width: "100%",
                                padding: "10px",
                                borderRadius: "5px",
                                backgroundColor: "rgb(226 114 42)",
                                color: "#fff",
                                border: "none",
                                cursor: "pointer",
                            }}
                        >
                            Reset Password
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ForgetPasswordpage;
