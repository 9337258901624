import React, { useState } from "react";
import DownloadBrocherpopup from "./DownloadBrocherpopup"; // Modal
import ButtonPage from './ButtonPage'; // Button component
import techtalk from "../img/banners/placements-banner.jpg";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
const AboutUsBanner = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  return (
    <div>
      <section>
        <Carousel className="res-nav-mob no-indicators" interval={300000} controls={false}>
          <img
            className="d-block w-100"
            src={techtalk}
            alt="Techno Talks"
            style={{ height: "650px" }}
          />



          <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
            <div className="row">
              {/* Column 1 */}
              <div className="col-lg-4 dtd" style={{}}>
                <div className="" style={{ width: "110%" }}>

                  <h2 className="banner-content"
                    style={{
                      fontSize: "32px",
                      color: "#524DA1",
                      fontFamily: "Raleway!important",
                      fontWeight: "600",
                    }}
                  >

                    Where Passion Meets Expertise in Technology.
                  </h2>

                  <h2 className="banner-content"
                    style={{
                      fontSize: "40px",
                      color: "#524DA1",
                      fontFamily: "Raleway!important",
                      fontWeight: "800",
                    }}
                  >
                    ABOUT US
                  </h2>
                  <p className="banner-contentul"
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      fontWeight: "400",
                      textAlign: "justify",
                      textIndent: "50px",
                      fontWeight: "bold"
                    }}
                  >
                    1. Where Learning Meets Opportunity
                  </p>
                  <p className="banner-contentul"
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      fontWeight: "400",
                      textAlign: "justify",
                      textIndent: "50px",
                      fontWeight: "bold"
                    }}
                  >
                    2. Bringing Experts and Learners Together
                  </p>
                  <p className="banner-contentul"
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      fontWeight: "400",
                      textAlign: "justify",
                      textIndent: "50px",
                      fontWeight: "bold"
                    }}
                  >
                    3. Your Gateway to Industry Expertise
                  </p>
                  <p className="banner-contentul"
                    style={{
                      fontSize: "17px",
                      color: "#000",
                      fontWeight: "400",
                      marginLeft: "49px",
                      textAlign: "justify",
                      fontWeight: "bold"
                    }}
                  >
                    Creating a Community of Lifelong Learners
                  </p>

                  <div className="view-btn">

                    {/* Link to view more */}
                    {/* <Link to="/flutter-corses" className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "10px 20px", float: "right",  }}>
                  View More
                 </Link> */}

                  </div>
                </div>
              </div>

              <div className="col-lg-8 dtdt ">
                <h2 className="banner-content"
                  style={{
                    fontSize: "32px",
                    color: "#fff",
                    fontFamily: "Raleway!important",
                    fontWeight: "600",
                    width: "96%",
                    textAlign: "center"
                  }}
                >
                  “Empowering Innovation Through Knowledge and Collaboration”
                </h2>

              </div>
            </div>


            {/* <div style={{}}>
              <div> 
                <br></br><br></br>
                <ButtonPage handleShow={handleShow} />

                
                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} /></div>

              
            </div> */}

          </Carousel.Caption>




        </Carousel>
      </section>
    </div>
  )
}

export default AboutUsBanner