import React from 'react';
import bg1 from '../img/bg1.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faComments, faGraduationCap, faMicrophoneAlt } from '@fortawesome/free-solid-svg-icons';

const Stats = () => {
  return (
    <div>
      <div className="section-full bg-white overlay-black-dark bg-img-fix dlab-stats parallax dlab-stats-3" 
        style={{ backgroundImage: `url(${bg1})` }}
        >
        <div className="container">
          <div className="row p-t30">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="stats text-black slide-in-left" style={{ textAlign: 'center' }}>
                <FontAwesomeIcon icon={faRobot} size="2x" style={{ color: '#ffa96a' }} /> <br></br>
                <span>COURSES</span>
                <div className="counter font-26 font-weight-800 m-b5" style={{ color: '#ffa96a' }}>100</div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="stats text-black slide-in-left" style={{ textAlign: 'center' }}>
                <FontAwesomeIcon icon={faComments} size="2x" style={{ color: '#ffa96a' }} /><br></br>
                <span>TECHNO TALKS</span>
                <div className="counter font-26 font-weight-800 m-b5" style={{ color: '#ffa96a' }}>12</div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="stats text-black slide-in-left" style={{ textAlign: 'center' }}>
                <FontAwesomeIcon icon={faGraduationCap} size="2x" style={{ color: '#ffa96a' }} /><br></br>
                <span>PLACEMENTS</span>
                <div className="counter font-26 font-weight-800 m-b5" style={{ color: '#ffa96a' }}>100</div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="stats text-black slide-in-left" style={{ textAlign: 'center' }}>
                <FontAwesomeIcon icon={faMicrophoneAlt} size="2x" style={{ color: '#ffa96a' }} /><br></br>
                <span>Branches</span>
                <div className="counter font-26 font-weight-800 m-b5" style={{ color: '#ffa96a' }}>50</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats;