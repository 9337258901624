import React from 'react';
import Slider from "react-slick";
import logo1 from "../img/tech-img/Picture12.jpg";
import logo2 from "../img/tech-img/Picture14.jpg";
import logo3 from "../img/tech-img/Picture13.jpg";
import logo4 from "../img/tech-img/Picture4.jpg";
import logo5 from "../img/tech-img/Picture5.jpg";
import logo6 from "../img/tech-img/Picture6.jpg";
import logo7 from "../img/tech-img/Picture7.jpg";
import logo8 from "../img/tech-img/Picture8.jpg";
import logo9 from "../img/tech-img/Picture9.jpg";
import logo10 from "../img/tech-img/Picture10.jpg";
import logo11 from "../img/tech-img/Picture1.jpg";
import logo12 from "../img/tech-img/Picture11.jpg";

const CollegeSeminors = () => {
  var seminorSliderse = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    infinite: true,
    dots: false,
    autoplaySpeed: 2000,
    lazyLoad: 'ondemand',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className='seminor' style={{ backgroundColor: "#fff" }}>
      <div className="partner-area ptb-100">
        <div className="container">
          <div className="partner-bg style-two">
            <h1 className="heading-names" style={{ color: "rgb(82 78 161)", fontSize: "40px", lineHeight: "1.3" }}>
              COLLEGE <span style={{ color: "#F58634" }}> SEMINORS </span>
            </h1>

            <div className="logo-sliders" style={{ paddingLeft: "0px!important", paddingRight: "0px!important" }}>
              <Slider {...seminorSliderse}>
                <div>
                  <img src={logo1} alt="Seminar 1" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={logo2} alt="Seminar 2" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={logo3} alt="Seminar 3" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={logo4} alt="Seminar 4" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={logo5} alt="Seminar 5" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={logo6} alt="Seminar 6" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={logo7} alt="Seminar 7" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={logo8} alt="Seminar 8" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={logo9} alt="Seminar 9" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={logo10} alt="Seminar 10" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={logo11} alt="Seminar 11" style={{ height: "367px", width: "370px" }} />
                </div>
                <div>
                  <img src={logo12} alt="Seminar 12" style={{ height: "367px", width: "370px" }} />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeSeminors;
