import React from 'react'
import OfflineRobiticsBriefExp from './OfflineRobiticsBriefExp'
import TechnoTalksCources from './TechnoTalksCources'
import AiandMlBanner from './AiandMlBanner';
import AIandMlTabs from './AIandMlTabs';

const TechnoALandMl = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div>   
      <AiandMlBanner/>
      <AIandMlTabs></AIandMlTabs>
      {/* <OfflineRobiticsBriefExp/> */}
      <TechnoTalksCources/>
     
    </div>
  )
}

export default TechnoALandMl