import React, { useState,useEffect } from "react";
import { Form } from "react-bootstrap";
// import { Link } from 'react-router-dom';

const PlacementForm = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const [formData, setFormData] = useState({
    fullName: '',
    gender: '',
    dateOfBirth: '',
    emailId: '',
    address: '',
    location: '',
    city: '',
    state: '',
    phoneNumber: '',
    highestDegree: '',
    university: '',
    resume: '',
});
const [submitStatus, setSubmitStatus] = useState(null);
const handleChange = (e) => {
  const { name, value, type, files } = e.target;

  if (type === 'file') {
      const reader = new FileReader();

      reader.onload = () => {
          // Remove data URI prefix
          const base64StringFromFrontend = reader.result;
          const base64StringToSend = base64StringFromFrontend.substring("data:image/png;base64,".length);
          setFormData(prevState => ({
              ...prevState,
              [name]: base64StringToSend
          }));
      };

      reader.onerror = (error) => {
          console.error('Error reading file:', error);
      };

      // Read the file asynchronously
      reader.readAsDataURL(files[0]);
  } else {
      setFormData(prevState => ({
          ...prevState,
          [name]: value
      }));
  }
};
const handleSubmit = (e) => {
  e.preventDefault();
  fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/recruiterform/register', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
  })
  .then(response => {
      if (response.ok) {
          setSubmitStatus('success');
          window.alert('Form submitted successfully Our Team Will Reach You Soon....!');
      } else {
          setSubmitStatus('fail');
          // window.alert(formData.resume);
          window.alert(formData.resume)
          window.alert('Failed to submit form. Please try again later.');
      }
  })
  .catch(error => {
      console.error('Error submitting form:', error);
      setSubmitStatus('fail');
  });
};

const handleClosePopup = () => {
  setSubmitStatus(null);
};





return (
    <div>
 <section style={{ paddingTop: "30px" }}>
      <div className="container-fluid">
        <div
          className="container  boderform"
          style={{
            backgroundColor: "#efefef",
            paddingBottom: "30px",
            borderRadius: "10px",
          }}
        >
          
          <h2
            style={{
              textAlign: "center",
              color: "#5B4B99",
              paddingBottom: "50px",paddingTop:"30px"
            }}
          >
            Placement Form
          </h2>
          <form
           onSubmit={handleSubmit}
            // ref={form}
            // onSubmit={(event) => {
            //   sendEmail(event);
            // }}
            className="donate-form"
          >
            <div className="row" style={{ paddingBottom: "25px" }}>
              <Form.Group
                className="col-md-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Full Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="fullName" value={formData.fullName} onChange={handleChange}
                  placeholder="Enter Name"
                 
                  required
                />
              </Form.Group>
              <Form.Group
                className="col-md-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Gender*</Form.Label>
                <Form.Select
                  aria-label="Default select example "
                 
                  name="gender" value={formData.gender} onChange={handleChange}
                  required
                >
                  <option>Enter Your Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="col-md-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>DOB*</Form.Label>
                <Form.Control
                  type="date"
                  name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange}
                  placeholder="Date Of Birth"
                  
                  required
                />
              </Form.Group>
              <Form.Group
                className="col-md-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>EMail-Id*</Form.Label>
                <Form.Control
                  type="email"
                  name="emailId" value={formData.emailId} onChange={handleChange}
                  placeholder="Enter Your Email "
                 
                  required
                />
              </Form.Group>
            </div>
            <div className="row" style={{ paddingBottom: "25px" }}>
             
              
              <Form.Group
                className="col-md-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Address*</Form.Label>
                <Form.Control
                  type="text"
                  name="address" value={formData.address} onChange={handleChange}
                  placeholder="HNo/FlatNo"
                 
                  required
                />
              </Form.Group>
                
              <Form.Group
                className="col-md-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Location*</Form.Label>
                <Form.Control
                  type="text"
                  name="location" value={formData.location} onChange={handleChange}
                  placeholder="Location"        
                  required
                />
              </Form.Group>
                
              <Form.Group
                className="col-md-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>City*</Form.Label>
                <Form.Control
                  type="text"
                  name="city" value={formData.city} onChange={handleChange}
                  placeholder="City"
                  required
                />
              </Form.Group>
                
              <Form.Group
                className="col-md-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>State*</Form.Label>
                <Form.Control
                  type="text"
                  name="state" value={formData.state} onChange={handleChange}
                  placeholder="State"
                 
                  required
                />
              </Form.Group>
                      
            </div>
        
             <div className="row" style={{ paddingBottom: "25px" }}>
                     
             <Form.Group
               className="col-md-3"
               controlId="exampleForm.ControlInput1"
             >
               <Form.Label>Phone No*</Form.Label>
               <Form.Control
                 type="number"
                 name="phoneNumber" value={formData.phoneNumber} onChange={handleChange}
                 placeholder="Enter Your Phone Number"
                
                 required
               />
             </Form.Group>
            
             <Form.Group
               className="col-md-3"
               controlId="exampleForm.ControlInput1"
             >
               <Form.Label>Highest Degree*</Form.Label>
               <Form.Control
                 type="text"
                 name="highestDegree" value={formData.highestDegree} onChange={handleChange}
                 placeholder="Enter Your Degree"        
                 required
               />
             </Form.Group>
               
             <Form.Group
               className="col-md-3"
               controlId="exampleForm.ControlInput1"
             >
               <Form.Label>University</Form.Label>
               <Form.Control
                 type="text"
                 name="university" value={formData.university} onChange={handleChange}
                 placeholder="Enter Your University"
                                        
                 required
               />
             </Form.Group>
             <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Resume*</Form.Label>
                                    <Form.Control type="file" name="resume"    onChange={handleChange} required />
                                </Form.Group>
             {/* <Form.Group               
               className="col-md-3"
               controlId="exampleForm.ControlInput1"
             >
               <Form.Label>Resume*</Form.Label>
               <Form.Control
  type="file"
  name="resume"
  onChange={handleChange}
  required
/>
{formData.resume && formData.resume.name && (
  <p>Selected file: {formData.resume.name}</p>
)}
             </Form.Group> */}
             
             
            
            
             
             
             
             
           </div>
            
            <div style={{textAlign:"center"}}>
            <button className="custom-btn btn-3"><span>Submit</span></button>  
            </div>
            {submitStatus === 'success' && (
                <div className="popup success">
                    <p>Form submitted successfully Our team will contact You Soon!!</p>
                    <button onClick={handleClosePopup}>Close</button>
                </div>
            )}
            {submitStatus === 'fail' && (
                <div className="popup fail">
                    <p>Failed to submit form. Please try again later.</p>
                    <button onClick={handleClosePopup}>Close</button>
                </div>
            )}
          </form>
        </div>
      </div>
    </section>



    </div>
  )
}

export default PlacementForm