import React, { useState, useEffect } from "react";
import robotics from "../img/section-images/robotics.jpg";

import { Link } from "react-router-dom";
const ErpapplicationCorses = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });


  // api integration 
  const [onlinerobotics, setOnlinerobotics] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/flutter/latest');
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setOnlinerobotics(result);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  //  if (isLoading) {
  //    return <div>Loading...</div>;
  //  }

  //  if (error) {
  //    return <div>Error: {error.message}</div>;
  //  }

  function MyChildComponent() {
    return (
      <div>
        <div class="" style={{ paddingTop: "10px" }}>
          <div class="">
            <section
              className="about-area about-style-three about-us-mobile "
              style={{ textAlign: "justify" }}
            >
              <div className="container">
                <div className="row align-items-center ">
             
                  <div className="col-lg-6">

                  <div className="about-content page-style ml-15">
                       <div className="text-center" style={{marginTop: "-40px"}}>
                        <span
                             className="top-title"
                             style={{
                                    fontSize: "35px",
                                     paddingBottom: "15px",
                                    }}
                                   >
                                 Key Features
                               </span>
                             </div>
                           </div>


                    <ul style={{ textAlign: "justify" }}>
                      <li >Dive into the world of software engineering, focusing on developing applications </li>
                      <li>Programming languages, software engineering principles application development.</li>
                      <li>Understand the fundamentals of protecting digital assets from cyber threats, attacks.</li>
                      <li>Cloud service management, virtualization, and cloud infrastructure.</li>
                      
                      <li>Explore the use of cloud services to enhance business operations and scalability.</li>
                      <li> Learn how to analyze and interpret complex data to make informed business decisions.</li>
                    </ul>
                    {/* <div className="about-img text-center">
                      <img src={robotics} alt="Image" />
                    </div> */}
                  </div>
                  <div
                    className="col-lg-6"
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      lineHeight: "2.6 ",
                    }}
                    >
                    <div className="text-center">
                      <span
                        className="top-title"
                        style={{
                          fontSize: "35px",
                          paddingBottom: "20px",
                        }}
                      >
                        Course Information
                      </span>
                    </div>

                    {onlinerobotics && (
                      <table>
                        <tbody>
                          <tr>
                            <td>Course Duration</td>
                            <td>{onlinerobotics.courseDuration}</td>
                          </tr>
                          {/* <tr>
               <td>Date</td><td>{data.date}</td>
               </tr> */}
                          <tr>
                            <td>Mode</td>
                            <td>{onlinerobotics.mode}</td>
                          </tr>
                          <tr>
                            <td>Time</td>
                            <td>{onlinerobotics.timing}</td>
                          </tr>

                          <tr>
                            <td>Price</td>
                            <td>{onlinerobotics.price}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}

<Link to="/contact-us?category=mechanical-engineering">
                  <button className="custom-btn btn-3">
                    <span>Register Now</span>
                  </button>
                </Link>
                  </div>
                </div>
              </div>
            </section>
           
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <article>
        <div class="snippet">
        <section className="about-area about-style-three about-us-mobile">
            <div className="container">
              <div className="row align-items-center rev">
                <div className="about-content page-style ml-15">
                <div className="text-center">
                    <span className="top-title" style={{ fontSize: "35px", paddingBottom: "20px", color: "#5B4B99" }}>
                      INFORMATION TECHNOLOGY <span style={{ color: "#F58634" }}>ENGINEERING</span>
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content page-style ml-15">
                    <p style={{ textAlign: "justify", textIndent: "50px", fontSize: "18px" }}>
                      Welcome to the Information Technology Training Institute, where technology and innovation converge to shape tomorrow's digital leaders. Our institute is committed to delivering a well-rounded and hands-on learning experience for aspiring IT professionals. We take pride in developing future innovators through a curriculum tailored to meet the evolving demands of the technology industry. A career in information technology offers an ever-expanding landscape of possibilities, ranging from software development and cybersecurity to data analytics and cloud computing. IT professionals are the architects of the digital world, creating solutions that drive business, enhance communication, and revolutionize industries. Their expertise plays a pivotal role in shaping how we interact, work, and live in the modern era.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-img text-center">
                    <div className="videoWrapper">
                      <iframe
                        width="100%"
                        height="330"
                        src="https://www.youtube.com/embed/y0YoTKv6was?si=pDFaCVQ3__MR78oe"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MyChildComponent />
          </section>
        </div>
      </article>
    </div>
  );
};

export default ErpapplicationCorses;
