import React, { useState } from "react";
import DownloadBrocherpopup from "./DownloadBrocherpopup"; // Modal
import ButtonPage from './ButtonPage'; // Button component
import techtalk from "../img/banners/TECHNO-TALKS.jpg";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import offlinerobotics from "../img/banners/offline-robotics.jpg";
const DigitalFacilityBanner = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  return (
    <div>
      <section>
        <Carousel className="res-nav-mob no-indicators" interval={300000} controls={false} >
          <img
            className="d-block w-100"
            src={offlinerobotics}
            alt="Offline Robotics"
            style={{ height: "650px" }}
          />

          <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
            <div className="row">
              {/* Column 1 */}
              <div className="col-lg-4 dtd" style={{}}>
                <div className="" style={{ width: "100%" }}>
                  <h2 className="banner-content"
                    style={{
                      fontSize: "32px",
                      color: "#000",
                      fontFamily: "Raleway!important",
                      fontWeight: "600",
                    }}
                  >
                    Turn your passion into expertise in robotics From Theory to Real-World Automation
                  </h2>
                  <h2 className="banner-content"
                    style={{
                      fontSize: "40px",
                      color: "#524DA1",
                      fontFamily: "Raleway!important",
                      fontWeight: "800",
                    }}
                  >
                    DIGITAL <span style={{ color: "#F58842" }}> SOLUTIONS</span>
                  </h2>

                  <p className="banner-contentul"
                    style={{
                      fontSize: "20px",
                      color: "#000",
                      fontWeight: "bold",
                      textAlign: "justify",
                      textIndent: "50px",
                    }}
                  >
                    1. Industry-Specific Robotics Training (ISR)
                  </p>
                  <p className="banner-contentul"
                    style={{
                      fontSize: "20px",
                      color: "#000",
                      fontWeight: "bold",
                      textAlign: "justify",
                      textIndent: "50px",
                    }}
                  >
                    2. Career-Focused Skills Advancement (CFSA)
                  </p>
                  <p className="banner-contentul"
                    style={{
                      fontSize: "20px",
                      color: "#000",
                      fontWeight: "bold",
                      textAlign: "justify",
                      textIndent: "50px",
                    }}
                  >
                    3. 100% Placement Support
                  </p>
                  <p className="banner-contentul"
                    style={{
                      fontSize: "20px",
                      color: "#000",
                      marginLeft: "49px",
                      textAlign: "justify",
                      fontWeight: "bold",
                      width: "77%"
                    }}
                  >
                    Hands-on experience with KUKA & ABB software included
                  </p>

                  {/* <div className="view-btn">

                    <Link to="/digital-solution" className="btn" style={{ backgroundColor: "#524DA1", color: "#fff", padding: "10px 20px", float: "right", }}>
                      View More
                    </Link>
                  </div> */}
                </div>
              </div>

              {/* Column 2 */}
              <div className="col-lg-8 dtdt ">
                <h2 className="banner-content"
                  style={{
                    fontSize: "32px",
                    color: "#000",
                    fontFamily: "Raleway!important",
                    fontWeight: "600",
                    width: "80%",
                    textAlign: "center"
                  }}
                >
                  “Revolutionize Your Career with Offline Robotics Across India”
                </h2>
              </div>
              <div style={{}}>
                <div> {/* ButtonPage to trigger the modal */}

                  <ButtonPage handleShow={handleShow} />

                  {/* Modal Component */}
                  <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} />


                </div>


              </div>
            </div>
          </Carousel.Caption>
        </Carousel>
      </section>
    </div>
  )
}

export default DigitalFacilityBanner