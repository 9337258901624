import React from "react";
import Courses from "./Courses";
import Partners from './Partners'
import CourseInfo from "./CourseInfo";
// import Slider from "react-slick";
import Slider from "./Slider"
import AllCourses from "./AllCourses";
import Coursessliders from "./Coursessliders";
import AllcoursesSlider from "./AllcoursesSlider";
const TechCourses = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div>
      {/* <Slider /> */}
      <AllcoursesSlider />
         {/* <Coursessliders /> */}
     {/* <CourseInfo></CourseInfo> */}
     <AllCourses/>
      {/* <Courses></Courses> */}
       {/* <Partners></Partners> */}
    </div>
  );
};

export default TechCourses;
