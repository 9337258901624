import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const CoursesPricing = () => {
  // api integration
  const [offlinerobotics, setOfflinerobotics] = useState(null);
  const [toolbarfixture, setToolbarfixture] = useState(null);
  const [onlinerobotics, setOnlinerobotics] = useState(null);
  const [toolbarBatch, setToolbarBatch] = useState(null);
  const [onlineRoboticsBatch, setOnlineRoboticsBatch] = useState(null);
  const [offlineRoboticsBatch, setOfflineRoboticsBatch] = useState(null);


  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/toolfixture/latest"
        );
       
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setToolbarfixture(result);
      } catch (error) {
        setError(error);
      }

      try {
        const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/toolfixtureregistration/latest"
        );
       
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setToolbarBatch(result);
      } catch (error) {
        setError(error);
      }

      try {
        const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/offlinerobotics/get');
       
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setOfflinerobotics(result);
      } catch (error) {
        setError(error);
      }

//online robotics Batch
try {
  const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/courseonlinerobotics/latest");
 
  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }
  const result = await response.json();
  setOnlineRoboticsBatch(result);
} catch (error) {
  setError(error);
}





      try {
        const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/OnlineRobotics/get');
       
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setOnlinerobotics(result);
      } catch (error) {
        setError(error);
      }


      try {
        const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/offlinerobotics/latest');
       
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setOfflineRoboticsBatch(result);
      } catch (error) {
        setError(error);
      }


      setIsLoading(false);
    };

    fetchData();
  }, []);

  //  if (isLoading) {
  //    return <div>Loading...</div>;
  //  }

  //  if (error) {
  //    return <div>Error: {error.message}</div>;
  //  }

  return (
    <div>
      <div className="section-full bg-white ">
        <div className="container">
          <div className="section-head text-black text-center">
            <h2 className="text-uppercase" style={{ color: "#5B4B99" }}>
              Our Products Pricing
            </h2>
            <div className="dlab-separator bg-primary"></div>
          </div>

          <div className="pricing-table">
            <div className="pricing-box">
            <h2>TOOL AND FIXTURE DESIGN</h2>
              {toolbarfixture && (
                <>
                  <span className="price">
                    {toolbarfixture.price}/- {toolbarfixture.courseDuration}
                  </span>

                  <ul>
                    <li>Duration :{toolbarfixture.courseDuration} </li>
                    <li>Mode : {toolbarfixture.mode}</li>
                    {toolbarBatch && (
                    <li>Batch : {toolbarBatch.batch} </li>
                    
                  )}
                    <li>Timings :{toolbarfixture.timing} </li>
                  </ul>
                </>
              )}
              <span className="pricing-table-divider"></span>
              <div className="pricingtable-footer">
                {" "}
                <Link to={"/contact-us"}>
                  {" "}
                  <button className="custom-btn btn-3">
                    <span>Enroll Now</span>
                  </button>
                </Link>
              </div>
            </div>
            <div className="pricing-box">
            <h2>OFFLINE ROBOTICS</h2>
            {offlinerobotics && (<>
              
              <span className="price">{offlinerobotics.price}/- {offlinerobotics.courseDuration}</span>
              {/* <p className="description">
                Best For Lorem Ipsum is simply dummy text of the printing and
                typesetting industry...
              </p> */}

              {/* <a className="btn" href="#"><b>Sign Up</b></a> */}
              {/* <span className="pricing-table-divider"></span> */}
              <ul>
                <li>Duration :{offlinerobotics.courseDuration} </li>
                <li>Mode : {offlinerobotics.mode}</li>

              {onlineRoboticsBatch &&(
                <li>Batch : {onlineRoboticsBatch.batch} </li>
              )}
                
                
                
                <li>Timings : {offlinerobotics.timing} </li>
              </ul>
              </> )}
              <span className="pricing-table-divider"></span>
              <div className="pricingtable-footer">
                {" "}
                <Link to={"/contact-us"}>
                  {" "}
                  <button className="custom-btn btn-3">
                    <span>Enroll Now</span>
                  </button>
                </Link>
              </div>
            </div>
            <div className="pricing-box pricing-table-best">
            <h2>ONLINE ROBOTICS</h2>
            {onlinerobotics && (<>
              
              <span className="price">{onlinerobotics.price}/- {onlinerobotics.courseDuration}</span>
              {/* <p className="description">
                Best For Lorem Ipsum is simply dummy text of the printing and
                typesetting industry...
              </p> */}

              {/* <a className="btn" href="#"><b>Sign Up</b></a> */}
              {/* <span className="pricing-table-divider"></span> */}
              <ul>
                <li>Duration :{onlinerobotics.courseDuration} </li>
                <li>Mode : {onlinerobotics.mode}</li>
                {offlineRoboticsBatch &&(
                <li>Batch : {offlineRoboticsBatch.batch} </li>
              )}
                <li>Timings : {onlinerobotics.timing} </li>
              </ul>
              </> )}
              <span className="pricing-table-divider"></span>
              <div className="pricingtable-footer">
                {" "}
                <Link to={"/contact-us"}>
                  {" "}
                  <button className="custom-btn btn-3">
                    <span>Enroll Now</span>
                  </button>
                </Link>{" "}
              </div>
            </div>
          </div>

          {/* <div className="pricingtable-row m-b30 p-lr15 no-col-gap">
					<div className="row">
						<div className="col-sm-12 col-md-4 col-lg-4 m-b30">
							<div className="pricingtable-wrapper">
								<div className="">
									<div className="pricingtable-price pricingtable-inner"> <span className="pricingtable-bx">₹10000</span> <span className="pricingtable-type">Three Month</span> </div>
									<div className="pricingtable-title bg-primary pricingtable-inner" style ={{backgroundColor: 'rgb(84, 67, 149)!important'}}>
										<h3>Tools & Fixture Design</h3>
									</div>
									<ul style={{listStyle: "none",borderLeft:"1px solid #efefef"}}>
										<li> Fundamentals of Tool and Fixture Design </li>
										<li> Material Selection and Properties</li>
										<li> Machine Tools and Processes</li>
										<li> CAD for Tool and Fixture Design</li>
										<li> Geometric Dimensioning and Tolerance</li>
										<li> Cutting Tool Design</li>
										<li> Tool Life and Performance Analysis</li>
										<li> Cost Analysis and Optimization</li>
										<li> Project Work and Case Studies</li> 
									</ul>
									<div className="pricingtable-footer"> <Link to={'/presonal-information'}> <button className="custom-btn btn-3"><span>Pay Now</span></button></Link></div>
								</div>
							</div>
						</div>
						<div className="col-sm-12 col-md-4 col-lg-4 m-b30">
							<div className="pricingtable-wrapper">
								<div className=" pricingtable-highlight">
									<div className="pricingtable-price pricingtable-inner"> <span className="pricingtable-bx ">₹12000</span> <span className="pricingtable-type">Three Months</span> </div>
									<div className="pricingtable-title bg-primary pricingtable-inner">
										<h2>OFFLINE ROBOTICS</h2>
									</div>
									<ul style={{listStyle: "none",borderLeft:"1px solid #efefef"}}>
										<li> Introduction to Robotics </li>
										<li> Robot Kinematics and Dynamics</li>
										<li> Hands-On Simulation Platforms</li>
										<li> Programming Physical Robots</li>
										<li> Path Planning and Collision Avoidance</li>
										<li> Sensor Integration</li>
										<li> Control Systems in Physical Robotics</li>
										<li> Real-World Applications</li>
										<li>Maintenance and Troubleshooting</li>
                                        <li> Project Work and Case Studies</li>                                          
									</ul>
									<div className="pricingtable-footer"> <Link to={'/presonal-information'}> <button className="custom-btn btn-3"><span>Pay Now</span></button></Link></div>
								</div>
							</div>
						</div>
						<div className="col-sm-12 col-md-4 col-lg-4 m-b30">
							<div className="pricingtable-wrapper">
								<div className="">
									<div className="pricingtable-price pricingtable-inner "> <span className="pricingtable-bx">₹18000</span> <span className="pricingtable-type">Three Month</span> </div>
									<div className="pricingtable-title bg-primary pricingtable-inner">
										<h3>ONLINE ROBOTICS</h3>
									</div>
									<ul style={{listStyle: "none",borderRight:"1px solid #efefef"}}>
										<li> Robot Kinematics and Dynamics </li>
										<li> Simulation Environments</li>
										<li> Robot Programming</li>
										<li> Path Planning and Collision Avoidance</li>
										<li> Sensor Simulation</li>
										<li> Control Systems in Robotics</li>
										<li> Real-World Applications</li>
										<li> Integration with ROS</li>
										<li> Project Work and Case Studies</li>                                        
									</ul>
									<div className="pricingtable-footer"> <Link to={'/presonal-information'}> <button className="custom-btn btn-3"><span>Pay Now</span></button></Link> </div>
								</div>
							</div>
						</div>
					</div>
				</div> */}
        </div>
      </div>
    </div>
  );
};

export default CoursesPricing;
