import React from 'react'
import Partners from './Partners'
import AboutUsBrief from './AboutUsBrief'
import Stats from './Stats'
import MeetOurTeam from './MeetOurTeam'
import OurCommitments from './OurCommitments'
import OurCoPartners from './OurCoPartners'
import BusinessPartners from './BusinessPartners'
import AboutusVideo from './AboutusVideo'
import OurleadershipCards from './OurleadershipCards'
import AboutUsBanner from './AboutUsBanner'
import UniversitiesAlignWithUs from './UniversitiesAlignWithUs'
import OurLeadershipCardsFlip from './OurLeadershipCardsFlip'
import OureducationsPathners from './OureducationsPathners'


const AboutUs = () => {
    { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div>
      <AboutUsBanner/>
      {/* <AboutusVideo/> */}
        <AboutUsBrief></AboutUsBrief>
        {/* <Stats></Stats>  */}
        <OurCommitments/>
        {/* <OurleadershipCards /> */}
        <OurLeadershipCardsFlip />
        {/* <MeetOurTeam/> */}
        <OurCoPartners/>
       
        {/* <Partners></Partners> */}
        {/* <BusinessPartners/> */}
        <BusinessPartners/>
        {/* <UniversitiesAlignWithUs/> */}
        <OureducationsPathners />
    </div>

  )
}

export default AboutUs