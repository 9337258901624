import React from 'react';
import { useInView } from 'react-intersection-observer';
// import './animations.css';
 // Import the CSS file with animations

import logo2 from "../img/Partner_logo/logo2.jpg";
import logo3 from "../img/Partner_logo/logo4.png";
import logo4 from "../img/Partner_logo/logo5.png";
import logo5 from "../img/Partner_logo/logo6.png";
import logo6 from "../img/Partner_logo/logo7.png";
import logo7 from "../img/Partner_logo/logo8.png";
import educationpartner from "../img/educational-partners/christu-jyothi.jpg";
import klr from "../img/educational-partners/klr.jpg";
import vidwan from "../img/educational-partners/vidwan-sarvatra.jpg";

const UniversitiesAlignWithUs = () => {
  const { ref: educationPartnerRef, inView: educationPartnerInView } = useInView();
  const { ref: logo2Ref, inView: logo2InView } = useInView();
  const { ref: logo3Ref, inView: logo3InView } = useInView();
  const { ref: klrRef, inView: klrInView } = useInView();
  const { ref: logo5Ref, inView: logo5InView } = useInView();
  const { ref: logo6Ref, inView: logo6InView } = useInView();
  const { ref: logo7Ref, inView: logo7InView } = useInView();
  const { ref: vidwanRef, inView: vidwanInView } = useInView();

  return (
    <div>
      <div className="container-fluid" style={{ paddingBottom: "20px", backgroundColor: "rgb(54, 48, 74)" }}>
        <div className="container">
          <div className="row" style={{ textAlign: "center" }}>
            <div className="section-head text-black text-center">
              
            <h1 className='educationpatner-content' style={{ color: "#fff", fontSize: "40px", lineHeight: "1.3" }}>
        OUR EDUCATION<span style={{ color: "#F58634" }}>PARTNERS</span>
                </h1>
{/*               
              <h2 className="text-uppercase" style={{ color: '#5B4B99' }}>
                Our Education <span style={{ color: " #F58634" }}>Partners</span>
              </h2> */}
              <div className=" bg-primary"></div>
            </div>
            <div className="col-lg-3 alg">
              <div
                className={`single-partner fade-in-left ${educationPartnerInView ? 'visible' : ''}`}
                ref={educationPartnerRef}  style={{    backgroundColor: "#aca3cb",padding:"20px"}}
              >
                <img src={educationpartner} alt="Image" style={{ height: "60px" }} />
              </div>
            </div>
            {/* <div className="col-lg-3 alg">
              <div
                className={`single-partner fade-in-right ${logo2InView ? 'visible' : ''}`}
                ref={logo2Ref}
              >
                <img src={logo2} alt="Image" style={{ height: "60px" }} />
              </div>
            </div> */}
            <div className="col-lg-3 alg">
              <div
                className={`single-partner fade-in-left ${logo3InView ? 'visible' : ''}`}
                ref={logo3Ref} style={{    backgroundColor: "#aca3cb",padding:"20px"}}
              >
                <img src={logo3} alt="Image" style={{ height: "60px" }} />
              </div>
            </div>
            <div className="col-lg-3 alg">
              <div
                className={`single-partner fade-in-right ${klrInView ? 'visible' : ''}`}
                ref={klrRef} style={{    backgroundColor: "#aca3cb",padding:"20px"}}
              >
                <img src={klr} alt="Image" style={{ height: "60px" }} />
              </div>
            </div>
            <div className="col-lg-3 alg">
              <div
                className={`single-partner fade-in-left ${logo5InView ? 'visible' : ''}`}
                ref={logo5Ref} style={{    backgroundColor: "#aca3cb",padding:"20px"}}
              >
                <img src={logo5} alt="Image" style={{ height: "60px" }} />
              </div>
            </div>
            {/* <div className="col-lg-3 alg">
              <div
                className={`single-partner fade-in-right ${logo6InView ? 'visible' : ''}`}
                ref={logo6Ref}
              >
                <img src={logo6} alt="Image" style={{ height: "60px" }} />
              </div>
            </div>
            <div className="col-lg-3 alg">
              <div
                className={`single-partner fade-in-left ${logo7InView ? 'visible' : ''}`}
                ref={logo7Ref}
              >
                <img src={logo7} alt="Image" style={{ height: "60px" }} />
              </div>
            </div>
            <div className="col-lg-3 alg">
              <div
                className={`single-partner fade-in-right ${vidwanInView ? 'visible' : ''}`}
                ref={vidwanRef}
              >
                <img src={vidwan} alt="Image" style={{ height: "60px" }} />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniversitiesAlignWithUs;