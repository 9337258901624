import React from 'react'
import ToolDesignBriefExp from './ToolDesignBriefExp';
import AllCourses from './AllCourses';
import ToolDesignBanner from './ToolDesignBanner';
import TabsCourse from './TabsCourse';
import FlipCards from './FlipCards';
import PlacementsfixturedesignBanner from './PlacementsfixturedesignBanner'
const ToolAndFixtureDesign = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };

  return (
    <div>
 {/* <ToolDesignBanner/> */}
 <PlacementsfixturedesignBanner />
    <TabsCourse></TabsCourse>
    {/* <ToolDesignBriefExp/> */}
    {/* <AllCourses></AllCourses> */}
    <FlipCards />
    </div>
  )
}

export default ToolAndFixtureDesign