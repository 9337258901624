import React from 'react'
import OfflineRobiticsBriefExp from './OfflineRobiticsBriefExp'
import AllCourses from './AllCourses'
import OfflineRoboticsBanner from './OfflineRoboticsBanner';
import OfflineRoboticsTabs from './OfflineRoboticsTabs';
import FlipCards from './FlipCards';
const PlacementsOfflineRobotics = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div>   
      <OfflineRoboticsBanner/>
      <OfflineRoboticsTabs></OfflineRoboticsTabs>
      {/* <OfflineRobiticsBriefExp/> */}
      {/* <AllCourses></AllCourses> */}
     <FlipCards />
    </div>
  )
}

export default PlacementsOfflineRobotics