import React from 'react'
import ReactJscorses from "./ReactJscorses";
import FlipCards from "./FlipCards";
import Partners from './Partners'
import DataanalysitcsBanner from './DataanalysitcsBanner';
import DataAnalysticsTabs from './DataAnalysticsTabs';
const PlacementsDataAnalytics = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div> 
      <DataanalysitcsBanner/>
      <DataAnalysticsTabs></DataAnalysticsTabs>
    {/* <ReactJscorses></ReactJscorses>  */}
    
    <FlipCards />
     {/* <Partners></Partners> */}
    
     </div>
  )
}

export default PlacementsDataAnalytics