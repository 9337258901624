import React from 'react'

import TechnoTalksBriefExp from './TechnoTalksBriefExp'
import TechnoTalksCources from './TechnoTalksCources'
import TechnotalksSliders from './TechnotalksSliders';
import Technonewsliders from './Technonewsliders'
const TechnoTalks = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };

  return (
    <div>
        {/* <TechnotalksSliders/> */}
        <Technonewsliders />
        {/* <TechnoTalksBriefExp/> */}
        <TechnoTalksCources/>
    </div>
  )
}

export default TechnoTalks