import React from 'react';
import Slider from 'react-slick'; // Ensure that "react-slick" is installed and imported
import "slick-carousel/slick/slick.css"; // Import slick carousel CSS
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const OurLeadershipCardsFlip = () => {
    const settingsg = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 200,
        slidesToShow: 3, // Show 3 cards in a row
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div style={{ background: "rgb(54 48 74)" }}>
            {/* Internal CSS */}
            <style>{`
                * {
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                }
       

                .slick-slider {
                    width: 80%;
                    margin: auto;
                }

                .box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                #card-container {
                    perspective: 1000px;
                    width: 300px;
                    margin: 0 15px;
                }

                #card, #card2, #card3 {
                    width: 100%;
                    height: 300px;
                    position: relative;
                    transform-style: preserve-3d;
                    transition: all .8s linear;
                    box-shadow: 5px 5px 15px rgba(0, 0, 0, .3);
                }

                #card:hover, #card2:hover, #card3:hover {
                    transform: rotateY(180deg);
                }

                .front, .back {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    backface-visibility: hidden;
                }

                .back {
                    transform: rotateY(180deg);
                    background-color: #fff;
                    text-align: center;
                    padding: 20px;
                }

                // img {
                //     width: 100%;
                //     height: 100%;
                //     border-radius: 15px;
                // }

                h6 {
                    color: #333;
                        font-weight: bold;
                }

                .artist, .date {
                    color: #888;
                }   
                      .icons{
                      padding-top:50px;
                      }

            `}</style>
            <h1 className="heading-names" style={{ color: "#fff", fontSize: "40px", lineHeight: "1.3" }}>
                MEET OUR <span style={{ color: "#F58634" }}>LEADERSHIP</span>
            </h1>
            <div className='row' style={{ height: "400px" }}>
                <Slider {...settingsg}>

                    <div className="box">
                        <div id="card-container">

                            <div id="card">
                                <div className="front face">
                                    <img src="images/our-team/ccr.png" alt="Subham" />
                                </div>
                                <div className="back face">
                                    <h6>Chandrashaker Reddy</h6>
                                    <p className="artist" style={{ textAlign: "justify" }}>Welcome to IIA, where our mission is to drive innovation and excellence in every project we undertake.Our dedicated team is committed to delivering outstanding results . </p>
                                    <div className="icons">
                                        <a className="site-button facebook sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-facebook-f" /></a>
                                        <a className="site-button google-plus sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-google-plus-g" /></a>
                                        <a className="site-button linkedin sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-linkedin" /></a>
                                        <a className="site-button twitter sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-twitter" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box">
                        <div id="card-container">
                            <div id="card2">
                                <div className="front face">
                                    <img src="images/our-team/ASHA.png" alt="Subham" />
                                </div>
                                <div className="back face">
                                    <h6>G.Asha</h6>
                                    <p className="artist" style={{ textAlign: "justify" }}>At IIA, we prioritize building strong and lasting relationships with our clients by understanding their unique culinary needs and preferences.</p>
                                    <div className="icons">
                                        <a className="site-button facebook sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-facebook-f" /></a>
                                        <a className="site-button google-plus sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-google-plus-g" /></a>
                                        <a className="site-button linkedin sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-linkedin" /></a>
                                        <a className="site-button twitter sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-twitter" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div id="card-container">
                            <div id="card2">
                                <div className="front face">
                                    <img src="images/our-team/KIRAN.jpeg" alt="Kiran" style={{ height: '300px' }} />
                                </div>
                                <div className="back face">
                                    <h6 >Kiran Kumar</h6>
                                    <p className="artist" style={{ textAlign: "justify" }}>At IIA, our Marketing Manager plays a crucial role in shaping our brands narrative and driving growth.With a keen understanding of market trends and customer insights.</p>
                                    <div className="icons">
                                        <a className="site-button facebook sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-facebook-f" /></a>
                                        <a className="site-button google-plus sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-google-plus-g" /></a>
                                        <a className="site-button linkedin sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-linkedin" /></a>
                                        <a className="site-button twitter sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-twitter" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div id="card-container">
                            <div id="card3">
                                <div className="front face">
                                    <img src="images/our-team/subhams.png" alt="Subham" style={{
                                        width: "100%",
                                        height: "100%",
                                    }} />
                                </div>
                                <div className="back face">
                                    <h6>Shubam</h6>
                                    <p className="artist" style={{ textAlign: "justify" }}>Welcome to IIA powered by Envision IS! As the L&D Head, I’m excited to support fresh graduates eager to kickstart their careers in automation.</p>
                                    <div className="icons">
                                        <a className="site-button facebook sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-facebook-f" /></a>
                                        <a className="site-button google-plus sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-google-plus-g" /></a>
                                        <a className="site-button linkedin sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-linkedin" /></a>
                                        <a className="site-button twitter sharp outline" target="_blank" aria-hidden="true"><FontAwesomeIcon icon="fa-brands fa-twitter" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
};

export default OurLeadershipCardsFlip;
