import React from 'react'

// import th from "../img/business-partners/th.jpg";
import picture1 from "../img/business-partners/Picture1.png";
import asm from "../img/business-partners/asm.jpg";
import group from "../img/business-partners/group.png";

import Slider from "react-slick";

const BusinessPartners = () => {
  var logoSliderss = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    infinite: true,
    // arrows: true,
    dots: false,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <div>
    <div className="container-fluid" style={{paddingBottom:"20px",background:"rgb(54, 48, 74)"}}>
        <div className="container">
            <div className="row" style={{textAlign:"center"}}>
            <div className=" text-black text-center">
            <h1 className="heading-names" style={{ color: "#fff", fontSize: "40px", lineHeight: "1.3",padding:'30px' }}>
        EMPLOYERS FOR YOUR <span style={{ color: "#F58634" }}>PROFESSIONAL JOURNEY</span>
                </h1>
                        {/* <h2 className="text-uppercase" style={{color:'#5B4B99'}}> Employers for Your  <span style={{ color: " #F58634" }}>Professional Journey</span></h2> */}
                        <div className=" bg-primary" ></div>
                        </div>
                        <Slider {...logoSliderss}>

                        {/* <div className="single-partner">
                  <img src={th} alt="Image" style={{height:"65px",width:"250px"}} />
                </div> */}
                <div className="single-partner">
                  <img src={picture1} alt="Image" style={{height:"65px",width:"250px"}} />
                </div>
                <div className="single-partner">
                  <img src={asm} alt="Image" style={{height:"65px",width:"250px"}}/>
                </div>
                <div className="single-partner">
                  <img src={group} alt="Image"  style={{height:"65px",width:"250px"}} />
                </div>

                </Slider>
            </div>
        </div>
    </div>
    
        </div>
  )
}

export default BusinessPartners