import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import toolfix from "../img/banners/V6.jpg";
import onlinerobotics from "../img/banners/online-robotics.jpg";
import offlinerobotics from "../img/banners/offline-robotics.jpg";
import react from "../img/banners/REACT-&-MOBILE-APP.jpg";
import java from "../img/banners/JAVA.jpg";
import flutter from "../img/banners/Filter-mobile-app.jpg";
import techtalk from "../img/banners/TECHNO-TALKS.jpg";
import { Link } from "react-router-dom";
import DownloadBrocherpopup from "./DownloadBrocherpopup"; // Modal
import ButtonPage from './ButtonPage'; // Button component

const PlacementsfixturedesignBanner = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
  





<section  >
          <Carousel className="res-nav-mob no-indicators" interval={300000} controls={false}>
          <img className="d-block w-100" src={toolfix} alt="Tool & Fixture Design"   style={{ height: "650px" }} />
          <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
            <div className="row">
          
              <div className="col-lg-4 dtd" style={{}}>
                <div className="" style={{ width: "100%" }}>
                  <h2 className="banner-content" style={{ fontFamily: "Poppins, SansSerif", fontSize: "32px", fontWeight: "600", width: "139%" }}>
                    Unlock your potential in manufacturing "From Concept to Creation"
                  </h2>
                  <h2 className="banner-content" style={{ fontSize: "40px", color: "#fff", fontFamily: "Roboto", fontWeight: "800" }}>
                    TOOL & FIXTURE <span style={{ color: "#F58842" }}>DESIGN</span>
                  </h2>

                  <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "400", fontWeight: "bold" }}>
                    1. Industrial Oriented Training (IOT)
                  </p>
                  <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "400", fontWeight: "bold", }}>
                    2. Job Oriented Skill Enhancement (JOSE)
                  </p>
                  <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "400", fontWeight: "bold", }}>
                    3. 100% Placement Support
                  </p>
                  <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "400", marginLeft: "49px", fontWeight: "bold", }}>
                    NX & CATIA Software free training
                  </p>
                
                </div>
              </div>

      
              <div className="col-lg-8 dtdt ">
                <h2 className="banner-content" style={{ fontFamily: "Poppins, SansSerif", fontSize: "27px", fontWeight: "600", }}>
                  “Only Institute Automating Careers Across India"
                </h2>
              </div>
              <div style={{}}>
                <div > 
                  
                  <ButtonPage handleShow={handleShow} />

               
                  <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} />


                </div>


              </div>
            </div>



     


          </Carousel.Caption>
          </Carousel>
        </section>
  );
};

export default PlacementsfixturedesignBanner;
