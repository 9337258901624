import React from 'react'
import Technotalksjavacorses from "./Technotalksjavacorses";
import TechnoTalksCources from "./TechnoTalksCources";
import Partners from './Partners';
import JavaCourseBanner from './JavaCourseBanner';
import JavaTabs from './JavaTabs';

const TechnoTalksJava = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div> 
      <JavaCourseBanner />
      <JavaTabs></JavaTabs>
    {/* <Technotalksjavacorses></Technotalksjavacorses>  */}
    <TechnoTalksCources />

     </div>
  )
}

export default TechnoTalksJava