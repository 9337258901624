import React from 'react';

const TermsAndCondition = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div style={{ padding: '20px', lineHeight: '1.6' }}>
      <h1>Terms and Conditions</h1>
      <p>Last Updated: September 23, 2024</p>

      <p>
        Welcome to IIAutomate. By accessing or using our website and services, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using our services. If you do not agree with any part of these terms, you should not use our website or services.
      </p>

      <h2>1. Use of Services</h2>
      <p>
        You agree to use our website and services in a lawful manner and in compliance with all applicable laws and regulations. You are solely responsible for your use of the website, including any content you post or share, and any consequences that result from such actions.
      </p>

      <h2>2. Intellectual Property</h2>
      <p>
        All content on the IIAutomate website, including but not limited to text, graphics, logos, and images, is the property of IIAutomate or its content suppliers and is protected by copyright, trademark, and other intellectual property laws. You may not copy, reproduce, distribute, or create derivative works from any part of the website without prior written permission from IIAutomate.
      </p>

      <h2>3. User Account</h2>
      <p>
        To access certain features of our services, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account.
      </p>

      <h2>4. Limitation of Liability</h2>
      <p>
        IIAutomate will not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use or inability to use our website and services. We do not guarantee that the website will be available at all times or that the content will be accurate or error-free.
      </p>

      <h2>5. Third-Party Links</h2>
      <p>
        Our website may contain links to third-party websites or services that are not owned or controlled by IIAutomate. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites. You acknowledge and agree that IIAutomate shall not be responsible or liable for any damages or losses caused by or in connection with your use of any third-party services.
      </p>

      <h2>6. Termination</h2>
      <p>
        We reserve the right to terminate or suspend your access to our website and services at any time, without prior notice, for conduct that we believe violates these terms or is harmful to other users, us, or third parties.
      </p>

      <h2>7. Modifications to the Terms</h2>
      <p>
        We reserve the right to modify or update these terms and conditions at any time. Any changes will be effective immediately upon posting to this page, with the updated date at the top. Your continued use of the website following any changes constitutes your acceptance of the new terms.
      </p>

      <h2>8. Governing Law</h2>
      <p>
        These terms and conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions or concerns regarding these Terms and Conditions, please contact us at:
      </p>
      <p>
        <strong>Email:</strong> <a href="mailto:info@iiautomate.com">info@iiautomate.com</a><br />
        <strong>Address:</strong> #913, Manjeera Trinity Corporate, 9th floor, Office No.913, KPHB, Hyderabad, Telangana, India - 500085
      </p>




      <h1>Refund Policy</h1>
      <p>
        At IIAutomate, we strive to provide exceptional products and services to
        meet your automation needs. However, if you are not completely satisfied
        with your purchase, we're here to help.
      </p>

      <h2>Refunds</h2>
      <p>
        If you wish to request a refund, you may do so within 30 days of your
        purchase date. To be eligible for a refund, the following conditions
        must be met:
      </p>
      <ul>
        <li>The product or service must be unused and in the same condition that you received it.</li>
        <li>Your request for a refund must be accompanied by a valid proof of purchase.</li>
      </ul>

      <h2>Non-Refundable Items</h2>
      <p>The following items are non-refundable:</p>
      <ul>
        <li>Downloaded digital products or software licenses.</li>
        <li>Services that have been initiated or completed.</li>
        <li>Promotional or discounted items.</li>
      </ul>

      <h2>How to Request a Refund</h2>
      <p>
        To initiate a refund, please contact us at{' '}
        <a href="mailto:support@iiautomate.com">support@iiautomate.com</a> with
        your order details, including the reason for your refund request.
        Once we receive your request, we will review it and notify you of the
        status of your refund.
      </p>

      <h2>Processing Time</h2>
      <p>
        If your refund is approved, we will initiate a refund to your original
        method of payment. Please note that processing times may vary depending
        on your payment provider, but it typically takes 5-7 business days for
        the funds to be reflected in your account.
      </p>

      <h2>Late or Missing Refunds</h2>
      <p>
        If you haven’t received a refund within the stated processing time,
        please check your bank account or contact your credit card company.
        There is often some processing time before a refund is posted. If you
        still have not received your refund, please contact us at{' '}
        <a href="mailto:support@iiautomate.com">support@iiautomate.com</a>.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about our refund policy, please reach out to
        us at{' '}
        <a href="mailto:support@iiautomate.com">support@iiautomate.com</a>, and
        we will be happy to assist you.
      </p>
    </div>
  );
};

export default TermsAndCondition;
