import React, { useState } from "react";
import DownloadBrocherpopup from "./DownloadBrocherpopup"; // Modal
import ButtonPage from './ButtonPage'; // Button component
import reactbanner from '../img/banners/REACT-&-MOBILE-APP.jpg';
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
// Ensure to import your CSS file

const ReactJsBanner = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  return (
    <div>
      <section>
        <Carousel className="res-nav-mob no-indicators" interval={300000} controls={false}>
          <img className="d-block w-100" src={reactbanner} alt="Tool & Fixture Design" style={{ height: "650px" }} />
          <Carousel.Caption style={{ width: "100%", textAlign: "left" }}>
            <div className="row">
              {/* Column 1 */}
              <div className="col-lg-4 dtd" style={{}}>
                <div className="" style={{ width: "110%" }}>

                  <h2 className="banner-content" style={{ fontSize: "32px", color: "#fff", fontFamily: "Raleway!important", fontWeight: "600", }}     >
                    Craft dynamic web applications with ease From Components to Full-Scale Solutions
                  </h2>
                  <h2 className="banner-content"
                    style={{
                      fontSize: "30px",
                      color: "#fff",
                      fontFamily: "Raleway!important",
                      fontWeight: "bold",

                    }}
                  >
                    REACTJS WEB <span style={{ color: "#F58842" }}>APPLICATION</span>
                  </h2>
                  {/* <h2   style={{  fontSize: "40px", color: "#524DA1",  fontFamily: "roboto",  fontWeight: "800",    }}     >
          
            ONLINE <span style={{ color: "#F58842" }}> ROBOTICS</span>
            </h2> */}
                  <p className="banner-contentul"
                    style={{
                      fontSize: "17px",
                      color: "#fff",
                      fontWeight: "400",
                      textAlign: "justify",
                      textIndent: "50px",
                      fontWeight: "bold"
                    }}
                  >
                    1. Advanced ReactJS Techniques (ART)
                  </p>
                  <p className="banner-contentul"
                    style={{
                      fontSize: "17px",
                      color: "#fff",
                      fontWeight: "400",
                      textAlign: "justify",
                      textIndent: "50px",
                      fontWeight: "bold"
                    }}
                  >
                    2. Web-Ready Skill Building (WSB)
                  </p>
                  <p className="banner-contentul"
                    style={{
                      fontSize: "17px",
                      color: "#fff",
                      fontWeight: "400",
                      textAlign: "justify",
                      textIndent: "50px",
                      fontWeight: "bold"
                    }}
                  >
                    3. 100% Placement Support
                  </p>
                  <p className="banner-contentul"
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "bold",
                      textAlign: "justify",
                      marginLeft: "49px"
                    }}
                  >
                    Free Next.js & Redux toolkit included
                  </p>

                  
                </div>
              </div>

              <div className="col-lg-8 dtdt ">
                <h2 className="banner-content" style={{ fontSize: "32px", color: "#fff", fontFamily: "Raleway!important", fontWeight: "600", }}    >
                  “Redefining Frontend Development Across India”
                </h2>

              </div>
            </div>
            <div style={{}}>
              <div > {/* ButtonPage to trigger the modal */}

                <ButtonPage handleShow={handleShow} />

                {/* Modal Component */}
                <DownloadBrocherpopup showModal={showModal} handleClose={handleClose} /></div>


            </div>
          </Carousel.Caption>
        </Carousel>
      </section>
    </div>
  );
}

export default ReactJsBanner;
