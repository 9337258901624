import React from 'react'
// import OnlineRobiticsBanner from './OnlineRobiticsBanner'
import OnlineRoboticsBriefExp from './OnlineRoboticsBriefExp'
// import Pricing from './Pricing'
import AllCourses from './AllCourses'
import OnlineRobiticsBanner from './OnlineRobiticsBanner';
import OnlineRoboticsTabs from './OnlineRoboticsTabs';
import FlipCards from './FlipCards';
const PlacementsOnlineRobotics = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div>
      <OnlineRobiticsBanner/>
      <OnlineRoboticsTabs></OnlineRoboticsTabs>
    {/* <OnlineRoboticsBriefExp/> */}
   <FlipCards />
    {/* <AllCourses></AllCourses> */}

    </div>
  )
}

export default PlacementsOnlineRobotics