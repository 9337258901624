import React from 'react'
import ReactJscorses from "./ReactJscorses";
import AllCourses from "./AllCourses";
import Partners from './Partners'
import AutomationSolutionsBanner from './AutomationSolutionsBanner';
import AutomationSolutionsTabs from './AutomationSolutionsTabs';
const AutomationSolutions = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div> 
      <AutomationSolutionsBanner/>
      <AutomationSolutionsTabs></AutomationSolutionsTabs>
    {/* <ReactJscorses></ReactJscorses>  */}
    
    <AllCourses></AllCourses>
     {/* <Partners></Partners> */}
    
     </div>
  )
}

export default AutomationSolutions