import React from 'react'
import JavaCorses from "./JavaCorses";
import AllCourses from "./AllCourses";
import Partners from './Partners'
import CloudComputingBanner from './CloudComputingBanner';
import CloudComputingTabs from './CloudComputingTabs';
import TechnoTalksCources from './TechnoTalksCources';

const TechnotalksCloud = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div> 

      <CloudComputingBanner/>
      <CloudComputingTabs></CloudComputingTabs>
      {/* <JavaCorses></JavaCorses>  */}
      
        <TechnoTalksCources/>
      {/* <Partners></Partners> */}
   
     </div>
  )
}

export default TechnotalksCloud