import React from 'react'
import Fluttercorses from "./Fluttercorses";
import AllCourses from "./AllCourses";
import Partners from './Partners'
import FlutterBanner from './FlutterBanner';
import FlutterTabs from './FlutterTabs';
const Flutter = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div> 
      <FlutterBanner/>
      <FlutterTabs></FlutterTabs>
    {/* <Fluttercorses></Fluttercorses>  */}
    
    <AllCourses></AllCourses>
     {/* <Partners></Partners> */}
  
     </div>
  )
}

export default Flutter