import React from 'react'
import ToolDesignBriefExp from './ToolDesignBriefExp';
import AllCourses from './AllCourses';
import ToolDesignBanner from './ToolDesignBanner';
import TabsCourse from './TabsCourse';

const ToolAndFixtureDesign = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };

  return (
    <div>
    <ToolDesignBanner/>
    <TabsCourse></TabsCourse>
    {/* <ToolDesignBriefExp/> */}
    <AllCourses></AllCourses>
  
    </div>
  )
}

export default ToolAndFixtureDesign